import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faXmark } from '@fortawesome/pro-solid-svg-icons';
import ProgressBar from './progress_bar.es6';

const UploadDocsTable = ({ documents, abortUploadCb, updateFileCb }) => {
  const handleDisplayNameChange = (rowId, event) => {
    const doc = documents[rowId];
    const newDocName = { ...doc, name: event.target.value };
    updateFileCb(rowId, newDocName);
  };

  //display name column
  const displayName = (file, rowId) => {
    return (
      <div>
        {file.status === 'success' && (
          <input
            className="upload-docs-table__input-text"
            type="text"
            value={file.name}
            onChange={(e) => handleDisplayNameChange(rowId, e)}
          />
        )}
      </div>
    );
  };

  const handleDescriptionChange = (rowId, event) => {
    const doc = documents[rowId];
    const newDocDescription = { ...doc, description: event.target.value };
    updateFileCb(rowId, newDocDescription);
  };

  //description column
  const description = (file, rowId) => {
    return (
      <div>
        {file.status === 'success' && (
          <textarea
            className="upload-docs-table__input-text upload-docs-table__input-textarea"
            value={file.description}
            onChange={(e) => handleDescriptionChange(rowId, e)}
          />
        )}
        {file.status === 'fail' && (
          <span className="upload-docs-table__span--failed">
            {file.description}
          </span>
        )}
      </div>
    );
  };

  //uploaded column
  const uploaded = (file, rowId) => {
    const handleCancelFileUpload = () => {
      abortUploadCb(file.cancelSource);
    };
    return (
      <div className="upload-docs-table__cell-with-spinner">
        {file.status === 'securing' && [
          <span key={`{pending-text-${rowId}`}>Securing File</span>,
          <div key={`{pending-spinner-${rowId}}`}>
            <FontAwesomeIcon
              icon={faSpinner}
              className="upload-docs-table__spinner fa-spin-pulse"
              data-testid="upload-docs-table__spinner"
            />
          </div>
        ]}
        {file.status === 'uploading' && (
          <a
            className="upload-docs-table--cancel"
            onClick={handleCancelFileUpload}
          >
            Cancel upload
          </a>
        )}
        {file.status === 'success' && (
          <div className="upload-docs-table__span--success">
            Queued for Virus Scanning
          </div>
        )}
        {file.status === 'fail' && (
          <span>
            <FontAwesomeIcon
              icon={faXmark}
              className="upload-docs-table__xmark"
              data-testid="upload-docs-table__xmark"
            />
            <span className="upload-docs-table__span--failed">Failed</span>
          </span>
        )}
      </div>
    );
  };

  const columns = [
    {
      label: 'Filename',
      name: 'name',
      render: (file) => file.file_name
    },
    {
      label: 'Display Name',
      name: 'displayName',
      render: (file, rowId) => displayName(file, rowId)
    },
    {
      label: 'Description',
      name: 'description',
      render: (file, rowId) => description(file, rowId)
    },
    {
      label: 'Uploaded',
      name: 'uploaded',
      render: (file, rowId) => uploaded(file, rowId)
    }
  ];

  const renderedHeaders = columns.map((column, index) => {
    return (
      <div
        className="upload-docs-table__cell"
        key={`upload-docs-table-${column.label}-${index}`}
        data-testid={`upload-docs-table__header-row-${index}`}
      >
        {column.label}
      </div>
    );
  });

  const renderedRows = Object.entries(documents).map(
    ([rowId, rowData], idx) => {
      const renderedCells = columns.map((column) => {
        return (
          <div
            className={'upload-docs-table__cell'}
            key={`${column.label}-${rowId}`}
            data-testid={`column-row-${idx}`}
          >
            {column.render(rowData, rowId)}
          </div>
        );
      });

      return (
        <div key={`upload-doc-row-${idx}`}>
          <div
            className="upload-docs-table__row upload-docs-table__text upload-docs-table__row--hover"
            key={`upload-docs-table__row-${idx}`}
            data-testid={`upload-docs-table__row-${idx}`}
          >
            {renderedCells}
          </div>
          <div
            key={`upload-docs-table__progress-bar-${idx}`}
            className="upload-docs-table__progress-bar-row"
          >
            <ProgressBar
              progress={rowData.progress}
              status={rowData.status}
              key={`progressBar-${rowData.upload_form_row_id}`}
            />
          </div>
        </div>
      );
    }
  );

  return (
    <div className="upload-docs-table" role="table">
      <div className="upload-docs-table__row-header upload-docs-table__row">
        {renderedHeaders}
      </div>
      <div>{renderedRows}</div>
    </div>
  );
};

export default UploadDocsTable;
