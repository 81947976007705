import React, { useCallback } from 'react';
import videojs from 'video.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';

// Rewrite of existing MediaPlayer without jQuery, using npm video.js
const NewMediaPlayer = ({
  src, // string, data stream url.
  type, // string, media type of stream. Expects 'video/mp4' or 'audio/mp3'.
  closeCallback // function, callback to call when player is closed.
}) => {
  // Placeholder setup
  let playerRef = React.useRef(null);
  let vPlayer = React.useRef(null);

  // Configure shared options across media types. See:
  // https://videojs.com/guides/options/
  let options = {
    fluid: true,
    playbackRates: [0.5, 1, 1.5, 2],
    sources: [{ src: src, type: type }],
    controls: true,
    preload: 'auto'
  };

  // Add media-specific options
  if (type == 'audio/mp3') {
    options = {
      ...options,
      aspectRatio: '1:0',
      controlBar: { fullscreenToggle: false }
    };
  } else {
    options = {
      ...options,
      controlBar: { pictureInPictureToggle: false }
    };
  }

  // Run the following code after playerRef element has been added to page
  React.useEffect(() => {
    // Instantiate Video.js player
    vPlayer = videojs(playerRef, options);

    // Disable context menu
    vPlayer.on('contextmenu', (e) => {
      e.preventDefault();
      return false;
    });
  }, [playerRef]);

  // Update callback to refer to actual vPlayer after it instantiates
  let handleClose = useCallback(() => {
    vPlayer.dispose();
    closeCallback();
  }, [vPlayer]);

  const TagName = type == 'video/mp4' ? 'video' : 'audio';

  return (
    <div
      className="new-media-player__overlay"
      data-testid="new-media-player__overlay"
    >
      <div className="new-media-player__modal">
        <TagName
          ref={(element) => (playerRef = element)}
          className="video-js vjs-default-skin"
          data-testid={`new-media-player__${TagName}`}
        />
        <div
          className="new-media-player__close"
          data-testid="new-media-player__close"
          onClick={handleClose}
        >
          <FontAwesomeIcon icon={faXmark} size="xs" />
        </div>
      </div>
    </div>
  );
};

export default NewMediaPlayer;
