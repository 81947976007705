import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Button = ({ text, icon, type, callback, isDisabled = false }) => {
  return (
    <button
      className={`btn btn-${type}`}
      onClick={callback}
      disabled={isDisabled}
      data-testid="button"
    >
      {icon && <FontAwesomeIcon className={'btn-icon'} icon={icon} />} {text}
    </button>
  );
};

export default Button;
