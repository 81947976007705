import React, { useState } from 'react';

/*
Prop info: 
 - userDashboards
    * an array of objects representing a user's Answers dashboards.
      {
        id: the dashboard's id in ShareSecure
        title: the dashboard's title
        sisense_url: the dashboard's iframe URL
      }
*/

const EndUserAnalyticsPage = ({
  userDashboards = [] // array of objects, see prop info above
}) => {
  const [selectedDashboardId, setSelectedDashboardId] = useState(
    userDashboards.length > 0 ? userDashboards[0].id : null
  );

  return (
    <div className="analytics-page__content">
      {userDashboards.length > 0 ? (
        <section className="analytics-page__user-dashboards">
          <div className="analytics-page__dashboard-tabs">
            {userDashboards.map((dashboard) => (
              <span
                key={dashboard.id}
                onClick={() => setSelectedDashboardId(dashboard.id)}
                className={`analytics-page__tab ${
                  selectedDashboardId == dashboard.id ? 'selected' : ''
                }`}
                data-testid={`analytics-page__tab--${dashboard.id}`}
              >
                {dashboard.title}
              </span>
            ))}
          </div>
          {selectedDashboardId && (
            <iframe
              className={'analytics-page__current-dashboard'}
              data-testid={`analytics-page__current-dashboard`}
              src={
                userDashboards.find(
                  (dashboard) => dashboard.id === selectedDashboardId
                ).sisense_url
              }
            >
              <p>Your browser does not support iframes.</p>
            </iframe>
          )}
        </section>
      ) : (
        'You have no dashboards at this time.'
      )}
    </div>
  );
};

export default EndUserAnalyticsPage;
