import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical } from '@fortawesome/pro-solid-svg-icons';

const TableRowActions = ({
  rowId, // integer, unique id of table row item
  actions // array of objects with icon, text, disabled (boolean), and clickCb (callback function)
}) => {
  const [showActionMenu, setShowActionMenu] = useState(false); // used for small/mobile screens which need an alternative to the hover effect to control menu visibility

  const controlMenuOnClick = (e) => {
    e.stopPropagation(); // prevents triggering parent onClick (opening modal for row data) when clicked
    setShowActionMenu(!showActionMenu);
  };

  return (
    <>
      {/* Larger screens - control menu visibility with hover effect */}
      <div
        tabIndex="0"
        className="table-row__actions-btn table-row__actions-btn-no-touch"
        data-testid={`table-row__actions-btn-${rowId}`}
        onClick={(e) => e.stopPropagation(e)} // prevents triggering parent onClick (opening modal for row data) when clicked
      >
        <FontAwesomeIcon icon={faEllipsisVertical} />
        <div className="table-row__actions">
          {actions.map((a, i) => {
            const uniqueId = `${rowId}-${i}`;
            return (
              <div
                key={uniqueId}
                tabIndex="0"
                className={`table-row__action${
                  a.isDisabled ? ' table-row__action--disabled' : ''
                }`}
                data-testid={`table-row__action-${uniqueId}`}
                onClick={a.callback}
              >
                <FontAwesomeIcon
                  icon={a.icon}
                  className="action-icon"
                  data-testid={`action-icon-${uniqueId}`}
                />
                <span className="table-row__action-text">{a.text}</span>
              </div>
            );
          })}
        </div>
      </div>

      {/* mobile devices - control menu visibility with onClick */}
      <div
        tabIndex="0"
        className={`table-row__actions-btn table-row__actions-btn-mobile ${
          showActionMenu ? 'table-row__actions-btn-mobile-active' : ''
        }`}
        data-testid={`table-row__actions-btn-mobile-${rowId}`}
        onClick={controlMenuOnClick}
      >
        <FontAwesomeIcon icon={faEllipsisVertical} />
        {showActionMenu && (
          <div className="table-row__actions">
            {actions.map((a, i) => {
              const uniqueId = `${rowId}-${i}`;
              return (
                <div
                  key={uniqueId}
                  tabIndex="0"
                  className={`table-row__action${
                    a.isDisabled ? ' table-row__action--disabled' : ''
                  }`}
                  data-testid={`table-row__action-${uniqueId}`}
                  onClick={a.callback}
                >
                  <FontAwesomeIcon
                    icon={a.icon}
                    className="action-icon"
                    data-testid={`action-icon-${uniqueId}`}
                  />
                  <span className="table-row__action-text">{a.text}</span>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
};

export default TableRowActions;
