import React from 'react';
import ClassUtils from '@es6/class_utils.es6';

export default class ProgressBar extends React.Component {
  constructor(props) {
    super(props)
    ClassUtils.autobind(this)
    this.state = { status: this.props.status, progress: this.props.progress }
  }

  static getDerivedStateFromProps(nextProps, prevState){
    if (nextProps.status != prevState.status || nextProps.progress != prevState.progress) {
      return { status: nextProps.status, progress: nextProps.progress };
    }
    return null;
  }

  componentDidUpdate(prevProps, _prevState) {
    if (prevProps.status != this.props.status || prevProps.progress != this.props.progress){
      this.setState( { status: this.props.status, progress: this.props.progress } )
    }
  }

  render() {
    let style = {width: this.props.progress + '%'}
    if(this.props.status == 'success') {
      style.height = 0
      style.opacity = 0
    }

    return (
      <tr className="progress-row">
        <td colSpan={this.props.colspan}>
          <div className="progress">
            <div className={this.props.status != 'fail' ? "bar" : "bar error"} style={style}></div>
          </div>
        </td>
      </tr>
    )
  }
}