import React from 'react';

const SelectAllCheckbox = ({ value = false, handleChange }) => {
  const stringValue = value.toString();
  return (
    <div>
      <input
        type="checkbox"
        className="selectAllCheckbox"
        value={value}
        checked={stringValue === 'true'}
        onChange={handleChange}
        data-testid="selectAll-checkbox"
      />
    </div>
  );
};

export default SelectAllCheckbox;
