import React from 'react';
import { classNames } from '@components_new/shared/utils/class_names.es6';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';

const FeaturedStatistic = ({
  icon = null, //imported FontAwesome icon
  count = 0, //integer, number of documents in featured statistic,
  text = '', //string, text to be displayed on featured statistic tab
  linkText = '', //string, link text
  link = '', // string, link to send user after clicking featured statistic
  color = '', //string, background color
  selected = false, // boolean, whether a featured statistic has been selected
  previewByAdmin = false, // boolean, whether it is previewed by admin
  notSelectableByAdmin = false, // boolean is it selectable by admin
  callback = null // callback function
}) => {
  const stringifiedCount = () =>
    count.toString().length == 1 && count != 0
      ? count.toString().padStart(2, '0')
      : count.toString();

  const plusIcon = () => {
    return (
      <div className="featured-statistic__number-container">
        99
        <FontAwesomeIcon
          icon={faPlus}
          className="featured-statistic__icon-plus"
          data-testid="featured-statistic__icon-plus"
        />
      </div>
    );
  };

  return (
    <div
      onClick={callback}
      className={classNames(
        `featured-statistic featured-statistic__theme-${color}`,
        selected && `featured-statistic__theme-${color}--selected`,
        previewByAdmin &&
          notSelectableByAdmin == true &&
          `featured-statistic__theme-${color}--preview`,
        `featured-statistic__theme-${color}--hover`
      )}
      data-testid={`featured-statistic__theme-${color}`}
    >
      <FontAwesomeIcon
        icon={icon}
        className={`featured-statistic__icon`}
        data-testid="featured-statistic__icon"
      />
      <div
        className="featured-statistic__number"
        data-testid={`featured-statistic__number-${count}`}
      >
        {count > 99 ? plusIcon() : stringifiedCount()}
      </div>
      <div>
        <div
          className="featured-statistic__text"
          data-testid="featured-statistic__text"
        >
          {text}
        </div>
        <a className="featured-statistic__link" href={link}>
          {linkText}
        </a>
      </div>
    </div>
  );
};

export default FeaturedStatistic;
