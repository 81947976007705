import React from 'react';
import ConfirmNavigateAwayModal from './confirm_navigate_away_modal.es6';
import Select2Input from '../select2_input.es6';
import UploadedDocumentRow from './uploaded_document_row.es6';
import ClassUtils from '@es6/class_utils.es6';

export default class DocumentUploadForm extends React.Component {
  constructor(props) {
    super(props);
    ClassUtils.autobind(this);
    this.state = DocumentUploadForm.getDefaultState(props);
    if (!window.sslyEvents) window.sslyEvents = new EventEmitter();
    this.sslyEvents = window.sslyEvents;
    this.totalDocsCount = 0;
    this.totalSize = 0;
  }

  render() {
    if (this.state.hidden) return false;
    if (this.state.hasDocs) {
      let tableRows = [];
      const startIdx =
        this.state.pages == 1 ? 0 : this.state.currentPage * this.props.perPage;
      const endIdx = Math.min(
        startIdx + this.props.perPage,
        this.state.rowIds.length
      );
      for (let i = startIdx; i < endIdx; i++) {
        const rowId = this.state.rowIds[i];
        tableRows.push(
          <UploadedDocumentRow
            key={'row-' + rowId}
            rowId={rowId}
            doc={this.state.documents[rowId]}
            watermarkCol={this.state.watermarkCol}
            watermarkDefault={this.props.watermarkCol}
            date={this.props.dateCol}
            columns={this.getColumns().length}
            docUpdateCb={this.handleDocUpdate}
            abortUpload={this.abortUpload}
          />
        );
      }

      let pageLinks = [];
      for (let i = 0; i < this.state.pages; i++) {
        pageLinks.push(
          <a key={'page-link-' + i} onClick={() => this.goToPage(i)}>
            {' '}
            {i + 1}{' '}
          </a>
        );
      }

      let collectionOptions = [];
      if (this.props.availableCollections) {
        for (let i = 0; i < this.props.availableCollections.length; i++) {
          const coll = this.props.availableCollections[i];
          collectionOptions.push(
            <option key={'collection-' + coll[0]} value={coll[0]}>
              {' '}
              {coll[1]}{' '}
            </option>
          );
        }
      }

      return (
        <div
          className={
            'clump cmp-doc-upload-form' +
            (this.props.modal ? ' end-user-new-doc' : '')
          }
        >
          <div className="form-holder">
            <ConfirmNavigateAwayModal message="It appears that you are in the middle of uploading files to ShareSecure. If this tab is closed or you navigate away from this page the upload progress will be lost." />

            <div className="clump-header">
              <h2>UPLOADED DOCUMENTS</h2>
            </div>

            <form>
              <div className="add-cats">
                {this.props.categories && (
                  <Select2Input
                    url="/admin/categories.json"
                    params={{ select2: true, page_limit: 200 }}
                    inputName="category_tokens"
                    placeholder="Search categories..."
                    multiple={true}
                    label="Add categories to all documents:"
                    createNew={true}
                    newChoicePosition={3}
                    updateCb={this.updateCategoryTokens}
                  />
                )}
              </div>
              <br />
              <br />

              <table key="docsTable" className="withPGBar">
                <thead>
                  <tr>
                    {this.getColumns().map((col, idx) => (
                      <th key={'col-' + idx}>{col}</th>
                    ))}
                  </tr>
                </thead>

                <tbody>{tableRows}</tbody>
              </table>

              {this.state.pages > 1 && (
                <div className="pagination">
                  {this.state.currentPage > 0 && (
                    <a onClick={this.prevPage}>&lt;Prev</a>
                  )}
                  {pageLinks}
                  {this.state.currentPage == 0 && this.state.pages > 1 && (
                    <a onClick={this.nextPage}>Next&gt;</a>
                  )}
                </div>
              )}

              <br />
              {this.showCollections() && (
                <div className="add-collections">
                  <h3>Collection</h3>
                  <div>You must select a collection to upload a document</div>
                  <select
                    name="collectionId"
                    onChange={this.updateCollectionSelected}
                    className="collection-select"
                  >
                    <option value="">Choose one</option>
                    {collectionOptions}
                  </select>
                </div>
              )}

              {this.props.otherFields && (
                <React.Fragment>
                  {this.props.draft && (
                    <div className="form-group">
                      <h3>Save as draft</h3>
                      <input
                        type="checkbox"
                        name="draft"
                        value="1"
                        defaultChecked={this.props.draftDefault}
                        onChange={this.updateCheckbox}
                      />
                      <div className="tooltip">
                        <span>?</span>
                        <div className="tooltip-message">
                          Check this box to save these documents as drafts.
                          Draft documents are not visible to non-admin users.
                        </div>
                      </div>
                    </div>
                  )}

                  {this.props.collections && (
                    <div className="form-group">
                      <h3>Collection</h3>
                      {this.props.mandatoryCollection && (
                        <div>
                          You must select a collection to upload a document
                        </div>
                      )}
                      <select
                        name="collectionId"
                        onChange={this.updateCollectionSelected}
                        className="collection-select"
                      >
                        <option value="">Choose one</option>
                        {collectionOptions}
                      </select>
                    </div>
                  )}

                  <Select2Input
                    url="/admin/user_documents.json"
                    params={{ page_limit: 50 }}
                    inputName="document_access[user_ids]"
                    placeholder="Search users..."
                    multiple={true}
                    label="Share with users"
                    formatFields={['name', 'email']}
                    updateCb={this.updateUserIds}
                  />
                  <p className="clear"></p>
                  <br />
                  <Select2Input
                    url="/admin/groups.json"
                    params={{ page_limit: 50 }}
                    inputName="document_access[group_ids]"
                    placeholder="Search groups..."
                    multiple={true}
                    label="Share with groups"
                    updateCb={this.updateGroupIds}
                  />
                  <br />
                  <br />
                  <p className="clear"></p>
                  <div className="form-group">
                    <h3>Notification Email</h3>
                    <input
                      type="checkbox"
                      name="skipNotification"
                      onChange={this.updateCheckbox}
                    />
                    <label>
                      Don't send a notification email about this upload
                    </label>
                    <div className="tooltip">
                      <span>?</span>
                      <div className="tooltip-message">
                        By default, users receive an email notification when a
                        document is shared with them. If you would prefer not to
                        send this notification, check this box.
                      </div>
                    </div>
                  </div>

                  <div className="form-group" id="custom_message">
                    <a
                      className="mail-preview-link"
                      onClick={this.toggleCustomMessage}
                    >
                      Add a custom message to notification email
                    </a>
                    {this.state.showCustomMessage && (
                      <div id="custom_message_to_edit">
                        <textarea
                          name="customMessage"
                          cols="45"
                          rows="15"
                          id="share_notification_custom_message"
                          defaultValue={this.state.customEmailTemplate}
                          onChange={this.updateInput}
                        />
                        <br />
                        <a
                          className="show_custom_message_preview"
                          data-preview-type="share_notification"
                        >
                          Preview
                        </a>
                        <div
                          id="share_notification_message_preview"
                          className="hide overlay"
                        >
                          <div className="modal">
                            <a
                              className="hide_custom_message_preview"
                              data-preview-type="share_notification"
                            >
                              Close Preview
                            </a>
                            <div className="mail-preview">Loading...</div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </React.Fragment>
              )}

              <div className="save-cancel">
                <input
                  type="submit"
                  name="commit"
                  value="Save Documents"
                  ref={(elt) => (this.submitBtn = elt)}
                  disabled={!this.state.canSave}
                  onClick={this.submitForm}
                />
                <a className="cancel" onClick={this.resetState}>
                  Cancel
                </a>
              </div>
            </form>

            {this.state.showAlert && (
              <div className="custom-alert">
                <div className="inner">
                  <p className="message">
                    {this.state.status === 'pending' && (
                      <img src={this.props.spinnerUrl} />
                    )}
                    &nbsp;
                    <span
                      dangerouslySetInnerHTML={{
                        __html: this.state.alertMessage
                      }}
                    ></span>
                  </p>
                  <br />
                  {this.state.status !== 'pending' && (
                    <p className="button" onClick={this.clearAlert}>
                      Ok
                    </p>
                  )}
                </div>
              </div>
            )}

            {this.state.failedToWatermark.length >= 1 &&
              this.failedToWatermarkAlert()}
          </div>
        </div>
      );
    } else if (this.state.hideAllInputs) {
      return (
        <div ref={(elt) => (this.fileUploadDiv = elt)}>
          <div className="cmp-doc-upload-form invisible_form">
            <form
              className="form_with_submit"
              data-url={this.props.formUrl + '/upload'}
              action={this.props.formUrl}
              acceptCharset="UTF-8"
              method="post"
            >
              <input
                ref={(elt) => (this.invisibleInput = elt)}
                type="file"
                name="file"
                multiple
              />
            </form>
          </div>
        </div>
      );
    } else {
      return (
        <div
          ref={(elt) => (this.fileUploadDiv = elt)}
          className={
            'doc-upload-wrapper' + (this.props.modal ? ' end-user-new-doc' : '')
          }
        >
          <div className="cmp-doc-upload-form">
            <form
              className="form_with_submit"
              data-url={this.props.formUrl + '/upload'}
              action={this.props.formUrl}
              acceptCharset="UTF-8"
              method="post"
            >
              <input name="utf8" type="hidden" value="&#x2713;" />
              <div className="can-drag">
                <div className="document-drop">
                  {this.props.hidden && !this.props.modal && (
                    <i
                      className="icon-delete-32 close-drop-zone"
                      onClick={this.resetState}
                    ></i>
                  )}
                  <p id="dropzone">Drag and drop files here</p>
                </div>
              </div>
              {this.state.hideButton && (
                <input type="file" name="file" multiple className="hide" />
              )}
              {!this.state.hideButton && (
                <React.Fragment>
                  <div>
                    <h3 className="or">or</h3>
                    <div className="file-input button">
                      <h2>Click to choose files</h2>
                      <input type="file" name="file" multiple />
                    </div>
                  </div>
                  <a key="backLink" onClick={this.goBack}>
                    Cancel
                  </a>
                </React.Fragment>
              )}
            </form>
          </div>
        </div>
      );
    }
  }

  componentDidMount() {
    this.initializeFileUpload();
    this.sslyEvents.on('show-doc-upload', this.showSelf);
  }

  showSelf(hideAllInputs) {
    this.setState({ hidden: false, hideAllInputs: hideAllInputs }, () => {
      if (!this.state.initialized) this.initializeFileUpload();
      if (hideAllInputs) $(this.invisibleInput).click();
    });
  }

  goBack(e) {
    if (this.props.backLink) {
      e.preventDefault();
      window.location = this.props.backLink;
    } else {
      this.resetState(e);
    }
  }

  cancelAndBack(e) {
    this.setState({ failedToWatermark: [] });
    this.abortUpload();
    this.goBack(e);
  }

  resetState(e) {
    e.preventDefault();
    $(this.fileUpload).fileupload('destroy');
    this.totalDocsCount = 0;
    this.totalSize = 0;
    this.setState(
      (oldState, props) => DocumentUploadForm.getDefaultState(props),
      this.initializeFileUpload
    );
  }

  getColumns() {
    let cols = ['Filename'];
    if (this.state.watermarkCol) cols.push('Watermark');
    cols.push('Display Name');
    if (this.props.dateCol) cols.push('Date');
    cols = [...cols, 'Description', 'Uploaded'];
    return cols;
  }

  initializeFileUpload() {
    if (this.state.initialized) return true;
    // fileUploadDiv comes from a ref, which doesn't persist - need to assign
    // it to something else here to have access to it in resetState
    this.fileUpload = this.fileUploadDiv;
    if (!this.fileUpload) return false;
    const self = this;
    $(this.fileUpload).fileupload({
      url: self.props.formUrl + '/upload',
      maxChunkSize: 10485760,
      sequentialUploads: true,
      start: () => {
        const newTimer = setTimeout(self.keepSessionActive, 24 * 60 * 1000);
        self.setState({ hasDocs: true, sessionRefreshTimer: newTimer });
        window.scrollTo(0, 0);
      },
      add: self.addFileToDownloads,
      done: self.uploadSuccess,
      fail: self.uploadFail,
      chunksend: (e, data) => {
        if (self.state.abortUpload) {
          self.setState({ abortUpload: false });
          return false; // this triggers a failure and aborts the upload
        }
        return true;
      },
      chunkfail: (e, data) => {
        $.ajax({
          url: '/documents/multipart/' + data.formData.upload_form_row_id,
          method: 'DELETE'
        });
      },
      progress: (e, data) => {
        const progress = parseInt((data.loaded / data.total) * 100, 10);
        const rowId = data.formData.upload_form_row_id;
        let doc = this.state.documents[rowId];
        if (doc) {
          doc.progress = progress;
          if (progress === 100) doc.status = 'securing';
          this.setState((oldState) => ({
            documents: { ...oldState.documents, [rowId]: doc }
          }));
        }
      },
      progressall: (e, data) => {
        const progress = parseInt((data.loaded / data.total) * 100, 10);
        if (progress === 100) {
          this.prepareCustomEmail();
          this.setState({ allUploaded: true });
        }
      }
    });
    this.setState({ initialized: true });
  }

  addFileToDownloads(e, data) {
    const rowId = this.randomString(32);
    const docObj = this.buildDocObject(data, rowId);
    this.setState((oldState, props) => ({
      hasDocs: true,
      documents: { ...oldState.documents, [rowId]: docObj },
      rowIds: [...oldState.rowIds, rowId],
      pages: Math.ceil((oldState.rowIds.length + 1) / props.perPage),
      totalSize: docObj.totalSize
    }));
    data.formData = docObj; // send to server, where we need row id and file size
    return data.submit();
  }

  uploadSuccess(e, data) {
    this.handleCompletedUpload(e, data, true);
  }

  uploadFail(e, data) {
    this.handleCompletedUpload(e, data, false);
  }

  handleCompletedUpload(e, data, success) {
    clearTimeout(this.state.sessionRefreshTimer);
    const formData = data.formData;
    const rowId = formData.upload_form_row_id;
    const docs = this.state.documents;
    let doc = docs[rowId];
    if (doc) {
      doc.status = success ? 'success' : 'fail';
      doc.progress = 100;
      const status = data.jqXHR ? data.jqXHR.status : -1;
      if (success) {
        const savedFile = data.result.files[0];
        this.checkForDuplicates(savedFile.unique_id);
        doc.uploaded_document_id = savedFile.id;
        doc.unique_id = savedFile.unique_id;
        doc.content_type = savedFile.content_type;
      } else if (status === 415) {
        doc.description = data.jqXHR.responseJSON.error;
      } else if (status === 403) {
        const msg = data.jqXHR.responseJSON.error;
        doc.description = msg.includes('not authorized')
          ? 'User file upload disabled'
          : msg;
      } else if (status === -1) {
        // upload has been canceled
        doc.description = 'Upload canceled';
      }
      const hasSuccess = this.state.successfulUpload || success;
      this.setState((oldState, props) => ({
        documents: { ...oldState.documents, [rowId]: doc },
        uniqueIds: [...oldState.uniqueIds, doc.unique_id],
        sessionRefreshTimer: null,
        watermarkCol:
          oldState.watermarkCol ||
          (props.watermarkCol && doc.content_type.includes('application/pdf')),
        canSave: oldState.allUploaded && hasSuccess,
        successfulUpload: hasSuccess
      }));
    }
  }

  checkForDuplicates(uniqueId) {
    if (uniqueId && this.state.uniqueIds.includes(uniqueId)) {
      if (typeof Rollbar !== 'undefined') {
        Rollbar.error('Document upload form contains duplicate unique id');
      }
      alert(
        'An error occurred processing the files. Please try uploading the files again.'
      );
      window.location.reload();
    }
  }

  randomString(len) {
    let outStr = '';
    while (outStr.length < len) {
      let newStr = Math.random().toString(36).slice(2);
      outStr += newStr.slice(0, Math.min(newStr.length, len - outStr.length));
    }
    return this.state.rowIds.includes(outStr) ? this.randomString(len) : outStr;
  }

  prevPage() {
    if (this.state.currentPage == 0) return;
    this.setState((oldState) => ({ currentPage: oldState.currentPage - 1 }));
  }

  nextPage() {
    if (this.state.currentPage == this.state.pages - 1) return;
    this.setState((oldState) => ({ currentPage: oldState.currentPage + 1 }));
  }

  goToPage(newPage) {
    this.setState({ currentPage: newPage });
  }

  prepareCustomEmail() {
    let template =
      'Greetings,\n\n' +
      this.props.orgName +
      ' has shared the following documents with you via ShareSecure:';
    let names = '';
    for (let rowId in this.state.documents) {
      names += '\n' + this.state.documents[rowId].name;
    }
    template += names + '\n';
    this.setState({ customEmailTemplate: template });
    return template;
  }

  toggleCustomMessage() {
    this.setState((oldState) => ({
      showCustomMessage: !oldState.showCustomMessage
    }));
  }

  keepSessionActive() {
    const self = this;
    $.get('/api/refresh_session').done(() => {
      const newTimer = setTimeout(self.keepSessionActive, 24 * 60 * 1000);
      self.setState({ uploadInProgress: newTimer });
    });
  }

  handleDocUpdate(rowId, updatedDoc) {
    this.setState((oldState) => ({
      documents: { ...oldState.documents, [rowId]: updatedDoc }
    }));
  }

  abortUpload() {
    this.setState({ abortUpload: true });
  }

  showCollections() {
    return !this.props.otherFields && this.props.mandatoryCollection;
  }

  submitForm(e) {
    e.preventDefault();
    const submitBtn = $(this.submitBtn);
    submitBtn.prop('disabled', true);
    if (!this.validateData()) {
      submitBtn.prop('disabled', false);
      return false;
    }

    this.setState({
      status: 'pending',
      showAlert: true,
      alertMessage: 'Saving Documents'
    });
    const params = this.buildParams();

    const self = this;
    $.ajax({
      url: self.props.formUrl,
      method: 'POST',
      data: JSON.stringify(params),
      dataType: 'json',
      contentType: 'application/json',
      success: (result) => {
        if (
          result.constructor === Array &&
          result.length > 0 &&
          result[0].constructor === String
        ) {
          let msgHTML =
            'There was an error with ' +
            result.length +
            ' of your documents:<br/>';
          for (let i = result.length - 1; i >= 0; i--) {
            msgHTML += result[i] + '<br/>';
          }
          self.setState({
            status: 'error',
            showAlert: true,
            alertMessage: msgHTML
          });
          submitBtn.prop('disabled', false);
        } else {
          self.setState({
            status: 'success',
            showAlert: true,
            alertMessage:
              'File(s) successfully created. After passing virus scanning, they will be available for use.'
          });
        }
      },
      error: (result) => {
        let msg =
          'Sorry, an error occurred while trying to save the documents.';
        if (result.status === 403) {
          msg = 'You do not have permission to perform this action.';
        }
        if (result.status === 422) {
          self.setState({
            failedToWatermark: result.responseJSON.failed
          });
          return;
        }
        self.setState({ status: 'failed', showAlert: true, alertMessage: msg });
        submitBtn.prop('disabled', false);
      }
    });
  }

  updateBypassWatermarking(e) {
    const checkboxValue = e.target.checked;
    const uploadedDocId = e.target.id;

    let updatedDocs = JSON.parse(JSON.stringify(this.state.documents));
    const currentDocIds = Object.keys(updatedDocs);
    const docToUpdate = currentDocIds.filter(
      (docId) => updatedDocs[docId].uploaded_document_id == uploadedDocId
    );

    updatedDocs[docToUpdate].bypass_watermarking = checkboxValue;

    this.setState({
      documents: updatedDocs
    });
  }

  submitProtectedDocs(e) {
    e.persist();

    let currentDocs = JSON.parse(JSON.stringify(this.state.documents));
    let currentRowIds = JSON.parse(JSON.stringify(this.state.rowIds));
    const currentDocIds = Object.keys(currentDocs);
    const failedDocs = JSON.parse(JSON.stringify(this.state.failedToWatermark));

    if (currentDocIds.length == 1) {
      // We're showing an error message without checkboxes for a singular document that fails to watermark,
      currentDocs[currentDocIds[0]].bypass_watermarking = true;
    } else {
      // but if a user has been offered these checkboxes, we need to drop any unselected ones from state.
      failedDocs.forEach((failedDoc) => {
        const currentDocId = currentDocIds.filter((docId) => {
          if (currentDocs[docId]) {
            return (
              currentDocs[docId].uploaded_document_id ==
              failedDoc.uploaded_document_id
            );
          }
        });
        const currentDoc = currentDocs[currentDocId];

        if (currentDoc.enable_watermark && !currentDoc.bypass_watermarking) {
          delete currentDocs[currentDocId];
          currentRowIds = currentRowIds.filter(
            (rowId) => rowId != currentDocId
          );
        }
      });
    }

    this.setState(
      {
        failedToWatermark: [],
        documents: currentDocs,
        rowIds: currentRowIds
      },
      () => {
        this.submitForm(e);
      }
    );
  }

  validateData() {
    let error = false;
    let message;
    for (let i = 0; i < this.state.rowIds.length; i++) {
      const doc = this.state.documents[this.state.rowIds[i]];
      if (doc.name.length === 0) {
        error = true;
        message = 'Please choose a display name for each document.';
        break;
      } else if (doc.name.length > 255) {
        error = true;
        message =
          'Name cannot be more than 255 characters. Please shorten and resubmit.';
        break;
      }
    }

    if (!error) {
      const groupIds = this.props.groupId
        ? this.props.groupId.toString()
        : this.state.groupIds;
      const collectionId = this.props.collectionId || this.state.collectionId;
      if (
        groupIds &&
        collectionId &&
        !this.props.allowCollectionsDocsToBeInGroups
      ) {
        error = true;
        message =
          'You cannot have a collection and group assigned to a document at the same time. Please unset one of these in the form.';
      }
    }

    if (this.props.mandatoryCollection) {
      const collectionSelected = this.state.collectionSelected;
      if (!collectionSelected) {
        error = true;
        message = 'You need to select a collection to upload a document';
      }
    }

    if (error) {
      this.setState({
        status: 'error',
        showAlert: true,
        alertMessage: message
      });
      return false;
    }
    return true;
  }

  failedToWatermarkAlert() {
    const isDocumentSingular = Object.keys(this.state.documents).length == 1;

    return (
      <section className="custom-alert">
        <article className="inner">
          <p>WARNING:</p>
          {isDocumentSingular ? (
            <div className="message">
              We were not able to watermark this document due to its security
              settings. We cannot watermark documents that have encryption,
              password protection, or other security restrictions. <br />
              <br /> Please try generating the document without these security
              restrictions and then uploading it. Or, you can upload the
              document without applying the watermark.
              <br />
              <br />
            </div>
          ) : (
            <div className="message">
              We were not able to watermark all of these documents due to their
              security settings. We cannot watermark documents that have
              encryption, password protection, or other security restrictions.{' '}
              <br />
              <br /> Please try generating these documents without these
              security restrictions and then uploading them. Or, you can upload
              them without applying a watermark.
              <br />
              <table className="failed-to-watermark-list">
                <thead>
                  <tr>
                    <th colSpan="2">
                      <b>
                        Select Which Documents You'd Like To Upload Without
                        Watermarking:
                      </b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.failedToWatermark.map((failedDoc) => (
                    <tr key={failedDoc.id}>
                      <td>
                        <b>{failedDoc.name}</b>
                      </td>
                      <td className="failed-doc-checkbox">
                        <input
                          id={failedDoc.id}
                          type="checkbox"
                          onChange={this.updateBypassWatermarking}
                        ></input>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
          <div className="button-cancel" onClick={this.cancelAndBack}>
            Cancel Upload
          </div>
          <br />
          <br />
          <div className="button" onClick={this.submitProtectedDocs}>
            Upload Without Watermark
          </div>
        </article>
      </section>
    );
  }

  clearAlert() {
    if (this.state.status === 'error') {
      this.setState({ status: 'ready', showAlert: false });
    } else if (this.state.status === 'failed') {
      window.location.reload();
    } else {
      if (this.props.reloadPage) {
        window.location.reload();
      } else {
        window.location = this.props.redirectPath || '/';
      }
    }
  }

  updateInput(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  updateCheckbox(e) {
    this.setState({ [e.target.name]: e.target.checked });
  }

  updateCategoryTokens(newIds) {
    this.setState({ categoryTokens: newIds.join(',') });
  }

  updateUserIds(newIds) {
    this.setState({ userIds: newIds.join(',') });
  }

  updateGroupIds(newIds) {
    this.setState({ groupIds: newIds.join(',') });
  }

  updateCollectionSelected(e) {
    this.setState({ [e.target.name]: e.target.value });
    if (e.target.value == '') {
      this.setState({ ['collectionSelected']: false });
    } else {
      this.setState({ ['collectionSelected']: true });
    }
  }

  buildDocObject(fileData, rowId) {
    const file = fileData.files[0];
    // Increment docs count and total docs size
    this.totalDocsCount = this.totalDocsCount + 1;
    this.totalSize = this.totalSize + file.size;

    return {
      file_name: file.name,
      name: file.name,
      original_size: file.size,
      upload_form_row_id: rowId,
      uploaded_document_id: null,
      unique_id: null,
      content_type: file.type,
      enable_watermark: this.props.watermarkDefault,
      bypass_watermarking: false,
      description: '',
      date: '',
      progress: 0,
      status: 'uploading',
      combined_size: this.totalSize,
      docs_count: this.totalDocsCount
    };
  }

  buildParams() {
    return {
      authenticity_token: this.props.authToken,
      documents: this.buildDocParams(),
      document_access: this.buildDocAccessParams(),
      collection_id: this.props.collectionId || this.state.collectionId,
      category_tokens: this.state.categoryTokens,
      draft: this.state.draft,
      skip_notification: this.state.skipNotification
    };
  }

  buildDocParams() {
    let docParams = [];
    const submitKeys = [
      'name',
      'unique_id',
      'uploaded_document_id',
      'date',
      'description',
      'enable_watermark',
      'content_type',
      'bypass_watermarking'
    ];
    for (let rowId in this.state.documents) {
      const doc = this.state.documents[rowId];
      const docFailedToWatermark = this.state.failedToWatermark.find(
        (failedDoc) => failedDoc.id == doc.uploaded_document_id
      );
      const rejectFailedDoc = !doc.bypass_watermarking && docFailedToWatermark;
      if (doc.status === 'success' && !rejectFailedDoc) {
        let thisDocParams = {};
        for (let i = 0; i < submitKeys.length; i++) {
          const key = submitKeys[i];
          thisDocParams[key] = doc[key];
        }
        docParams = [...docParams, thisDocParams];
      }
    }
    return docParams;
  }

  buildDocAccessParams() {
    const groupIds = this.props.groupId
      ? this.props.groupId.toString()
      : this.state.groupIds;
    const userIds = this.props.userId
      ? this.props.userId.toString()
      : this.state.userIds;
    let accessParams = {
      custom_message: this.state.customMessage || this.prepareCustomEmail()
    };
    if (groupIds) accessParams.group_ids = groupIds;
    if (userIds) accessParams.user_ids = userIds;
    return accessParams;
  }

  static getDefaultState(props) {
    return {
      initialized: false,
      hidden: props.hidden,
      hideAllInputs: props.hidden,
      hideButton: props.hideButton,
      documents: {},
      draft: props.draftDefault,
      rowIds: [],
      uniqueIds: [],
      sessionRefreshTimer: null,
      currentPage: 0,
      pages: 1,
      hasDocs: false,
      canSave: false,
      allUploaded: false,
      status: 'ready',
      showAlert: false,
      alertMessage: 'Saving Documents',
      watermarkCol: false,
      failedToWatermark: [],
      totalSize: 0, // total size of all uploaded docs, in bytes
      successfulUpload: false,
      collectionSelected: false
    };
  }
}
