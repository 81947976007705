import React from 'react';
import Dropdown from './dropdown.es6';
import ClassUtils from '@es6/class_utils.es6';

export default class ExportDropdown extends React.Component {
  constructor(props) {
    super(props)
    ClassUtils.autobind(this)
  }

  render() {
    return (
      <Dropdown title="EXPORT" items={this.props.items} itemClickCb={this.itemCb} />
    )
  }

  itemCb( cbData ) {
    ssly.BackgroundRequest.send( {
      method: "GET",
      path: cbData.path,
      data: { download_format: cbData.format },
      message: "Preparing your download"
    } );
  }
}
