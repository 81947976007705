import React from 'react';
import ClassUtils from '@es6/class_utils.es6';

export default class DatePicker extends React.Component {
  constructor(props) {
    super(props)
    ClassUtils.autobind(this)

    this.state = { pickedDate: this.props.date }
  }

  componentDidMount() {
    this.dateComponent = new Pikaday({
      field: this.dateFld,
      format: 'YYYY-MM-DD',
      reposition: false,
      onClose: this.pickerClosed
    })
    this.showDate( this.state.pickedDate )
  }

  static getDerivedStateFromProps(nextProps, prevState){
    if (nextProps.date != prevState.date) {
      if ( nextProps.date == null ) {
        return { date: '' }
      }
      return { date: nextProps.date }
    }
    return null;
  }

  componentDidUpdate(prevProps, _prevState) {
    if (prevProps.date != this.props.date) {
      if (this.props.date == '') {
        this.dateFld.value = ''
      }
      this.showDate( this.props.date )
      this.setState( { pickedDate: this.props.date } )
    }
  }

  render() {
    const inputElt = this.props.inputName ?
      <input className="center" type="text" name={this.props.inputName} placeholder={this.props.label} ref={(me) => { this.dateFld = me }} /> :
      <input className="center" type="text" placeholder={this.props.label} ref={(me) => { this.dateFld = me }} />

    return (
      <div className="cmp-date-picker">
        {inputElt}
        <i className={ `old-icon icon-delete-16 dark pull-right clear-date ${this.state.pickedDate != null ? '' : 'hide'}` } title="Clear Search" onClick={this.clearDate} ></i>
      </div>
    )
  }

  showDate(dt) {
    if (dt != null && dt.includes('-')) {
      dt = dt.split('-')
      dt = new Date( dt[0], dt[1] - 1, dt[2] ) // work around pikaday timezone issue
    }
    this.dateComponent.setDate( dt )
  }

  pickerClosed() {
    let dt = this.dateFld.value

    if ( isNaN(Date.parse(dt)) ) {
      this.showDate(this.state.pickedDate)
    } else {
      this.setState( { pickedDate: dt } )
      this.notify( dt )
    }
  }

  clearDate() {
    this.setState( { pickedDate: null } )
    this.dateFld.value = ''
    this.notify( null )
  }

  notify( newDt ) {
    if (this.props.onChange) this.props.onChange( newDt )
  }
}