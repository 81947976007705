import React from 'react';
import ClassUtils from '@es6/class_utils.es6';

export default class MediaPlayer extends React.Component {
  constructor(props) {
    super(props)
    ClassUtils.autobind(this)
    if(!window.sslyEvents) window.sslyEvents = new EventEmitter()
    this.sslyEvents = window.sslyEvents
    this.state = {visible: false, autoplay: false}
  }

  componentDidMount() {
    this.sslyEvents.on('init-video', this.showSelf)
  }

  render() {
    if(this.state.visible) {
      let mediaElement
      if(this.state.mediaType === 'video'){
        mediaElement = <video ref={elt => this.playerRef = elt} className="video-js vjs-default-skin"
                              width="475" controls preload="auto" style={{margin: '0 auto'}} data-setup='{ "playbackRates": [0.5, 1, 1.5, 2] }'></video>
      } else {
        mediaElement = <audio ref={elt => this.playerRef = elt} className="video-js vjs-default-skin"
                              width="475" controls preload="auto" style={{margin: '0 auto'}} data-setup='{ "playbackRates": [0.5, 1, 1.5, 2] }'></audio>
      }
      return (
        <div className="overlay" id="video_overlay">
          <div className="modal">
            <div className="video">{mediaElement}</div>
            <div className="overlay_close_unbound" onClick={this.hideSelf}>X</div>
          </div>
        </div>
      )
    } else {
      return null
    }
  }

  showSelf(url, mediaType) {
    if(this.vPlayer) this.vPlayer.dispose()
    this.setState({visible: true, mediaType: mediaType, url: url}, this.initPlayer)
  }

  hideSelf() {
    if(this.vPlayer) {
      this.vPlayer.dispose()
      this.vPlayer = null
    }
    this.setState({visible: false, time: null, autoplay: false})
  }

  initPlayer() {
    const self = this
    let options = {}
    if(this.state.mediaType === 'audio') {
      options = {
        aspectRatio: '1:0',
        fluid: true,
        controlBar: {fullscreenToggle: false}
      }
    }
    this.vPlayer = videojs(this.playerRef, options)
    this.vPlayer.ready(() => {
      self.refreshSource()
    })
    if (this.props.disableContextMenu) {
      this.vPlayer.on('contextmenu', (e) => {
        e.preventDefault();
        return false;
      });
    }
    this.vPlayer.on('error', () => {
      if(self.vPlayer.error().code === 2) {
        this.setState({autoplay: true, time: self.vPlayer.currentTime()}, self.refreshSource)
      }
    })
  }

  refreshSource() {
    const self = this
    this.vPlayer.off('ready')
    $.get(this.state.url, data => {
      self.vPlayer.src({
        type: self.state.mediaType == 'video' ? 'video/mp4' : 'audio/mp3',
        src: data.stream_url.replace(/&amp;/g, '&')
      })

      self.vPlayer.ready(() => {
        if(self.state.time && self.state.time > 0) {
          self.vPlayer.currentTime(self.state.time)
        }

        if(self.state.autoplay) {
          self.setState({autoplay: false}, () => {self.vPlayer.play()})
        }
      })
    })
  }
}
