import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleInfo,
  faCircleChevronRight
} from '@fortawesome/pro-light-svg-icons';

const InfoTip = ({
  message = null, // string, text to be displayed,
  position = '', //string, position of the infotip popup right, left, top, bottom
  arrowPosition = '', //string, for infotip that is on the top or bottom the arrow options are right or left
  //for the infotip positioned to left or right the arrow options are top or bottom
  infoTipName = '', //string, it is used for testing
  hasLink = false, //boolean, optional default false
  linkText = '', //string, optional link text
  link = '', // string, optional link to send user after clicking
  callback = null //function, optional
}) => {
  const displayLink = () => {
    return hasLink ? (
      <div
        className={`info-tip__link-container`}
        data-testid={`info-tip-${infoTipName}-link`}
        onClick={callback}
      >
        <a className="info-tip__link" href={link}>
          {linkText}
        </a>
        <FontAwesomeIcon
          icon={faCircleChevronRight}
          className=" info-tip__chevron-icon"
        />
      </div>
    ) : null;
  };
  return (
    <div className="info-tip" data-testid={`info-tip-${infoTipName}`}>
      <FontAwesomeIcon icon={faCircleInfo} className="info-tip__icon" />
      <div
        className={`info-tip__message-container info-tip--${position}-arrow-${arrowPosition}`}
        data-testid={`info-tip-${infoTipName}-container`}
      >
        <div
          className="info-tip__message-text"
          data-testid={`info-tip-${infoTipName}-message`}
        >
          {message}
        </div>

        {displayLink()}
      </div>
    </div>
  );
};

export default InfoTip;
