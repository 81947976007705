import React from 'react';
import ClassUtils from '@es6/class_utils.es6';

export default class Select2Input extends React.Component {
  constructor(props) {
    super(props)
    ClassUtils.autobind(this)
    if(typeof(EventEmitter) !== 'undefined') {
      if(!window.sslyEvents) window.sslyEvents = new EventEmitter()
      this.sslyEvents = window.sslyEvents
    }
  }

  componentDidMount() {
    // initialize select2
    const self = this
    let formPath = this.props.url
    if(this.props.params) {
      formPath += '?' + Object.entries(this.props.params).map(elt => elt[0] + '=' + elt[1]).join('&')
    }

    if(this.props.preloaded) {
      $(this.s2Input)
        .select2({
          placeholder: self.props.placeholder,
          multiple: self.props.multiple,
          allowClear: self.props.allowClear,
          data: self.props.data ? JSON.parse(self.props.data) : {}
        })
        .one('select2-focus', Select2Input.select2Focus)
        .on('select2:unselecting', this.handleUnselecting)
        .on('select2:close', this.handleClose)
        .on('change', e => this.updateParent( $(e.target).val() ) )
    } else {
      $(this.s2Input)
        .select2({
          placeholder: self.props.placeholder,
          multiple: self.props.multiple,
          allowClear: self.props.allowClear,
          tags: !!self.props.createNew,
          createTag: (params) => {
            const term = $.trim( params.term )
            if (term === '') return null
            return self.props.createNew ? {id: `<<<${term}>>>`, text: `New: ${term}`, title: `${term}` } : null
          },
          insertTag: function (data, tag) {
            if (self.props.newChoicePosition != null) {
              data.splice( self.props.newChoicePosition, 0, tag)
            } else {
              data.push(tag)
            }
          },
          templateResult: self.formatItem,
          templateSelection: self.formatItem,
          ajax: {
            url: formPath,
            dataType: "json",
            data: function(params) {
              return { q: params.term, from: document.body.className }
            },
            delay: 250,
            processResults: function(data) {
              const items = data.map(item => ({...item, id: item.id.toString(), text: item.name}))
              return { results: items }
            },
            error: function(resp) {
              if(resp.status !== 0) {
                const msg = 'Error: ' + (function(statusCode) {
                  switch(statusCode) {
                    case 414:
                      return 'search term too long'
                    default:
                      return 'something went wrong'
                  }
                })(resp.status)
                alert(msg)
              }
            }
          }
        })
        .one('select2-focus', Select2Input.select2Focus)
        .on('select2:unselecting', this.handleUnselecting)
        .on('select2:close', this.handleClose)
        .on('change', e => this.updateParent( $(e.target).val() ) )
    }

    this.setDefaultValues( this.props.defaultVal )

    if(this.sslyEvents) {
      this.sslyEvents.on('select2-reset', this.reset)
      this.sslyEvents.on('select2-enable-disable', this.toggleDisabled)
      this.sslyEvents.on('select2-resetValues', this.setSelect2Values)
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.props.label && <h3 key="header">{this.props.label}</h3>}
        <select key="content" name={this.props.inputName} id={this.props.inputId}
                style={this.props.style || {width: '350px'}} ref={s2Input => this.s2Input = s2Input}
                className="cmp-select2-input"
                placeholder={this.props.placeholder}
                disabled={this.props.disabled}>
          {!this.props.multiple && this.props.placeholder && <option></option>}
        </select>
      </React.Fragment>
    )
  }

  reset() {
    this.setDefaultValues( null )
  }

  toggleDisabled(enable_disable, toggleOn) {
    if(this.props.toggleOn && this.props.toggleOn === toggleOn) {
      let disable = (enable_disable == 'disable')
      $(this.s2Input).prop('disabled', disable).trigger('change')
      if(disable) $(this.s2Input).val(null).trigger('change')
    }
  }

  setSelect2Values(eventPrefix, newDefaultValues) {
    if(this.props.eventPrefix && this.props.eventPrefix === eventPrefix) {
      this.setDefaultValues( newDefaultValues )
    }
  }

  setDefaultValues( defaultValues ) {
    let self = this

    if ( !defaultValues ) return

    if ( typeof defaultValues == 'string' ) {
      defaultValues = JSON.parse( defaultValues )
    } else if ( typeof defaultValues != 'object' ) {
      return
    }

    defaultValues.forEach( function(item) {
      let option = new Option( self.formatItem(item), item.id, true, true )
      $(self.s2Input).append( option ).trigger( 'change' )
    } )

    $(self.s2Input).trigger( {
      type: 'select2:select',
      params: ( { data: defaultValues } )
    } )
  }

  formatItem(item) {
    if ( !item.text ) item.text = item.name

    let formatted = item.text
    if(this.props.formatFields) {
      const field1 = this.props.formatFields[0]
      if(this.props.formatFields.length > 1) {
        const field2 = this.props.formatFields[1]
        if ( item[field1] && item[field2] ) {
          formatted = `${item[field1]} (${item[field2]})`
        }
      } else if ( item[field1] ) {
        formatted = item[field1]
      }
    }

    return formatted
  }

  updateParent(newVal) {
    if (newVal == null) newVal = []

    if(this.props.updateCb) this.props.updateCb(newVal)
  }

  // TODO: is this still needed? possibly a select2 3.x issue?
  static select2Focus() {
    let select2 = $(this).data('select2')
    setTimeout(() => {
      if(!select2.opened()) {
        select2.open()
      }
    }, 0)
  }

  handleUnselecting(e) {
    // when the little X on a pill is clicked, e.params.args.data.element is populated;
    // when you select an already selected choice in the results, it is not
    if(!e.params.args.data.element) {
      e.preventDefault();
      e.stopPropagation();
      $(this.s2Input).select2('close');
    }
  }

  handleClose(e) {
    /*
    * this one sucks. basically, it's a bug when you add a new option, then type in the same new
    * option again, but select an existing category. for example, if i have a category called
    * "abc def" and i follow these steps:
    *   1. type in "abc"
    *   2. select "New: abc"
    *   3. type in "abc"
    *   4. click on the existing "abc def"
    * it will duplicate the new option, so the list will then look like "abc, abc, abc def."
    * i couldn't quite figure out exactly why this was happening, but it seems like select2 is
    * creating an extra selected option element for the "New: abc" option that appears in the list
    * during step 3 (since that item is technically already selected), so when you click on something
    * else, it then duplicates the new item in the list.
    * the only way i could figure out to get around it was this, which is to dedupe the list of selected
    * options manually after select2 closes and then re-trigger the change event on the input.
    */
    let existingVals = [];
    let removeElements = [];
    $(this.s2Input).find(':selected').each((idx, elt) => {
      if(existingVals.includes(elt.value)) {
        removeElements.push(elt);
      } else {
        existingVals.push(elt.value);
      }
    });
    removeElements.forEach(elt => elt.remove());
    $(this.s2Input).trigger('change');
  }
}
