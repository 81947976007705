import React from 'react';
import AjaxUtils from '@es6/ajax_utils.es6';
import ClassUtils from '@es6/class_utils.es6';
import FormattingUtils from '@es6/formatting_utils.es6';

export default class PagingList extends React.Component {
  constructor(props) {
    super(props)
    ClassUtils.autobind(this)
    this.filters = {}
    this.searchResults = {}
    this.lastForceReload = 0
    this.timer
    this.state = { filters: '{}', resultsInfo: '', selectedInfo: '' }
  }

  componentDidMount() {
    this.lastAjaxParamsJson = null
    this.filters = this.props.filters

    this.initTable()
    $(this.tableEl).find('#select_all').on('click', this.selectAllClick )
    $(this.tableEl).find('tbody').on( 'change', 'input[type="checkbox"].pageable-item', this.selectClick )
    $(this.tableEl).find('tbody').on( 'click', '.item-action', this.actionClick )
    $(this.tableEl).find('tbody').on( 'click', '.clickable-item-name', this.itemNameClick )
  }

  componentWillUnmount() {
    if ( this.dtTable ) {
      this.dtTable.destroy()
      this.dtTable = null
    }
  }

  static getDerivedStateFromProps(nextProps, prevState){
    let newFiltersJson = JSON.stringify(nextProps.filters)
    let lastFiltersJson = JSON.stringify(prevState.filters)
    if (newFiltersJson != lastFiltersJson) {
      return { filters: nextProps.filters };
    }
    return null;
  }

  componentDidUpdate(prevProps, _prevState) {
    const newFiltersJson = JSON.stringify(this.props.filters);
    const lastFiltersJson = JSON.stringify(prevProps.filters);
    let selectCol = this.dtTable.column(0)
    selectCol.visible( this.props.showSelects )
    clearTimeout(this.timer); // reset timer for debounce
    if (newFiltersJson != lastFiltersJson){
      this.setState( { filters: this.props.filters } )
      this.lastAjaxParamsJson = null
      this.filters = this.props.filters
      this.dtTable.page(0)

      const reloadAjax = this.dtTable.ajax.reload( null, false ) // search again with new filters 
      // Debouncer
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {reloadAjax}, 250)
      return this.timer

    } else if (this.props.forceReload != this.lastForceReload) {
      this.lastForceReload = this.props.forceReload
      this.lastAjaxParamsJson = null
      this.dtTable.ajax.reload( null, false )
    }
  }

  render() {
    let headers = [ <th key="0"><input type="checkbox" id="select_all" /></th> ]
    headers = headers.concat( this.props.columns.map( function(col,idx) { if (col.title) return <th key={idx}>{col.title}</th> } ) )
    let selectedInfo;
    if(this.state.resultsInfo != null) {
      selectedInfo = <div className="selected-info">
        <span>{this.state.resultsInfo}</span>
        <br/>
        <span>{this.state.selectedInfo}</span>
      </div>
    } else {
      selectedInfo = <div className="selected-info">{this.state.selectedInfo}</div>
    }
    
    return (
      <div className="cmp-paging-list">
        {selectedInfo}
        <table className="cmp-paging-list-table" width="100%" ref={(table) => { this.tableEl = table }} >
          <thead>
            <tr>{headers}</tr>
          </thead>
        </table>
      </div>
    )
  }

  initTable() {
    let MY = this

    let displayStart = 0
    if (this.props.page) {
      displayStart = (this.props.page - 1) * this.props.itemsPerPage
    } 

    MY.dtTable = $(MY.tableEl).DataTable({
      columnDefs: MY.getColDefs(),
      columns: MY.props.columns,
      dom: 'tr<"cmp-paging-list-paging"pi>', // so ugly, see https://datatables.net/reference/option/dom
      info: true,
      lengthChange: false,
      order: ( this.props.order ? this.props.order : [[ 1, 'asc' ]] ),
      displayStart: displayStart,
      pageLength: this.props.itemsPerPage,
      pagingType: 'full_numbers',
      language: {
        paginate:{
          next: "&gt;",
          previous: "&lt;"
        },
        emptyTable: `No matching ${this.props.itemDesc}`
      },
      processing: true,
      autoWidth: false,
      searching: false,
      serverSide: true,

      ajax: function(data, callback, settings) {
        let searchParams = MY.toSearchParams(data)
        let searchParamsJson = JSON.stringify( searchParams )
        if (searchParamsJson != MY.lastAjaxParamsJson) {
          MY.lastAjaxParamsJson = searchParamsJson
        } else {
          MY.handleSearchResults( callback ) // nothing changed, dont hit server unnecessarily, reuse last results
          return
        }

        ssly.Modal.show( { message: 'Filtering...', spinner: true } )

        $.ajax({
          url: MY.props.filteringUrl,
          data: searchParams,
          dataType: 'json',
          error: function(xhr, txtStatus, error) {
            if (!AjaxUtils.handleUnauthorized(xhr.status)) {
              if(!(xhr.responseJSON && xhr.responseJSON.message && xhr.responseJSON.message.includes('pending agreement'))) {
                alert( 'An error occurred while filtering: ' + error)
              }
            }
            ssly.Modal.hide()
          },
          success: function(resp) {
            MY.searchResults = resp
            MY.handleSearchResults( callback )
          },
          timeout: 60000
        })
      }
    })
  }

  handleSearchResults( callback ) {
    $(this.tableEl).find('#select_all').prop('checked', false)
    callback( this.toDtResponse(this.searchResults) )
    this.updateSelectInfo()
    if (this.props.searchCb) this.props.searchCb( this.searchResults, { sortColumn: this.sortColumn, sortDir: this.sortDir, page: this.currentPage } )

    let pageInfo = this.dtTable.page.info()
    if (pageInfo.page >= pageInfo.pages && pageInfo.page > 0) {
      this.dtTable.page('previous').draw('page')
    }

    ssly.Modal.hide()
  }

  // convert to our endpoint's request params
  toSearchParams(dtData) {
    let params = {}
    if (this.filters) {
      let filterParams = this.props.filterTranslator.translateToUrlParamsJson( this.filters )
      $.extend(params, filterParams)
    }

    if (dtData.start >= 0) {
      params.page = (dtData.start/dtData.length) + 1
      params.per = dtData.length
    }
    this.currentPage = params.page

    if (dtData.order && dtData.order.length > 0 && dtData.order[0].column > 0) {
      let col = dtData.order[0].column
      params.sort = this.props.columns[col].data
      params.direction = dtData.order[0].dir
      this.sortColumn = col
      this.sortDir = params.direction
    }
    return params
  }

  toDtResponse(respJson) {
    let items = respJson
    if (this.props.respContainer) {
      items = items[this.props.respContainer]
    }

    return {
      recordsTotal: respJson.total_returned,
      recordsFiltered: respJson.total_returned,
      data: (items ? items : [])
    }
  }

  // this'll stick the checkbox with the items' id as its value in the 1st column
  getColDefs() {
    return [{
      'targets': 0,
      'searchable': false,
      'orderable': false,
      'className': 'dt-body-center',
      'render': function(data, type, full, meta){
          return '<input class="pageable-item" type="checkbox" value="' + data + '">'
      }
    }]
  }

  selectAllClick(event) {
    $(this.tableEl).find('input[type="checkbox"].pageable-item').prop('checked', event.target.checked)
    this.updateSelectInfo()
  }

  selectClick(event) {
    if(!event.target.checked) $(this.tableEl).find('#select_all').prop('checked', false)
    this.updateSelectInfo()
  }

  actionClick(event) {
    let targetEle = event.target
    if ( !targetEle.getAttribute('data-action') ) {
      targetEle = event.target.parentElement
    }

    let itemId = targetEle.getAttribute('data-item-id')
    let action = targetEle.getAttribute('data-action')
    let actionInfo = targetEle.getAttribute('data-action-info')
    if (this.props.itemActionCb) this.props.itemActionCb( itemId, action, actionInfo )
  }

  itemNameClick(event) {
    let itemId = event.target.getAttribute('data-item-id')
    if (this.props.itemClickCb) this.props.itemClickCb( itemId )
  }

  updateSelectInfo() {
    let selectState = this.getItemSelectState()

    let selectCount = 0
    if (selectState.all && !this.props.selectOnlyThisPage) {
      selectCount = this.searchResults.total_count
    } else {
      selectCount = selectState.itemIds.length
    }

    let resultsInfo = null
    let selectedInfo = ''
    let totalCount = this.searchResults.total_count
    let totalMatched = this.searchResults.total_matched
    if (totalCount != null) {
      selectedInfo = `${totalCount} matching ${this.props.itemDesc}`
      if(totalMatched && totalMatched > totalCount) {
        resultsInfo = `Showing ${totalCount} of ${totalMatched} matching ${this.props.itemDesc}`
      }
    }

    if (this.props.showSelects && this.searchResults.total_returned > 0) {
      if(totalMatched && totalMatched > totalCount) {
        selectedInfo = `${selectCount} of ${totalCount} selected`
      } else {
        selectedInfo = `${selectCount} of ${totalCount} matching ${this.props.itemDesc} selected`
      }
    }
    this.setState( { resultsInfo: resultsInfo, selectedInfo: selectedInfo } )

    if (this.props.selectionCb) this.props.selectionCb( selectState )
  }

  getItemSelectState() {
    let selectState = { all: false, itemIds: [] }
    if ( $(this.tableEl).find('#select_all').is(':checked') ) {
      selectState.all = true
    }

    $(this.tableEl).find('input[type="checkbox"]:checked.pageable-item').each( function(idx, el) {
      selectState.itemIds.push( $(el).val() )
    })

    return selectState
  }

  static itemActionHtml(actionType, id, actionInfo) {
    let actionInfoData = actionInfo ? `data-action-info="${actionInfo}"` : ''
    let actionName = actionType.charAt(0).toUpperCase() + actionType.slice(1)
    return `<a class="item-action" data-item-id="${id}" data-action="${actionType}" ${actionInfoData} href="#">${actionName}</a>`
  }

  static readableDateTime(dt, type) {
    return (type === 'sort' ? Date.parse(dt) : FormattingUtils.readableDateTime(dt))
  }

  static checkmarkIcon(checked, type, doc) {
    if(type === 'display') return checked ? '&#x2713;' : ''
    return checked
  }

  static clickableItemName(name, type, doc) {
    const safeName = name ? $.fn.dataTable.render.text().display(name) : ''
    if (type == 'display') {
      return `<a href="#" class="clickable-item-name" data-item-id="${doc.id}">${safeName}</a>`
    } else {
      return safeName
    }
  }
}
