import React, { useState } from 'react';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SiteNotification = ({
  date, // string, in notification.start_time.strftime('%B %d, %Y') format
  subject, // string
  collapsible, // boolean
  body, // string, HTML content
  id
}) => {
  const [expanded, setExpanded] = useState(!collapsible)

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <div className="site-notification__wrapper" data-testid={`site-notification-wrapper-${id}`}>
      <p className="site-notification__date" data-testid={`site-notification-date-${id}`}>{ date }</p> {/* TODO: replicate previous styling in presenter */}
      <h3 className="site-notification__subject" data-testid={`site-notification-subject-${id}`}>{ subject }</h3>
      <div className={`site-notification__body-outer site-notification__body-outer--${expanded ? 'expanded' : 'collapsed' }`}>
         <div className='site-notification__body-inner' dangerouslySetInnerHTML={{__html: body}} />
      </div>
      {collapsible &&
        <a 
          className={'site-notification__expand-btn'} 
          onClick={handleExpandClick}
          data-testid={`site-notification-expand-btn-${id}`}
        >
          { expanded ? 'Show Less' : 'Show More' }
        </a>    
      }
      <div className="site-notification__divider"></div> {/* TODO: replace with CSS */}
    </div>
  )
};

const SiteNotificationsTray = ({
  notifications, // array of objects
  open, // boolean, whether the tray is to be displayed or not
  closeCallback // callback to parent to notify button was clicked to close it (TODO: better way to do this?)
}) => {
  const notificationsContent = () => {
    if(notifications.length == 0) {
      return (<h3>No New Notifications</h3>);
    } else {
      return (
        notifications.map((notification, index)=>{
          return (
            <SiteNotification 
              {...notification} 
              key={'notification-' + index} 
              id={'notification-' + index} 
            />
          );
        })
      )
    }
  };

  return (
    <div className={`site-notifications-tray${open ? ' site-notifications-tray--open' : ''}`}
        data-testid='site-notifications-tray'>
      <div className="site-notifications-tray__header">
        <FontAwesomeIcon
          className="site-notifications-tray__close-arrow"
          onClick={closeCallback}
          icon={faChevronRight}
          data-testid="site-notification-tray-close-arrow" 
        />
        <h2 data-testid='h2-notifications'>
          Notifications
        </h2>
      </div>

      <div className="site-notifications-tray__content">
        {notificationsContent()}
      </div>
    </div>
  )
}

export default SiteNotificationsTray;