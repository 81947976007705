import React from 'react';
import Button from '../../shared/end_user/button.es6';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faXmark } from '@fortawesome/pro-solid-svg-icons';

/*
Prop info: 
 - customAlign (optional)
    * obj with optional properties {heading: '', footer: ''} 
    * values can be 'center' or 'right'
 - footerButtons (optional)
    * array of objs {text: '', type: '', callback: method, isDisabled: boolean}
    * type value can be 'primary', 'secondary' or 'tertiary'
    * isDisabled is optional, defaults to false
*/

const Modal = ({
  visible, // boolean, controls whether modal is visible
  heading, // string, heading text to display on modal
  children, // modal body (jsx)
  closeable, // boolean, whether to display close ('x') icon
  closeCallback, // callback, hides modal onClick
  spinner = false, // boolean (optional), whether to render spinner icon/animation
  customAlign = null, // object (optional) - see prop info above
  footerButtons = null, // object (optional) - see prop info above
  size = null // string (optional) - small, medium (default), and large.
}) => {
  const style = {
    center: `modal-custom-align-center`,
    left: `modal-custom-align-left`
  };

  let modalSize;
  switch (size) {
    case 'small':
      modalSize = 'small';
      break;
    case 'large':
      modalSize = 'large';
      break;
    default:
      modalSize = 'medium';
  }

  return (
    <div className={`modal-background${visible ? ' modal--visible' : ''}`}>
      <div
        className={`modal modal--${modalSize}`}
        data-testid={`modal-${heading}`}
      >
        {spinner && (
          <FontAwesomeIcon
            icon={faSpinner}
            className="modal-spinner fa-spin-pulse"
            data-testid="modal-spinner"
          />
        )}
        {/* left aligned by default. Override using the customAlign prop */}
        <div
          className={`modal-heading ${
            customAlign?.['heading'] ? style[customAlign['heading']] : ''
          }`}
        >
          {heading}
        </div>

        {/* pass in modal body as jsx */}
        {children}

        {/* right aligned by default. Override using the customAlign prop */}
        {footerButtons && (
          <div
            className={`modal-footer ${
              customAlign?.['footer'] ? style[customAlign['footer']] : ''
            }`}
            data-testid="modal-footer"
          >
            {footerButtons.map((btn, i) => {
              return (
                <Button
                  key={`${i}-${btn.text}`}
                  icon={btn.icon}
                  text={btn.text}
                  type={btn.type}
                  callback={btn.callback}
                  isDisabled={btn.isDisabled}
                ></Button>
              );
            })}
          </div>
        )}

        {closeable && (
          <div
            className="modal-close"
            onClick={closeCallback}
            data-testid="modal-close"
          >
            <FontAwesomeIcon icon={faXmark} size="xs" />
          </div>
        )}
      </div>
    </div>
  );
};

export default Modal;
