export default class ClassUtils {
  // bind all the method of provided object to itself so 'this' is really 'this' in event handlers
  static autobind(obj) {
    for (const key of Object.getOwnPropertyNames(obj.constructor.prototype)) {
      const prop = obj[key];
      if (key !== 'constructor' && typeof prop === 'function') {
        obj[key] = prop.bind(obj);
      }
    }
  }

  static clone(obj) {
    if ( Array.isArray(obj) ) {
      return $.extend( true, [], obj )
    } else {
      return $.extend( true, {}, obj )
    }
  }
}
