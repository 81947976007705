import React from 'react';
import TableControl from './table_control.es6';

const FileTypeDropdownItem = ({ type, selectCallback }) => {
  const handleClick = () => {
    selectCallback(type.mime);
  };

  return (
    <div
      className="table-control__dropdown-list-item"
      data-testid={`selectable-file-type-${type.mime}`}
      onClick={handleClick}
    >
      {type.name}
    </div>
  );
};

const FileTypeControl = ({
  fileTypes, // array of all available file types with friendly name and mime. e.g. { name: 'PNG Image', mime: 'application/png' }
  selectedType, // optional string, mime type we're currently filtering by. e.g. 'application/png'
  applyNewFilters // callback that triggers a fresh query when this component changes
}) => {
  const handleSelect = (fileType) => {
    if (fileType != selectedType) {
      applyNewFilters({ page: 1, content_type: fileType });
    }
  };

  const populateSelectionText = () =>
    fileTypes.find((ft) => ft.mime == selectedType)?.name || 'All types';

  const dropdown = () => (
    <div className="table-control__dropdown-list">
      <FileTypeDropdownItem
        type={{ name: 'All types', mime: null }}
        selectCallback={handleSelect}
      />
      {fileTypes.map((type, i) => (
        <FileTypeDropdownItem
          key={i}
          type={type}
          selectCallback={handleSelect}
        />
      ))}
    </div>
  );

  return (
    <TableControl
      controlTitle={'File Type'}
      selectionText={populateSelectionText()}
      dropdown={dropdown()}
    />
  );
};

export default FileTypeControl;
