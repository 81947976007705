import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark } from '@fortawesome/pro-solid-svg-icons';

const ToggleSwitch = ({
  name,
  text = null,
  isOn,
  handleToggle,
  previewByAdmin
}) => {
  //set up icons name for testing
  const iconOn = 'faCheck';
  const iconOff = 'faXmark';
  return (
    <div className="toggle-switch" data-testid={`toggle-switch-${name}`}>
      <input
        defaultChecked={isOn}
        onChange={previewByAdmin ? null : handleToggle}
        className="toggle-switch__checkbox"
        type="checkbox"
        name={name}
        id={name}
      />
      <label
        className={`${
          previewByAdmin
            ? 'toggle-switch__label toggle-switch__label-disabled'
            : 'toggle-switch__label'
        }`}
        htmlFor={name}
      >
        <span className="toggle-switch__slider">
          <FontAwesomeIcon
            icon={isOn ? faCheck : faXmark}
            className={`${
              isOn
                ? 'toggle-switch__slider-icon-on'
                : 'toggle-switch__slider-icon-off'
            }`}
            data-testid={`toggle-switch__slider-icon-${
              isOn ? iconOn : iconOff
            }`}
          />
        </span>
      </label>
      {text && <div className="toggle-switch__text">{text}</div>}
    </div>
  );
};

export default ToggleSwitch;
