import React from 'react';
import TableControl from '../../shared/end_user/table_control.es6';

const DateRangeDropdownItem = ({ startDate, optionText, selectCallback }) => {
  const handleClick = () => {
    selectCallback(startDate);
  };

  return (
    <div className="table-control__dropdown-list-item" onClick={handleClick}>
      {optionText}
    </div>
  );
};

const DateRangeControl = ({ startDate, applyNewFilters }) => {
  // Because none of the current options modify the end date, it is always null.

  const getDateDaysAgo = (daysAgo) => {
    const date = new Date();
    date.setDate(date.getDate() - daysAgo);
    // return only date part of ISO string, YYYY-MM-DD, needed for Rails to parse
    return date.toISOString().replace(/T.*/, '');
  };

  // Uses IIFE to populate variable with return value of function executed once.
  // See https://developer.mozilla.org/en-US/docs/Glossary/IIFE
  const startDatesToOptionsMap = (() => {
    var dateMap = {};

    // Uses 'Last # Days' - 1 so that date ranges are inclusive of today.
    dateMap[getDateDaysAgo(0)] = 'Today';
    dateMap[getDateDaysAgo(6)] = 'Last 7 days';
    dateMap[getDateDaysAgo(29)] = 'Last 30 Days';
    dateMap[getDateDaysAgo(59)] = 'Last 60 Days';
    dateMap[getDateDaysAgo(89)] = 'Last 90 Days';
    return dateMap;
  })();

  const getSelectionText = () => {
    if (!startDate) return 'All time';

    return startDatesToOptionsMap[startDate]
      ? startDatesToOptionsMap[startDate]
      : 'Custom';
  };

  // Calling callback here vs. on item so we can check if selected date is new.
  const handleSelect = (newStartDate) => {
    if (newStartDate != startDate) {
      applyNewFilters({ page: 1, start_date: newStartDate });
    }
  };

  const dropdownItems = () => {
    const options = Object.entries(startDatesToOptionsMap);
    options.push([null, 'All time']);
    return options.map(([startDate, option]) => {
      return (
        <DateRangeDropdownItem
          optionText={option}
          startDate={startDate}
          selectCallback={handleSelect}
          key={option}
        />
      );
    });
  };

  const dropdown = () => {
    return (
      <div className="table-control__dropdown-list">{dropdownItems()}</div>
    );
  };

  return (
    <TableControl
      controlTitle="Date"
      selectionText={getSelectionText()}
      dropdown={dropdown()}
    />
  );
};

export default DateRangeControl;
