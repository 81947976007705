import React from 'react';
import ClassUtils from '@es6/class_utils.es6';

export default class CategoryFilter extends React.Component {
  constructor(props) {
    super(props);
    ClassUtils.autobind(this)
  }

  render() {
    let classes = `cmp-category-filter cmp-selectable-button ${this.props.selected ? 'selected' : ''}`;
    return (
      <div className={classes} data-category-id={this.props.category.id} onClick={this.categoryClick} >{this.props.category.name}</div>
    )
  }

  categoryClick(event) {
    if (this.props.categoryClick) this.props.categoryClick( this.props.category );
  }
}