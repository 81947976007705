import React, { useState } from 'react';
import Modal from '@components_new/shared/end_user/modal.es6.jsx';

const AgreementModal = ({
  agreement,
  downloadPath,
  responsePath,
  destroySessionPath,
  authToken
}) => {
  const [checked, setChecked] = useState(false);
  const { name, notification_only, body, required } = agreement;

  const handleAcceptTerms = () => {
    setChecked(!checked);
  };

  const sendAgreementResponse = async (params) => {
    await fetch(responsePath, {
      method: 'PATCH',
      body: JSON.stringify(params),
      headers: {
        'X-CSRF-Token': authToken,
        'Content-Type': 'application/json'
      }
    });
    window.location.reload();
  };

  const handleClose = () => {
    sendAgreementResponse({ response: 'viewed' });
  };

  const handleAccept = () => {
    sendAgreementResponse({ response: 'accepted' });
  };

  const handleDecline = () => {
    sendAgreementResponse({ response: 'declined' });
  };

  // user will be logged out if they choose to decline a required agreement
  const handleLogout = async () => {
    if (confirm('Declining will log you out. Are you sure?')) {
      await fetch(destroySessionPath, {
        method: 'DELETE',
        headers: {
          'X-CSRF-Token': authToken
        }
      });
      // refresh, letting Rails handle redirect to correct login
      window.location.reload();
    }
  };

  const responseBtns = [
    {
      text: 'Accept',
      type: 'primary',
      callback: handleAccept,
      isDisabled: required && !checked
    },
    {
      text: 'Decline',
      type: 'secondary',
      callback: required ? handleLogout : handleDecline
    }
  ];

  const closeButton = [
    {
      text: 'Close',
      type: 'primary',
      callback: handleClose
    }
  ];

  return (
    <Modal
      visible={true}
      heading={name}
      size="large"
      closeable={false}
      footerButtons={notification_only ? closeButton : responseBtns}
      customAlign={{ footer: 'left' }}
    >
      <div className="agreement-modal">
        <div
          className="agreement-modal_text-area"
          data-testid="agreement-content"
        >
          {body}
        </div>
        <div className="agreement-modal_download">
          <a href={downloadPath} data-testid="download-link">
            Download
          </a>
        </div>

        {required && (
          <div className="agreement-modal_accept-terms">
            <label>
              <input
                type="checkbox"
                checked={checked}
                onChange={handleAcceptTerms}
              />
              I accept the terms
            </label>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default AgreementModal;
