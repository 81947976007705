import React, { useState } from 'react';
import ToggleSwitch from '../../../shared/end_user/toggle_switch.es6';

const ReadOnlyToggle = ({ isOn, patchEndpoint, authToken }) => {
  const [toggled, setToggled] = useState(isOn);

  const handleToggle = async () => {
    // the update endpoint expects query param key in this format
    const key = encodeURIComponent('documents[][read_only]');
    const url = `${patchEndpoint}?${key}=${!toggled}`;

    await fetch(url, {
      method: 'PATCH',
      headers: {
        'X-CSRF-Token': authToken,
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
      },
      redirect: 'manual' // because update endpoint sends 302 back to doc show page
    });

    setToggled(!toggled);
    window.location.reload(); // to show success flash message from BE
  };
  return (
    <ToggleSwitch
      name="read-only-toggle"
      isOn={toggled}
      handleToggle={handleToggle}
    ></ToggleSwitch>
  );
};

export default ReadOnlyToggle;
