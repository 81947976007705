import React from 'react';
import ClearableField from './clearable_field.es6';
import PagingList from './paging_list.es6';
import ClassUtils from '@es6/class_utils.es6';

export default class PagingListWithActions extends React.Component {
  constructor(props) {
    super(props);
    ClassUtils.autobind(this);

    let filters = ClassUtils.clone(this.props.filters);
    filters.textSearch = null;
    this.state = {
      item_count: 0,
      selectedItems: { all: false, itemIds: [] },
      forceReload: 0
    };
  }

  // Parent callable: Refetch the data in the list (e.g. after items are modified externally)
  refreshList() {
    this.setState((state) => {
      return { forceReload: this.state.forceReload + 1 };
    });
  }

  // Parent callable: Extract current select state for action usage
  getSelectInfo() {
    let selectCount = this.state.selectedItems.all
      ? this.state.item_count
      : this.state.selectedItems.itemIds.length;
    let ids = this.state.selectedItems.itemIds.join(',');
    return {
      all: this.state.selectedItems.all,
      ids: ids,
      selectCount: selectCount
    };
  }

  // parent callable
  getTotalCount() {
    return this.state.item_count;
  }

  static renderActions(actionTypes, item, extractActionInfoCb) {
    return actionTypes.reduce((actions, type, idx) => {
      let actionInfo = extractActionInfoCb
        ? extractActionInfoCb(type, item)
        : null;
      const prefix = idx === 0 ? '' : ' | ';
      actions += prefix + PagingList.itemActionHtml(type, item.id, actionInfo);
      if (idx + 1 < actionTypes.length) {
        actions += ' ';
      }
      return actions;
    }, '');
  }

  render() {
    const itemsSelected = this.state.selectedItems.itemIds.length > 0;
    const actionBarClass = this.props.actionBarClass || 'action-bar';
    let searchActionBarClass = 'search-filter';
    if (itemsSelected) {
      searchActionBarClass += ' search-filter-with-items-selected';
    }
    return (
      <div className="cmp-paging-list-with-actions">
        <div className={actionBarClass}>
          {this.props.anytimeActions}
          {itemsSelected && this.props.selectedActions}
          {this.props.anytimeActionsAfter}
          {itemsSelected && this.props.multiSelectedActions}
          {this.props.includeTextSearch && (
            <ClearableField
              className={searchActionBarClass}
              label="Search"
              iconClass="search-icon"
              value={this.props.filters.textSearch}
              onChange={this.textSearchChange}
              debounce={true}
            />
          )}
        </div>

        <PagingList
          columns={this.props.columns}
          order={this.props.order}
          itemsPerPage={this.props.itemsPerPage}
          showSelects={this.props.showSelects}
          filters={this.props.filters}
          filterTranslator={this.props.filterTranslator}
          filteringUrl={this.props.filteringUrl}
          page={this.props.page}
          itemDesc={this.props.itemDesc}
          respContainer={this.props.respContainer}
          forceReload={this.state.forceReload}
          searchCb={this.searchCb}
          selectionCb={this.selectionCb}
          itemActionCb={this.props.itemActionCb}
          itemClickCb={this.props.itemClickCb}
          selectOnlyThisPage={this.props.selectOnlyThisPage}
        />
      </div>
    );
  }

  textSearchChange(newText) {
    if (this.props.textSearchChangeCb) this.props.textSearchChangeCb(newText);
  }

  searchCb(searchResults, sortAndPagingInfo) {
    this.setState((state) => {
      return { item_count: searchResults.total_count };
    });

    if (this.props.searchCb)
      this.props.searchCb(searchResults, sortAndPagingInfo);
  }

  selectionCb(selectState) {
    this.setState({ selectedItems: selectState });
  }
}
