import React from 'react';
import ProgressBar from './progress_bar.es6';
import DatePicker from '@components_new/shared/date_picker.es6';
import ClassUtils from '@es6/class_utils.es6';

export default class UploadedDocumentRow extends React.Component {
  constructor(props) {
    super(props)
    ClassUtils.autobind(this)
  }

  render() {
    return (
      <React.Fragment>
        <tr key={this.props.doc.upload_form_row_id + '_form'} className="upload">
          <td>{this.props.doc.file_name}</td>
          {this.props.watermarkCol &&
          <td>
            {this.props.doc.content_type.includes('application/pdf') &&
            <input name="documents[][enable_watermark]" type='checkbox'
                   defaultChecked={this.props.doc.enable_watermark} onChange={this.updateWatermark} />
            }
          </td>
          }
          <td>
            {this.props.doc.status === 'success' &&
            <input name="documents[][name]" type="text" defaultValue={this.props.doc.name}
                   onChange={this.updateName}/>
            }
          </td>
          {this.props.date &&
          <td className="date">
            {this.props.doc.status === 'success' &&
            <div name="documents[][date]">
              <DatePicker onChange={this.updateDate}/>
            </div>
            }
          </td>
          }
          <td className="desc">
            {this.props.doc.status === 'success' &&
            <textarea name="documents[][description]" defaultValue={this.props.doc.description} rows="2"
                      style={{width: '100%'}} onChange={this.updateDescription}/>
            }
            {this.props.doc.status === 'fail' &&
            <span className="upload-error">{this.props.doc.description}</span>
            }
          </td>
          <td className="center">
            {this.props.doc.status === 'securing' &&
            [<span key="pending-text">Securing File&nbsp;</span>,
              <img key="pending-spinner"
                   src="/assets/spinner-0eddaab3b8cb0b15d81d62e5ae5960329c3e576ea78dc321b20734ab20271847.gif"
                   alt="Spinner"/>]
            }
            {this.props.doc.status === 'uploading' &&
            <a className="cancel" onClick={this.props.abortUpload}>Cancel upload</a>
            }
            {this.props.doc.status === 'success' &&
            <span className="upload-success">Queued for Virus Scanning</span>
            }
            {this.props.doc.status === 'fail' &&
            <span className="upload-error">&#x2717; Failed</span>
            }
          </td>
        </tr>
        <ProgressBar key={this.props.doc.upload_form_row_id + '_bar'}
                     status={this.props.doc.status}
                     progress={this.props.doc.progress}
                     colspan={this.props.columns}/>
      </React.Fragment>
    )
  }

  updateName(e) {
    const newName = e.target.value
    this.updateParent({...this.props.doc, name: newName})
  }

  updateDate(newDate) {
    this.updateParent({...this.props.doc, date: newDate})
  }

  updateDescription(e) {
    const newDesc = e.target.value
    this.updateParent({...this.props.doc, description: newDesc})
  }

  updateWatermark(e) {
    const checked = e.target.checked
    this.updateParent({...this.props.doc, enable_watermark: checked})
  }

  updateParent(newDoc) {
    this.props.docUpdateCb(this.props.rowId, newDoc)
  }
}
