import React from 'react';
import TableControl from '../../shared/end_user/table_control.es6';
import DocumentActions from '@es6/documents/document_actions.es6';

const BulkActionDropdownItem = ({ text, callback, disabled }) => {
  const handleClick = () => {
    callback();
  };

  return (
    <div
      className={`table-control__dropdown-list-item${
        disabled ? ' table-control__dropdown-list-item--disabled' : ''
      }`}
      data-testid={`table-control_dropdown-list-item`}
      onClick={disabled ? null : handleClick}
    >
      {text}
    </div>
  );
};

const BulkActionsControl = ({
  selectedDocs, // array of document objects example: {id: integer, isAudio: boolean, isVideo: boolean, isReadOnly: boolean}
  // for currently selected documents on table
  allSharedIds, // array of IDs for all the user's documents without filters applied
  userId, // integer, current user or user being previewed
  preview, // boolean, whether or not page is being previewed by admin
  uploaded, // string of 'end_user' if user is viewing their uploaded docs
  authToken, // string, CSRF token to include with POST requests
  setModalState, // callback function that changes the state of the parent component modal
  closeModal // callback function that closes the parent component modal
}) => {
  // TODO: consider moving this to parent component so fetch logic can be shared
  const handleDownload = async ({ ids }) => {
    // opted to use download_multiple for single doc downloads as a quick fix for download bug (see ss-417)

    const joinedId = ids.join(','); // handled by BE as comma-separated string
    var joinedParams = preview
      ? `preview=${preview}&user_id=${userId}&id=${joinedId}`
      : `user_id=${userId}&id=${joinedId}`;
    if (uploaded) joinedParams += `&uploaded=${uploaded}`;

    var url = '/documents/download_multiple';

    const urlWithParams = url + '?' + joinedParams;

    const selectInfo = { ids: joinedId, selectCount: ids.length };

    // reusing existing confirmSelectedDocsAction but using custom perform func.
    if (
      DocumentActions.confirmSelectedDocsAction(
        selectInfo,
        joinedParams,
        'download'
      )
    ) {
      sendBackgroundRequest({
        path: urlWithParams,
        message: 'Preparing your download, please wait...'
      });
    }
  };

  const sendBackgroundRequest = async ({ path, message }) => {
    setModalState({
      visible: true,
      heading: 'Downloading files',
      message: modalBody(message),
      spinner: true,
      closeable: false,
      customAlign: {
        heading: 'center'
      }
    });

    try {
      const postResponse = await fetch(path, {
        method: 'POST',
        headers: {
          'X-CSRF-Token': authToken,
          'Content-Type': 'application/json'
        }
      });

      const postResponseBody = await postResponse.json();
      if (!postResponse.ok) {
        backgroundRequestJobFailed(postResponseBody);
      } else {
        setTimeout(() => {
          checkBackgroundRequestStatus(postResponseBody.job_id);
        }, 2000);
      }
    } catch (error) {
      backgroundRequestJobFailed(
        `Initial POST request failed: ${error.message}`
      );
    }
  };

  const checkBackgroundRequestStatus = async (job_id) => {
    if (job_id) {
      // confirm JSON content type is not required in headers
      await fetch('/api/v1/background_status/' + job_id)
        .then((response) => response.json())
        .then((responseBody) => {
          if (responseBody.status === 'complete') {
            backgroundRequestJobComplete(responseBody);
            return;
          }

          if (
            responseBody.status === 'queued' ||
            responseBody.status === 'working'
          ) {
            setTimeout(function () {
              checkBackgroundRequestStatus(job_id);
            }, 2000);
            return;
          }

          backgroundRequestJobFailed(responseBody); // all other statuses from server indicate failure
        })
        .catch((error) => {
          backgroundRequestJobFailed(error);
        });
    } else {
      // Return if there is an error "Fetch failed loading" caused by job_id = null
      backgroundRequestJobFailed('No job_id');
      return;
    }
  };

  const backgroundRequestJobFailed = (responseBody) => {
    const info = responseBody.info;
    const message =
      responseBody === 'No job_id'
        ? "Something went wrong and we weren't able to complete your request."
        : info;
    setModalState({
      visible: true,
      heading: "We're sorry",
      children: modalBody(message),
      spinner: false,
      closeable: true,
      closeCallback: closeModal
    });
  };

  const backgroundRequestJobComplete = (response) => {
    closeModal();

    if (response.error_message) {
      setModalState({
        visible: true,
        heading: "We're sorry",
        children: modalBody(response.error_message),
        spinner: false,
        closeable: true,
        closeCallback: closeModal
      });
    }

    if (response.download_url) {
      window.location.href = response.download_url.replace(/amp;/g, '');
      return false;
    }
  };

  //returns true if all selected docs do have type of media or isReadOnly
  let nonDownloadableDocs = () => {
    if (selectedDocs.length > 0) {
      let allTrue = selectedDocs.every(
        (doc) =>
          doc.isAudio === true ||
          doc.isVideo === true ||
          doc.isReadOnly === true
      );
      return allTrue;
    }
    return false;
  };

  const options = [
    {
      text: `Download All (${allSharedIds.length})`,
      callback: () => handleDownload({ ids: allSharedIds }),
      disabled: false
    },
    {
      text: `Download Selected (${selectedDocs.length})`,
      callback: () =>
        handleDownload({ ids: selectedDocs.map((doc) => doc.id) }),
      disabled: nonDownloadableDocs() ? true : false || selectedDocs.length == 0
    }
  ];

  const dropdownItems = () => {
    return options.map(({ text, callback, disabled }) => {
      return (
        <BulkActionDropdownItem
          text={text}
          callback={callback}
          disabled={disabled}
          key={text}
        />
      );
    });
  };

  const selectionHelperText =
    'Admin settings may exclude certain files from downloads.';

  const dropdown = () => {
    return (
      <div className="table-control__dropdown-list">
        {dropdownItems()}
        <div
          className="table-control__dropdown-list-helper-text"
          data-testid="table-control__dropdown-list-helper-text"
        >
          {selectionHelperText}
        </div>
      </div>
    );
  };

  const modalBody = (msg) => (
    <div className="bulk_actions_control__modal-body">{msg}</div>
  );

  return (
    <TableControl
      controlTitle="Bulk Actions"
      selectionText="Select action"
      dropdown={dropdown()}
    />
  );
};

export default BulkActionsControl;
