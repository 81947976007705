export default class FormattingUtils {
  static readableDateTime(dt) {
    try {
      var d = new Date(dt).toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        year: '2-digit',
        timeZone: 'UTC'
      });
      var date = d.replace(' ', '-').replace(', ', '-');
      return date;
    } catch (e) {
      console.error(e);
      if (typeof Rollbar !== 'undefined') {
        Rollbar.error('readableDateTime error: ' + e + '. input value: ' + dt);
      }
      return dt;
    }
  }
}
