import React, { useState, useEffect } from 'react';

const CookieOptInModal = (props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [currentCookies, setCurrentCookies] = useState([]);
  const [selectableCookies, setSelectableCookies] = useState([
    {
      cookieKey: 'sharesecure_enable_google_analytics',
      friendlyText: 'Google Analytics',
      selected: false
    }
  ]);

  useEffect(() => {
    cookieSettingsAreCurrent() || populateModal()
  }, []);

  function cookieSettingsAreCurrent() {
    const browserCookies = document.cookie.split('; ').map( cookie => cookie.slice(0, cookie.indexOf('=')) );

    setCurrentCookies(currentCookies.concat(browserCookies));
    return selectableCookies.every(c => browserCookies.includes(c.cookieKey));
  }

  function populateModal() {
    const populatedFromBrowser = selectableCookies.map( cookie => {
      if (currentCookies.includes(cookie.cookieKey)) {
        const cookieValue = document.cookie.match(new RegExp('(^| )' + cookie.cookieKey + '=([^;]+)'))[2];
        cookie.selected = cookieValue === 'true';
      }

      return cookie;
    })

    setSelectableCookies(populatedFromBrowser);
    setModalOpen(true);
  }

  function handleCheckboxToggle(index) {
    const updatedSelectableCookies = selectableCookies.map((cookie, i) => {
      if (i === index) {
        cookie.selected = !cookie.selected
      }
      return cookie;
    });

    setSelectableCookies(updatedSelectableCookies);
  }

  function handleSubmit() {
    var oneYearFromNow = new Date();
    oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);

    selectableCookies.forEach( cookie => {
      document.cookie = `${cookie.cookieKey}=${cookie.selected}; expires=${oneYearFromNow}; domain=${props.baseUrl}`
    })
    setModalOpen(false);
  }

  return (
    <div>
      { modalOpen &&
        <div className='cookie-modal-bg'>
          <section className='cookie-modal'>
            <div className='cookie-modal-header'>
              <h2 className='cookie-modal-header-text'>Cookie Notice:</h2>
              <b className='close-modal-button' onClick={() => setModalOpen(false)}>
                X
              </b>
            </div>
            <main className='cookie-modal-body'>
              <p>
                We use cookies to make interactions with our websites and services easy and meaningful for visitors like you,
                and to help us better secure our websites and services.
              </p>
              <p>
                Please note that this website is designed to use the Google Analytics cookie.
              </p>
              {
                selectableCookies.map((cookie, i) => (
                  <div className='opt-in-cookie-selection' key={i}>
                    I opt into the use of {cookie.friendlyText} on this website:
                    <label className='checkbox select-cookie'>
                      <input 
                        type='checkbox'
                        id={cookie.cookieKey}
                        checked={cookie.selected} 
                        onChange={() => handleCheckboxToggle(i)}
                      />
                      <span className='checkmark' />
                    </label>
                  </div>
                ))
              }
            </main>
            <div className='cookie-modal-controls'>
              <button className='accept-btn' onClick={() => handleSubmit()}>
                Save Settings
              </button>
              <a href='/cookie_policy' target='_blank'>Learn More</a>
            </div>
          </section>
        </div>
      }
    </div>
  )
}

export default CookieOptInModal;
