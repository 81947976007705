import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';

const SelectionSummaryBar = ({
  selectedCount, // integer, number of selected items
  totalCount, // integer, cumulative amount of table items after filters are applied
  filteredSubsetOrSelectionPresent, // boolean, true if any content_type, start_date or q filters are active
  handleClearFiltersAndSelection // callback function that resets content_type, start_date and q filters
}) => (
  <div className="selection-summary-bar">
    <span
      className="selection-summary-bar__selected-text"
      data-testid="selection-summary-bar__selected-text"
    >
      <b>{selectedCount}</b> of <b>{totalCount}</b> matching documents selected.
    </span>
    {filteredSubsetOrSelectionPresent && (
      <span
        className="selection-summary-bar__clear-button"
        data-testid="selection-summary-bar__clear-button"
        onClick={() => handleClearFiltersAndSelection()}
      >
        <span className="selection-summary-bar__clear-button-text">
          Clear all
        </span>
        <FontAwesomeIcon
          icon={faXmark}
          className="selection-summary-bar__clear-icon"
        />
      </span>
    )}
  </div>
);

export default SelectionSummaryBar;
