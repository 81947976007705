import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleInfo,
  faTriangleExclamation,
  faCircleCheck,
  faCircleExclamation,
  faXmark
} from '@fortawesome/pro-regular-svg-icons';

const FlashMessage = ({ type, msg }) => {
  const [isOpen, setIsOpen] = useState(true);

  /* types not listed i.e. 'notice', 'alert', 'persistant' or any other type passed in 
  will default to 'success' styling (green) but with no icon.
  */
  const flashStyleByType = {
    success: 'flash-message_success',
    error: 'flash-message_error',
    warning: 'flash-message_warning',
    general: 'flash-message_general'
  };

  const iconByType = {
    success: faCircleCheck,
    error: faCircleExclamation,
    warning: faTriangleExclamation,
    general: faCircleInfo
  };

  return (
    <>
      {isOpen && (
        <div
          className={`${type !== 'persistent' ? 'flash-message_fade-out' : ''}`}
        >
          <div
            className={`flash-message ${
              flashStyleByType[type] ?? 'flash-message_default'
            }`}
            data-testid="flash-message"
          >
            <p>
              <span>
                {!!iconByType[type] && (
                  <FontAwesomeIcon
                    icon={iconByType[type]}
                    data-testid={`flash-message_type-icon`}
                    className="flash-message_type-icon"
                  />
                )}
              </span>
              {msg}
            </p>

            <div>
              <FontAwesomeIcon
                onClick={() => setIsOpen(false)}
                icon={faXmark}
                data-testid={'flash-message_close-button'}
                className="flash-message_close-icon"
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const FlashList = ({ flashMessages }) => {
  const flashMessageList = flashMessages.map((flash, i) => (
    <FlashMessage type={flash[0]} msg={flash[1]} key={`${i}-${flash[0]}`} />
  ));

  return <div className={'flash-container'}>{flashMessageList}</div>;
};

export default FlashList;
