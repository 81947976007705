import React, { useState } from 'react';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const TableControl = ({ controlTitle, selectionText, dropdown }) => {
  const [showDropdownMenu, setShowDropdownMenu] = useState(false); // used for small/mobile screens which need an alternative to the hover effect to control menu visibility
  const generateTestId = (parent) =>
    // Cool Widget becomes table-control-cool-widget
    `table-control-${controlTitle
      .split(' ')
      .join('-')
      .toLowerCase()}-${parent}`;

  return (
    <>
      {/* Larger screens - control menu visibility with hover effect */}
      <div
        className="table-control table-control__no-touch"
        data-testid={generateTestId('primary')}
      >
        <div
          className="table-control__title"
          data-testid="table-control__title"
        >
          {controlTitle}
        </div>
        <div className="table-control__selection-row">
          <div className="table-control__selection-text">{selectionText}</div>
          <FontAwesomeIcon icon={faCaretDown} className="table-control__icon" />
        </div>
        <div className={`table-control__dropdown`}>{dropdown}</div>
      </div>

      {/* mobile devices - control menu visibility with onClick */}
      <div
        className={`table-control table-control__mobile ${
          showDropdownMenu ? 'table-control__mobile-active' : ''
        }`}
        onClick={() => setShowDropdownMenu(!showDropdownMenu)}
        data-testid={generateTestId('mobile')}
      >
        <div
          className="table-control__title"
          data-testid="table-control__title"
        >
          {controlTitle}
        </div>
        <div className="table-control__selection-row">
          <div className="table-control__selection-text">{selectionText}</div>
          <FontAwesomeIcon icon={faCaretDown} className="table-control__icon" />
        </div>
        {showDropdownMenu && (
          <div className={`table-control__dropdown`}>{dropdown}</div>
        )}
      </div>
    </>
  );
};

export default TableControl;
