import React from 'react';

const Footer = (props) => (
  <footer className="footer" data-testid="footer-id">
    {props.legalDisclaimer && (
      <div
        className="footer__legal-disclaimer footer__row"
        data-testid="legal-disclaimer"
      >
        {props.legalDisclaimer}
      </div>
    )}
    <div className="footer__sitewide-info footer__row">
      <span className="footer__copyright">
        © {new Date().getFullYear()} Altvia Solutions, LLC
      </span>
      <span className="footer__links">
        <a
          href="https://www.sharesecurely.com/terms"
          target="_blank"
          rel="noreferrer"
        >
          Terms of Use
        </a>
        <p className="footer__link-divider-bar">|</p>
        <a
          href="https://www.sharesecurely.com/privacy_policy"
          target="_blank"
          rel="noreferrer"
        >
          Privacy Policy
        </a>
        <p className="footer__link-divider-bar">|</p>
        <a href="/cookie_policy" target="_blank" rel="noreferrer">
          Cookie Policy
        </a>
      </span>
    </div>
  </footer>
);

export default Footer;
