import React from 'react';

const BannerButton = ({ text, callback }) => {
  return (
    <button
      className="banner-btn"
      onClick={callback}
      data-testid="user-preview-banner-btn"
    >
      {text}
    </button>
  );
};

const UserPreviewBanner = ({ userName, userId }) => {
  const handleReturnToUserDetails = async () => {
    // If the cross_app parameter is present, close the tab.
    // TODO: this if/else branch should be obsolete soon! see SA-175
    // This is a temporary solution since we have two concurrent sharesecure apps, and need a way to
    // log into this app from the new Admin project in order to use the "View as User" feature.
    // Once our code bases are merged, this 'if' condition can be deleted.
    const currentAddress = window.location.search;
    const params = new URLSearchParams(currentAddress);
    if (params.get('cross_app')) {
      window.close();
    } else {
      window.open(`/admin/organization_users/${userId}`, '_self');
    }
  };
  return (
    <div className="user-preview-banner">
      <span className="user-preview-banner__content user-preview-banner__text">
        <p data-testid="user-preview-banner__text">
          Viewing the portal as:{' '}
          <span
            className="user-preview-banner__name"
            data-testid="user-preview-banner__userName"
          >
            {userName}
          </span>
        </p>
        <BannerButton
          text="Exit View As"
          callback={handleReturnToUserDetails}
        />
      </span>
    </div>
  );
};
export default UserPreviewBanner;
