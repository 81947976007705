import React, { useState, useEffect, useMemo, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInboxIn, faInboxOut } from '@fortawesome/pro-light-svg-icons';
import { faMagnifyingGlass, faXmark } from '@fortawesome/pro-solid-svg-icons';
import CategoryPin from '@components_new/shared/end_user/category_pin.es6';
import InfoTip from './info_tip.es6';

//replace whitespaces in name used in test id
const replaceWhiteSpace = (str) => {
  return str.replaceAll(' ', '-');
};
// for All Documents and My Uploads - parent doc lists for which category/uncategorized filters are applied
const ParentDocumentList = ({
  icon = null, // imported FontAwesome icon, displays next to doc list name
  name = '', // string, name of doc list 'all' or 'uploads'
  count = 0, // integer, number of documents
  animation = null, // string, optional animation to use, accepts 'slide' and 'tag'
  selected = false, // boolean, whether a doc list has been selected
  handleDocListClick = null // callback, triggers on All Documents/My Uploads click
}) => {
  return (
    <div
      className={`category-sidebar__category animate-${animation} ${
        selected ? 'selected-bold' : ''
      }`}
      data-testid={`category-sidebar-parent-list-btn-${replaceWhiteSpace(
        name
      )}`}
      onClick={handleDocListClick}
    >
      {icon && (
        <FontAwesomeIcon
          icon={icon}
          className="category-sidebar__category-icon"
        />
      )}
      <span
        className="category-sidebar__category-name"
        data-testid={`category-sidebar-parent-list-${replaceWhiteSpace(name)}`}
      >
        {name}
      </span>
      <span
        className="category-sidebar__category-count"
        data-testid={`category-sidebar-parent-list-count-${replaceWhiteSpace(
          name
        )}`}
      >
        {count}
      </span>
    </div>
  );
};

const Category = ({
  id = null, // integer, id of the category
  icon = null, // imported FontAwesome icon, optionally displays next to category name
  name = '', // string, category name
  count = 0, // integer, number of documents in the category
  animation = null, // string, optional animation to use, accepts 'slide' and 'tag'.
  index = null, // integer, index of category in list
  selected = false, // boolean, whether a category has been selected
  pinned = false, // boolean, whether a category has been pinned
  type = '', // string, 'categories' or 'investor-names'
  handleCategorySelect = null, // function, triggers on category click
  handleCategoryPinned = null, // function, triggers on pin being enabled
  handleCategoryUnpinned = null // function, triggers on pin being disabled
}) => {
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const categoryName = useRef();

  useEffect(() => {
    // responsively show tooltips for overflowing category names
    isTextOverflowing(categoryName.current)
      ? setTooltipVisible(true)
      : setTooltipVisible(false);
  }, [categoryName]);

  const isTextOverflowing = (categoryName) =>
    categoryName.offsetWidth < categoryName.scrollWidth;

  count > 99 ? (count = '+99') : count;
  return (
    <div
      className={`category-sidebar__category animate-${animation} ${
        selected ? 'selected' : ''
      }`}
      data-testid={`category-${id || name}`}
      onClick={() => handleCategorySelect(id || name)}
    >
      {
        <CategoryPin
          categoryId={id}
          pinned={pinned}
          type={type}
          handleCategoryPinned={handleCategoryPinned}
          handleCategoryUnpinned={handleCategoryUnpinned}
        />
      }
      {icon && (
        <FontAwesomeIcon
          icon={icon}
          className="category-sidebar__category-icon"
        />
      )}
      <span
        className="category-sidebar__category-name"
        data-testid={
          index == null
            ? `category-sidebar-name-${name}`
            : `category-sidebar-name-${index}`
        }
        ref={categoryName}
        title={tooltipVisible ? name : undefined}
      >
        {name}
      </span>
      <span
        className="category-sidebar__category-count"
        data-testid={
          index == null
            ? `category-sidebar-count-${name}`
            : `category-sidebar-count-${index}`
        }
      >
        {count}
      </span>
    </div>
  );
};

// used by Uncategorized
const UncategorizedFilter = ({
  icon = null, // imported FontAwesome icon, optionally displays next to uncategorized filter name
  name = '', // string, uncategorized filter name
  count = 0, // integer, number of documents in the uncategorized filter
  animation = null, // string, optional animation to use, accepts 'slide' and 'tag'.
  selected = false, // boolean, whether an uncategorized filter has been selected
  handleUncategorizedFilter = null // function, triggers on uncategorized filter click
}) => {
  count > 99 ? (count = '+99') : count;
  return (
    <div
      className={`category-sidebar__category animate-${animation} ${
        selected ? 'selected' : ''
      }`}
      data-testid={`uncategorized-filter-${name}`}
      onClick={() => handleUncategorizedFilter()}
    >
      {icon && (
        <FontAwesomeIcon
          icon={icon}
          className="category-sidebar__category-icon"
        />
      )}
      <span
        className="category-sidebar__category-name"
        data-testid={`uncategorized-filter-sidebar-name-${name}`}
      >
        {name}
      </span>
      <span
        className="category-sidebar__category-count"
        data-testid={`uncategorized-filter-sidebar-count-${name}`}
      >
        {count}
      </span>
    </div>
  );
};

const CategorySidebar = ({
  categoryInvNames = [], // array of objects {id, name}
  categories = [], // array of objects {id, name}
  categorySortMethod = 'name', // string, 'name' or 'recently_active',
  handleChangeCategorySortMethod = null, // callback, fires when sort method toggled
  handleCategorySelect = null, // callback, fires when a category is clicked
  myUploadsSelected, // boolean whether My Uploads is selected
  allDocsSelected, // boolean, whether All Documents is selected
  sidebarFilteredDocsCount = null, // callback to get count
  handleUncategorizedFilter = null, // boolean
  handleClearCategorySelections = null, // callback, fires when 'Clear' is clicked
  handleClearFiltersAndSelection = null, // callback, fires when 'All Documents' is clicked
  handleMyUploadsFilter = null, // callback, fires when 'My Uploads' is clicked
  uncategorizedSelected = false,
  handleCategoryPinned = null, // function, triggers on pin being enabled
  handleCategoryUnpinned = null, // function, triggers on pin being disabled
  canUpload = false //boolean, whether user can upload docs or not
}) => {
  const [searchText, setSearchText] = useState('');

  const populateCategories = (categorySet, plural, id) => {
    const sortedAlphabetically = (categorySet) =>
      categorySet.sort((a, b) =>
        a.name.localeCompare(b.name, 'en', { ignorePunctuation: true })
      );

    const filteredBySearchText = (categorySet) => {
      const filtered = categorySet.filter((category) =>
        category.name.toLowerCase().includes(searchText.toLowerCase())
      );

      return id == 'categories' && categorySortMethod == 'recently_active'
        ? sortedByRecentlyActive(filtered)
        : sortedAlphabetically(filtered);
    };

    const sortedByRecentlyActive = (categorySet) =>
      categorySet.sort(
        (a, b) =>
          Date.parse(b.recently_active_at) - Date.parse(a.recently_active_at)
      );

    const splitIntoPinnedAndUnpinned = (categorySet) =>
      // from: https://stackoverflow.com/a/47225591
      categorySet.reduce(
        ([pinned, unpinned], category) => {
          return category.pinned
            ? [[...pinned, category], unpinned]
            : [pinned, [...unpinned, category]];
        },
        [[], []]
      );

    const sortedByPin = (categorySet) => {
      // split into pinned and unpinned
      const [pinned, unpinned] = splitIntoPinnedAndUnpinned(categorySet);

      // sort pinned categories by pinned_at
      const sortedPinned = pinned.sort(
        (a, b) => Date.parse(a.pinned_at) - Date.parse(b.pinned_at)
      );

      // sort unpinned categories by current sort method
      const sortedUnpinned =
        id == 'categories' && categorySortMethod == 'recently_active'
          ? sortedByRecentlyActive(unpinned)
          : sortedAlphabetically(unpinned);

      return sortedPinned.concat(sortedUnpinned);
    };

    // pins don't get prioritized when searchText is present
    const categoriesToDisplay = searchText
      ? filteredBySearchText(categorySet)
      : sortedByPin(categorySet);

    return categoriesToDisplay.length > 0 ? (
      categoriesToDisplay.map((catData, i) => (
        <Category
          id={catData.id}
          name={catData.name}
          count={catData.cat_docs_count}
          animation="tag"
          key={`${catData.name}-${id}-${i}`}
          index={i}
          selected={catData.selected}
          pinned={catData.pinned}
          type={id}
          handleCategorySelect={handleCategorySelect}
          handleCategoryPinned={handleCategoryPinned}
          handleCategoryUnpinned={handleCategoryUnpinned}
        />
      ))
    ) : (
      <span className="category-sidebar__no-category-name">
        {searchText ? 'No Matching Categories' : `No Available ${plural}`}
      </span>
    );
  };

  // Lists are memoized so they aren't re-rendered when unrelated state changes
  const investorNameCategory = useMemo(
    () =>
      populateCategories(categoryInvNames, 'Investor Names', 'investor-names'),
    [categoryInvNames, searchText]
  );

  const categoriesList = useMemo(
    () => populateCategories(categories, 'Categories', 'categories'),
    [categories, searchText]
  );

  const handleSearchTextEntered = (text) => {
    setSearchText(text);
  };

  // Toggle whether viewing recent or all categories
  const handleViewClick = () => {
    handleChangeCategorySortMethod();
  };

  const infoTipMessage =
    'Categories aid in document organization similar to tags. Click on a Category filter to see related documents distributed by your portal admin.';

  const anyCategoriesSelected = () =>
    categories.some((cat) => cat.selected) ||
    categoryInvNames.some((cat) => cat.selected);

  return (
    <div className="category-sidebar">
      <section className="category-sidebar__featured">
        <h3 className="category-sidebar__header">
          Categories
          <InfoTip
            message={infoTipMessage}
            position="bottom"
            arrowPosition="right"
            infoTipName="categories-sidebar"
          />
        </h3>
        <div className="category-sidebar__search-field">
          <FontAwesomeIcon
            icon={faMagnifyingGlass}
            className="category-sidebar__search-icon"
          />
          <input
            placeholder="Search categories"
            className="category-sidebar__search-text-input"
            data-testid="category-sidebar__search-text-input"
            onChange={(e) => handleSearchTextEntered(e.target.value)}
            value={searchText}
          />
          {searchText && (
            <span
              onClick={() => setSearchText('')}
              className="category-sidebar__search-clear-btn"
              data-testid="category-sidebar__search-clear-btn"
            >
              <FontAwesomeIcon
                icon={faXmark}
                className="category-sidebar__search-clear-icon"
              />
            </span>
          )}
        </div>
        <ParentDocumentList
          name="All Documents"
          count={sidebarFilteredDocsCount('all')}
          selected={allDocsSelected}
          handleDocListClick={handleClearFiltersAndSelection}
          icon={faInboxIn}
          animation="slide"
        />
        <hr className="category-sidebar__featured-category-underline" />
        {canUpload && (
          <ParentDocumentList
            name="My Uploads"
            count={sidebarFilteredDocsCount('uploads')}
            selected={myUploadsSelected}
            handleDocListClick={handleMyUploadsFilter}
            icon={faInboxOut}
            animation="slide"
          />
        )}
        <hr className="category-sidebar__featured-category-underline" />
      </section>
      <section className="category-sidebar__general">
        {anyCategoriesSelected() && (
          <>
            <div
              className="category-sidebar__section-header search-results short-top-margin"
              data-testid="category-sidebar__search-results-header"
            >
              <h5>Category Selection</h5>
              <u
                className="action-link"
                onClick={() => handleClearCategorySelections()}
              >
                Clear
              </u>
            </div>
            <hr className="category-sidebar__general-category-underline" />
          </>
        )}
        {searchText ? (
          <div
            className="category-sidebar__section-header search-results short-top-margin"
            data-testid="category-sidebar__search-results-header"
          >
            <h5>Search Results</h5>
            <u className="action-link" onClick={() => setSearchText('')}>
              Clear
            </u>
          </div>
        ) : (
          <UncategorizedFilter
            name="Uncategorized"
            count={sidebarFilteredDocsCount('uncategorized')}
            animation="tag"
            selected={uncategorizedSelected}
            handleUncategorizedFilter={handleUncategorizedFilter}
          />
        )}
        <hr className="category-sidebar__general-category-underline" />
        <div
          className={`category-sidebar__section-header ${
            searchText ? '' : 'short-top-margin'
          }`}
          data-testid="category-sidebar-by-investor-name"
        >
          <h5>By Investor Name</h5>
        </div>
        <div className="category-sidebar__categories category-sidebar__investor-name">
          {investorNameCategory}
        </div>
        <div className="category-sidebar__section-header">
          <h5>
            {categorySortMethod == 'recently_active'
              ? 'Recently Active'
              : 'All Categories'}
          </h5>
          <u className="action-link" onClick={() => handleViewClick()}>
            {categorySortMethod == 'recently_active'
              ? 'View All'
              : 'View Recent'}
          </u>
        </div>
        <div className="category-sidebar__categories">{categoriesList}</div>
        <div className="category-sidebar__recently-active"></div>
      </section>
    </div>
  );
};

export default CategorySidebar;
