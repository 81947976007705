import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSort,
  faCircleSortDown,
  faCircleSortUp
} from '@fortawesome/pro-light-svg-icons';

const SortableHeaderContents = ({
  name,
  label,
  currentSortColumn,
  sortDirection,
  sortCallback
}) => {
  const isActiveSortColumn = currentSortColumn == name;

  const getNewSortDirection = () => {
    if (isActiveSortColumn) {
      // toggle if currently active
      return sortDirection == 'desc' ? 'asc' : 'desc';
    } else {
      // default to desc if becoming active
      return 'desc';
    }
  };

  const handleClick = () => {
    sortCallback({ sort: name, direction: getNewSortDirection() });
  };

  const getIcon = () => {
    if (isActiveSortColumn) {
      return sortDirection == 'desc' ? faCircleSortDown : faCircleSortUp;
    } else {
      return faSort;
    }
  };

  return (
    <span className="sortable-header-contents" onClick={() => handleClick()}>
      {label}
      <FontAwesomeIcon
        icon={getIcon()}
        className={`${
          isActiveSortColumn
            ? 'sortable-header-contents__icon-selected '
            : 'sortable-header-contents__icon'
        }`}
      />
    </span>
  );
};

const Table = ({
  data,
  columns,
  keyFn,
  message,
  rowClickCallback,
  sortColumn,
  sortDirection,
  sortCallback
}) => {
  const renderedHeaders = columns.map((column, index) => {
    let classes = [];

    if (column.grow) {
      classes.push(column.grow);
    }
    if (column.alignItems) {
      classes.push(column.alignItems);
    }

    const combinedClasses = classes.join(' ');

    return (
      <div
        className={combinedClasses}
        key={column.label}
        data-testid={`table__header-row-${index}`}
      >
        {column.sortable ? (
          <SortableHeaderContents
            name={column.name}
            label={column.label}
            currentSortColumn={sortColumn}
            sortDirection={sortDirection}
            sortCallback={sortCallback}
          />
        ) : (
          column.label
        )}
      </div>
    );
  });
  const renderedRows = data.map((rowData, idx) => {
    const renderedCells = columns.map((column) => {
      let classes = [];
      if (column.wasViewed && !column.wasViewed(rowData)) {
        classes.push('table__bold-text');
      }
      if (column.alignItems) {
        classes.push(column.alignItems);
      }
      if (column.grow) {
        classes.push(column.grow);
      }

      const combinedClasses = classes.join(' ');

      return (
        <div
          className={combinedClasses}
          key={column.label}
          data-testid={`column-row-${idx}`}
        >
          {column.render(rowData)}
        </div>
      );
    });

    return (
      <div
        className="table__row hoverstate"
        key={keyFn(rowData)}
        data-testid={`table__row-${idx}`}
        onClick={() => rowClickCallback(rowData)}
      >
        {renderedCells}
      </div>
    );
  });

  const noData = () => {
    return (
      <div className="table__row" data-testid="no-data-msg">
        {message}
      </div>
    );
  };

  return (
    <div className="table" role="table">
      <div className="table__row table__row-header">{renderedHeaders}</div>
      <div className="table__rows">
        {data.length == 0 ? noData() : renderedRows}
      </div>
    </div>
  );
};
export default Table;
