import React from 'react';
import ClassUtils from '@es6/class_utils.es6';

export default class ClearableField extends React.Component {
  constructor(props) {
    super(props);
    ClassUtils.autobind(this);
    this.timer;
    this.state = { value: this.props.value != null ? this.props.value : '' };
  }

  componentDidUpdate(prevProps, _prevState) {
    if (prevProps.value != this.props.value) {
      this.setState({ value: this.props.value });
    }
  }

  render() {
    return (
      <div className={`cmp-clearable-field ${this.props.className}`}>
        <input
          type="text"
          placeholder={this.props.label}
          value={this.state.value}
          onChange={this.handleChange}
        />
        <i
          className={`old-icon icon-delete-16 dark clear-field${
            this.state.value != '' ? '' : ' hide'
          }`}
          title={`Clear ${this.props.label}`}
          onClick={this.clearClick}
        ></i>
        <i
          className={`input-icon ${this.props.iconClass} ${
            this.state.value == '' ? '' : 'hide'
          }`}
        />
      </div>
    );
  }

  handleChange(event) {
    let val = event.target.value != null ? event.target.value : '';
    this.setState({ value: val });
    this.notify(val);
  }

  clearClick() {
    this.setState({ value: '' });
    this.notify('');
  }

  notify(newValue) {
    if (this.props.onChange && this.props.debounce) {
      // Debouncer
      clearTimeout(this.timer);
      this.timer = setTimeout(() => this.props.onChange(newValue), 250);
      return this.timer;
    } else {
      return this.props.onChange(newValue);
    }
  }
}
