import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFile,
  faUser,
  faBell,
  faChartMixed,
  faBars
} from '@fortawesome/pro-light-svg-icons';
import SiteNotificationsTray from './site_notifications_tray.es6';
import WorkspacesNavControl from '../workspaces_nav_control.es6';

const EndUserNavTab = ({ url, active, icon, text }) => {
  return (
    <a
      href={url}
      className={`nav-tab${active ? ' nav-tab--active' : ''}`}
      data-testid={`${text}-tab${active ? '-active' : ''}`}
    >
      <FontAwesomeIcon
        icon={icon}
        className="nav-tab__icon"
        data-testid={`${text}-tab-icon`}
      />
      {text}
    </a>
  );
};

const EndUserNavbar = ({
  portalIndex, // string, URL for global portal index
  answersEnabled, // boolean, controls whether answers/analytics tab is displayed
  currentPath, // string, current URI of page, used to determine "active" tab
  previewByAdmin, // boolean, whether UI is being previewed by admin
  userId, // integer, ID of user or user being previewed by admin
  userName, // string, name of current user or user being previewed by admin
  userEmail, // string, email of current user or user being previewed by admin
  organizationId, // integer, for use by Rollbar
  authToken, // string, csrf token to include on api calls
  notifications, // array of objects with date, subject, body, and collapsible props
  unviewedNotificationsCount, // integer
  userWorkspaces, //array of objects with tenant + workspace data
  currentWorkspaceName, //string
  currentTenant, //tenant object
  endUserNoSidebar, // boolean used for styling in old UI for end user
  workspacesDropdownEnabled // boolean, whether workspaces-dropdown is enabled in LaunchDarkly
}) => {
  const [notificationsTrayOpen, setNotificationsTrayOpen] = useState(false);
  const [unviewedNotifications, setUnviewedNotifications] = useState(
    unviewedNotificationsCount
  );
  const [openBurgerMenu, setOpenBurgerMenu] = useState(false);

  // Replicate the effect of the delete-method link the old UI made with UJS
  const signOut = async (event) => {
    // don't actually follow link to dummy href
    event.preventDefault();

    // send DELETE request to Rails logout endpoint (with token) to destroy user session
    await fetch('/logout', {
      method: 'DELETE',
      headers: { 'X-CSRF-Token': authToken }
    });

    // refresh, letting Rails handle redirect to correct login
    window.location.reload();
  };

  // Followed existing behavior. See: app/views_new/shared/_share_error_details_js.erb
  const shareErrorDetails = (event) => {
    // don't actually follow link to dummy href
    event.preventDefault();

    // Checks if rollbar.js library was loaded by middleware in config/initializers/rollbar.rb
    if (typeof Rollbar !== 'undefined') {
      // construct reference ID from props
      const refId = `${userId}-${organizationId}`;

      // log info (defaults to debug severity). See https://docs.rollbar.com/docs/javascript
      Rollbar.log('User Info Submission for ' + refId, {
        htmlBody: document.body.innerHTML
      });

      alert(
        'You have successfully submitted your browser information to the ShareSecure team. The browser info has been logged and is typically only used if the ShareSecure team has requested it from you. Please contact us for more information at support@altvia.com'
      );
    } else {
      alert(
        'Rollbar is not configured for this environment so no browser information has been submitted.'
      );
    }
  };

  // both a click handler for widget and a callback for when its close icon is clicked.
  const handleNotificationsTrayClick = async () => {
    // If user has unviewed notifications and tray is currently closed, mark as viewed
    if (unviewedNotifications > 0 && notificationsTrayOpen == false) {
      // Controller that handles this: app/controllers/org/site_notifications_controller.rb
      await fetch('/site_notifications/mark_viewed.js', {
        method: 'PUT',
        headers: { 'X-CSRF-Token': authToken }
      });

      // Setting notifications to zero without actually checking because notifications are infrequent.
      // In the unlikely scenario that a notification arrived in the interim, a page refresh would show it.
      // TODO: refactor this to dynamically fetch new notifications if/when it becomes part of roadmap.
      setUnviewedNotifications(0);
    }

    setNotificationsTrayOpen(!notificationsTrayOpen); // toggle open status
  };

  const tabs = [
    {
      url: '/',
      alternateUrl: '/documents',
      icon: faFile,
      text: 'Documents'
    },
    {
      url: '/dashboards',
      icon: faChartMixed,
      text: 'Analytics'
    }
  ];

  return (
    <>
      <div className="navbar__group navbar__group--left">
        {tabs.map((tab) => {
          if (tab.text == 'Analytics' && !answersEnabled) return;

          // make list of all possible routes that match a tab
          let possiblePaths = [tab.url];
          tab.alternateUrl && possiblePaths.push(tab.alternateUrl); // add alternate if exists
          possiblePaths = possiblePaths.concat(
            possiblePaths.map((url) => url + `?preview=true&user_id=${userId}`)
          ); // add preview variations

          // construct intended URL for tab to navigate to
          const intendedPath = previewByAdmin
            ? tab.url + `?preview=true&user_id=${userId}`
            : tab.url;

          return (
            <EndUserNavTab
              url={intendedPath}
              icon={tab.icon}
              text={tab.text}
              active={possiblePaths.includes(currentPath)}
              key={tab.text + '-tab'}
            />
          );
        })}
      </div>
      <div className="navbar__group navbar__group--right">
        {workspacesDropdownEnabled && (
          <WorkspacesNavControl
            previewByAdmin={previewByAdmin}
            userWorkspaces={userWorkspaces}
            currentTenant={currentTenant}
            currentWorkspaceName={currentWorkspaceName}
            endUserNoSidebar={endUserNoSidebar}
          />
        )}
        <div
          className="navbar__widget navbar__dropdown-icon"
          role="button"
          tabIndex="0"
          data-testid="navbar__dropdown-btn-primary"
        >
          <FontAwesomeIcon
            icon={faUser}
            className="navbar__icon"
            data-testid="navbar__dropdown-icon"
          />
          <span className="navbar__dropdown-text">
            <div
              className="navbar__dropdown--user-name"
              data-testid="navbar__dropdown--user-name"
            >
              {userName}
            </div>
            <div
              className="navbar__dropdown--user-email"
              data-testid="navbar__dropdown--user-email"
            >
              {userEmail}
            </div>
          </span>
          <div
            className="navbar__dropdown-menu"
            data-testid="navbar__dropdown-menu"
          >
            {/* TODO: extract as component and map over array of data objects like with tabs. Conditionally render new component with either full screen menu or burger menu */}
            <a
              className="navbar__dropdown-item"
              href={previewByAdmin ? '#' : portalIndex}
            >
              My Workspaces
            </a>
            <a
              className="navbar__dropdown-item"
              href={previewByAdmin ? '#' : '/user/account'}
            >
              My Account
            </a>
            <a
              className="navbar__dropdown-item"
              href={
                previewByAdmin
                  ? '#'
                  : 'https://altvia-learning.com/training-share-secure/'
              }
              target={previewByAdmin ? '_self' : '_blank'}
              rel="noreferrer"
            >
              Help
            </a>
            <a
              className="navbar__dropdown-item"
              href="#"
              onClick={previewByAdmin ? null : shareErrorDetails}
              role="button"
            >
              Share Error Details
            </a>
            <a
              className="navbar__dropdown-item"
              href="#"
              onClick={previewByAdmin ? null : signOut}
              role="button"
            >
              Sign Out
            </a>
          </div>
        </div>
        <div
          className="navbar__widget navbar__widget-notifications"
          role="button"
          tabIndex="0"
          data-testid="navbar__notifications-btn"
          onClick={previewByAdmin ? null : handleNotificationsTrayClick}
        >
          <span className="fa-layers fa-fw">
            <FontAwesomeIcon
              icon={faBell}
              className="navbar__icon"
              data-testid="navbar__notifications-icon"
            />
            {unviewedNotifications > 0 && (
              <span
                className="fa-layers-counter navbar__widget-counter"
                data-testid="navbar__notifications-counter"
              >
                {unviewedNotifications}
              </span>
            )}
          </span>
        </div>
        {/*  burger menu */}
        {/* TODO: extract as component and map over array of data objects like with tabs. Conditionally render new component with either full screen menu or burger menu */}
        <div
          className={`navbar__burger-menu navbar__dropdown-icon ${
            openBurgerMenu ? 'navbar__burger-menu-active' : ''
          }`}
          data-testid="navbar__dropdown-btn-burger"
        >
          <FontAwesomeIcon
            icon={faBars}
            size="lg"
            className="navbar__icon"
            data-testid="navbar__dropdown-burger-icon"
            onClick={() => setOpenBurgerMenu(!openBurgerMenu)}
          />
          {openBurgerMenu && (
            <div
              className="navbar__dropdown-menu"
              data-testid="navbar__dropdown-menu"
            >
              <div className="navbar__dropdown-item navbar__dropdown-item-read-only">
                <div
                  className="navbar__dropdown--user-name"
                  data-testid="navbar__burger-dropdown--user-name"
                >
                  {userName}
                </div>
                <div
                  className="navbar__dropdown--user-email"
                  data-testid="navbar__burger-dropdown--user-email"
                >
                  {userEmail}
                </div>
              </div>
              {answersEnabled && (
                <a
                  className="navbar__dropdown-item"
                  href={
                    previewByAdmin
                      ? `/dashboards?preview=true&user_id=${userId}`
                      : '/dashboards'
                  }
                >
                  Analytics
                </a>
              )}
              <a
                className="navbar__dropdown-item"
                href={
                  previewByAdmin
                    ? `/documents?preview=true&user_id=${userId}`
                    : '/documents'
                }
              >
                Documents
              </a>
              <a
                className="navbar__dropdown-item"
                href={previewByAdmin ? '#' : portalIndex}
              >
                My Workspaces
              </a>
              <a
                className="navbar__dropdown-item"
                href={previewByAdmin ? '#' : '/user/account'}
              >
                My Account
              </a>
              <a
                className="navbar__dropdown-item"
                href={
                  previewByAdmin
                    ? '#'
                    : 'https://altvia-learning.com/training-share-secure/'
                }
                target={previewByAdmin ? '_self' : '_blank'}
                rel="noreferrer"
              >
                Help
              </a>
              <a
                className="navbar__dropdown-item"
                href="#"
                onClick={previewByAdmin ? null : shareErrorDetails}
                role="button"
              >
                Share Error Details
              </a>
              <a
                className="navbar__dropdown-item"
                href="#"
                onClick={previewByAdmin ? null : signOut}
                role="button"
              >
                Sign Out
              </a>
            </div>
          )}
        </div>
      </div>
      <SiteNotificationsTray
        notifications={notifications}
        open={notificationsTrayOpen}
        closeCallback={handleNotificationsTrayClick}
      />
    </>
  );
};

export default EndUserNavbar;
