export default class AjaxUtils {

  static handleUnauthorized(httpStatus) {
    if (httpStatus == 401) {
      alert( 'You are no longer logged in, please log back in.' )
      window.location.href = '/login'
      return true
    }
    return false
  }
}
