import AjaxUtils from '../ajax_utils.es6';

export default class DocumentActions {
  static confirmAndPublish(docId, refreshCb) {
    if (confirm('Publish this document?')) {
      let ME = this;
      ssly.Modal.show({ message: 'Publishing...', spinner: true });
      $.ajax({
        url: DocumentActions.adminDocUrl(docId),
        data: { draft: false },
        dataType: 'json',
        method: 'PATCH',
        error: function (xhr, txtStatus, error) {
          if (!AjaxUtils.handleUnauthorized(xhr.status)) {
            const msg = (xhr.responseJSON && xhr.responseJSON.error) || error;
            alert('An error occurred while publishing: ' + msg);
          }
          ssly.Modal.hide();
          if (refreshCb) refreshCb();
        },
        success: function (resp) {
          ssly.Modal.hide();
          if (refreshCb) refreshCb();
        },
        timeout: 30000
      });
    }
  }

  static preparingDownload(
    download_format,
    filter_params,
    category_id,
    refreshCb
  ) {
    if (
      confirm(
        'Preparing this file may take a few minutes. Please remain on this page. The file will not be available if you navigate to another page. Do you wish to continue?'
      )
    ) {
      ssly.BackgroundRequest.send({
        path: DocumentActions.adminReportDocUrl(
          download_format,
          filter_params,
          category_id
        ),
        message: 'Preparing Document Data for Download',
        doneCb: refreshCb,
        method: 'GET'
      });
    }
  }

  static removeDocFromCategory(catId, docId, refreshCb) {
    if (
      confirm(
        'Are you sure you want to remove this document from this category?'
      )
    ) {
      let ME = this;
      ssly.Modal.show({ message: 'Removing...', spinner: true });
      $.ajax({
        url:
          DocumentActions.adminCategoryUrl(catId) +
          `/remove_associated?removal_ids=${docId}`,
        method: 'POST',
        dataType: 'script',
        error: function (xhr, txtStatus, error) {
          if (!AjaxUtils.handleUnauthorized(xhr.status)) {
            const responseText = xhr && xhr.responseText;
            let msg;
            try {
              msg = JSON.parse(responseText).error;
            } catch (e) {
              msg = error;
            }
            alert('An error occurred while removing document: ' + msg);
          }
          ssly.Modal.hide();
          if (refreshCb) refreshCb();
        },
        success: function (resp) {
          ssly.Modal.hide();
          if (refreshCb) refreshCb();
        },
        timeout: 10000
      });
    }
  }

  static confirmAndDelete(docId, refreshCb) {
    if (confirm('Are you sure you want to delete this document?')) {
      let ME = this;
      ssly.Modal.show({ message: 'Deleting...', spinner: true });
      $.ajax({
        url: DocumentActions.adminDocUrl(docId),
        method: 'DELETE',
        dataType: 'script',
        error: function (xhr, txtStatus, error) {
          if (!AjaxUtils.handleUnauthorized(xhr.status)) {
            const responseText = xhr && xhr.responseText;
            let msg;
            try {
              msg = JSON.parse(responseText).error;
            } catch (e) {
              msg = error;
            }
            alert('An error occurred while deleting: ' + msg);
          }
          ssly.Modal.hide();
          if (refreshCb) refreshCb();
        },
        success: function (resp) {
          ssly.Modal.hide();
          if (refreshCb) refreshCb();
        },
        timeout: 10000
      });
    }
  }

  static confirmAndUndelete(docId, refreshCb) {
    if (confirm('Are you sure you want to undelete this document?')) {
      let ME = this;
      ssly.Modal.show({ message: 'Undeleting...', spinner: true });
      $.ajax({
        url: `/admin/trash/${docId}`,
        method: 'PATCH',
        error: function (xhr, txtStatus, error) {
          if (!AjaxUtils.handleUnauthorized(xhr.status)) {
            alert('An error occurred while undeleting: ' + error);
          }
          ssly.Modal.hide();
          if (refreshCb) refreshCb();
        },
        success: function (resp) {
          ssly.Modal.hide();
          if (refreshCb) refreshCb();
        },
        timeout: 10000
      });
    }
  }

  static confirmSelectedDocsAction(
    selectInfo,
    filterUrlParams,
    actionDesc,
    suffix
  ) {
    if (selectInfo.selectCount == 0) {
      alert('You must select at least one document to take action on.'); // shouldnt happen but just in case
      return false;
    } else if (
      actionDesc !== 'download' &&
      suffix !== 'from this category' &&
      selectInfo.all &&
      selectInfo.selectCount > 1 &&
      filterUrlParams.length == 0
    ) {
      // to help prevent accidentally doing something to all docs in the org
      alert(
        'You must select at least one filter to reduce the number of selected documents to take action on.'
      );
      return false;
    }

    const noun = selectInfo.selectCount === 1 ? 'document' : 'documents';
    let question = `Are you sure you want to ${actionDesc}`;
    question += selectInfo.all ? ' all' : ' the';
    question += ` ${selectInfo.selectCount} selected ${noun}`;
    if (selectInfo.all && actionDesc !== 'download')
      question += ' matching these filters';
    if (suffix) question += ' ' + suffix;
    question += '?';

    return confirm(question);
  }

  static performSelectedDocsAction(
    actionUrl,
    selectInfo,
    filterUrlParams,
    progressMsg,
    refreshCb
  ) {
    const queryOp = actionUrl.includes('?') ? '&' : '?';
    if (selectInfo.all) {
      ssly.BackgroundRequest.send({
        path: `${actionUrl}${queryOp}all=true&${filterUrlParams}`,
        message: progressMsg,
        doneCb: refreshCb
      });
    } else {
      ssly.BackgroundRequest.send({
        path: `${actionUrl}${queryOp}id=${selectInfo.ids}${
          filterUrlParams.length > 0 ? '&' + filterUrlParams : ''
        }`,
        message: progressMsg,
        doneCb: refreshCb
      });
    }
  }

  static adminDocUrl(docId) {
    return `/admin/documents/${docId}`;
  }

  static adminCategoryUrl(catId) {
    return `/admin/categories/${catId}`;
  }

  static adminReportDocUrl(download_format, filterUrlParams, category_id) {
    if (category_id) {
      return `/admin/documents/documents_report_export?download_format=${download_format}&category_ids[]=${category_id}&${filterUrlParams}`;
    } else {
      return `/admin/documents/documents_report_export?download_format=${download_format}&${filterUrlParams}`;
    }
  }
}
