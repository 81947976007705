import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFileExcel,
  faFileWord,
  faFilePdf,
  faFileCsv,
  faFilePowerpoint,
  faFileZipper,
  faFileVideo,
  faFileAudio,
  faFileImage,
  faFile,
  faFileLines,
  faFileSpreadsheet
} from '@fortawesome/pro-light-svg-icons';

const DocIcon = ({ fileType }) => {
  const fileTypeToIconMap = {
    Audio: faFileAudio,
    CSV: faFileCsv,
    'Microsoft Word': faFileWord,
    DWG: faFileImage,
    'JPEG Image': faFileImage,
    PDF: faFilePdf,
    'PNG Image': faFileImage,
    'OpenDocument Presentation': faFilePowerpoint,
    'Rich Text': faFileLines,
    SVG: faFile,
    'OpenDocument Text': faFileLines,
    Text: faFileLines,
    'Microsoft Excel': faFileExcel,
    'OpenDocument Spreadsheet': faFileSpreadsheet,
    'Microsoft PowerPoint': faFilePowerpoint,
    'Zip Archive': faFileZipper,
    Video: faFileVideo
  };

  // Look up icon by file type key, use if found, otherwise use generic icon
  const iconToUse = fileTypeToIconMap[fileType]
    ? fileTypeToIconMap[fileType]
    : faFile;

  return (
    <span className="doc-icon__wrapper">
      <FontAwesomeIcon
        className="doc-icon"
        icon={iconToUse}
        data-testid={`doc-icon-${fileType}`}
      />
    </span>
  );
};

export default DocIcon;
