import React from 'react';
import ClassUtils from '@es6/class_utils.es6';

export default class ConfirmWithPassword extends React.Component {
  constructor(props) {
    super(props)
    this.state = {passwordPlaceholder: "Password"}
    ClassUtils.autobind(this)
  }

  confirm() {
    var context = this
    $.ajax({
      url: '/user/validate_password',
      data: {password: this.password.value},
      type: 'POST',
      dataType: "json",
      success: function(){
        context.password.value = null
        context.setState({passwordPlaceholder: ""})
        context.props.successCallback()
      },
      error: function(xhr, txtStatus, error) {
        context.password.value = null
        if(xhr.status == 405){
          context.setState({passwordPlaceholder: "Password Incorrect"})
        } else if (xhr.status == 401){
          context.setState({passwordPlaceholder: "Try again in 1 hour"})
        } else {
          context.setState({passwordPlaceholder: "Server Error"})
        }
      }
    })
  }

  cancel() {
    this.props.hideDialogCallback()
  }

  render() {
    if(!this.props.showConfirm) {
      return null;
    }
    return (
        <div className="cmp-confirm-with-password cmp-shared-component-modal-styles">
          <div className="backdrop">
            <div className="modal">
              <div className="warning">
                <b>WARNING</b>
              </div>
              <div className="confirm-details">
                <div className="close">
                  <a onClick={this.cancel}>
                    <img class="icon-circle-close" />
                  </a>
                </div>
                <div className="header">
                  {this.props.header}
                </div>
                <div className="message">
                  <div className="textblock" dangerouslySetInnerHTML={{__html: this.props.message}}>
                  </div>
                </div>
                <div className="actions">
                  <div className="go">
                    <input autoComplete='off' placeholder={this.state.passwordPlaceholder} ref={password => this.password = password} type='password' className='password'/>
                    <button className="danger" onClick={this.confirm}>
                      {this.props.buttonAction}
                    </button>
                  </div>
                  <div className="no">
                    <button className="neutral" onClick={this.cancel}>
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    );
  }
}

