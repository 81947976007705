import React from 'react';

const CategoryTag = ({ id, name, clickCb }) => (
  <div
    className="category-tag"
    data-testid={`category-tag-${id}`}
    onClick={clickCb}
  >
    {name}
  </div>
);

export default CategoryTag;
