export default class DocumentFilterTranslator {
  static translateToUrlParamsString(uiFilterState, uiUserViewId = null) {
    let filters =
      DocumentFilterTranslator.translateToUrlParamsJson(uiFilterState);

    let urlParameters = Object.keys(filters)
      .reduce((accum, name) => {
        let value = filters[name];
        if (value !== null && value !== '') {
          if (Array.isArray(value)) {
            value.forEach((v) => {
              accum.push(name + '[]=' + v);
            });
          }
          else if (filters.q) {
            accum.push(name + '=' + encodeURIComponent(value));
          } else {
            accum.push(name + '=' + value);
          }
        }
        return accum;
      }, [])
      .join('&');
    if (uiUserViewId) {
      urlParameters = urlParameters + '&user_id=' + uiUserViewId;
    }
    return urlParameters;
  }

  static translateToUrlParamsJson(uiFilterState) {
    let filters = {};

    if (uiFilterState.sf_batch_id) {
      filters.batch_id = uiFilterState.sf_batch_id;
    }

    let catIds = DocumentFilterTranslator._mergeCategoryIds(uiFilterState);
    if (catIds.length > 0) {
      filters.category_ids = catIds;
    }

    if (uiFilterState.textSearch) {
      filters.q = uiFilterState.textSearch;
    }

    if (uiFilterState.uploaded) {
      filters.uploaded = true;
    }

    if (uiFilterState.lastModified) {
      filters.last_modified = true;
    }

    if (uiFilterState.startDate != null) {
      filters.start_date = uiFilterState.startDate;
    }

    if (uiFilterState.endDate != null) {
      filters.end_date = uiFilterState.endDate;
    }

    if (uiFilterState.fileType != null) {
      filters.content_tab = uiFilterState.fileType;
    }

    if (uiFilterState.endUserFileType != null) {
      filters.content_tab = uiFilterState.endUserFileType;
      if (uiFilterState.endUserFileType == 'uploaded') {
        filters.uploaded = true;
      }
    }

    if (uiFilterState.endUserViewed == 'unviewed') {
      filters.viewed = false;
    }

    if (uiFilterState.contentType && uiFilterState.contentType.mime) {
      filters.content_type = uiFilterState.contentType.mime;
    }

    if (uiFilterState.draft != null) {
      filters.draft = uiFilterState.draft;
    }

    if (uiFilterState.uncategorized != null) {
      filters.uncategorized = uiFilterState.uncategorized;
    }

    return filters;
  }

  static _mergeCategoryIds(uiFilterState) {
    let catIds = [];
    if (uiFilterState.categories) {
      catIds = uiFilterState.categories.reduce((ids, cat) => {
        ids.push(cat.id);
        return ids;
      }, []);
    }

    if (uiFilterState.legalNames) {
      catIds = uiFilterState.legalNames.reduce((ids, legalName) => {
        ids.push(legalName.id);
        return ids;
      }, catIds);
    }

    if (uiFilterState.batchNames) {
      catIds = uiFilterState.batchNames.reduce((ids, batchName) => {
        ids.push(batchName.id);
        return ids;
      }, catIds);
    }

    return catIds;
  }
}
