import React, { useState } from "react";

const PersonalInformationForm = (props) => {
  const [email, setEmail] = useState(props.userEmail);
  const [name, setName] = useState(props.userName);
  const [correspondencePreferences, setCorrespondencePreferences] = useState(props.userCorrespondencePreferences);
  const [company, setCompany] = useState(props.userCompany);
  const [shareNotificationEmails, setShareNotificationEmails] = useState(props.userShareNotificationEmails);
  const [currentPassword, setCurrentPassword] = useState('');
  const [modalOpen, setModalOpen] = useState(false);

  const handleSubmit = () => {
    setModalOpen(false);
  }

  const portalListItems = props.verifyEmailChangeOrgs.map((orgName, index)=>{
    return <li key={index}>{orgName}</li>;
  });

  const submitButton = () => {
    if(!props.userRequiresEmailChangeApproval || email == props.userEmail) {
      return (
        <input type="submit" name="commit" value="Update" className="button submit" data-disable-with="Update"/>
      );
    } else {
      return (
        <div>
          <a className="button" onClick={() => setModalOpen(true)}>Update</a>
          {modalOpen &&
            <div className='personal-information-form-modal-background'>
              <div className='personal-information-form-modal'>
                <div className="row">Updating your email will require that you lose access to the following portal(s) until this change is approved by their respective Admins:</div>
                <div className="row"><ul>{portalListItems}</ul></div>
                <div className="row">Clicking "Confirm" below will trigger an email to the Admins of the above portal(s) for their approval.</div>
                <div className="row">Would you like to continue?</div>
                <div className="row">
                  <input type="submit" name="commit" value="Confirm" className="button submit" data-disable-with="Confirm"/>
                  <a className="button" onClick={() => setModalOpen(false)}>Cancel</a>
                </div>
              </div>
            </div>}
        </div>
      );
    }
  }

  // Most of this was adapted from the raw HTML generated by the old ERB code.
  // It could be cleaned up to follow best JSX/React practices.
  return (
    <div>
      <form
        className="user-info-form"
        action={props.formPath}
        acceptCharset="UTF-8"
        data-remote="true"
        method="post"
        onSubmit={() => handleSubmit()}
      >
        <input type="hidden" name="_method" value="patch" />
        <input
          type="hidden"
          name="authenticity_token"
          value={props.authToken}
        />
        <div className="row" id="user-info-errors"></div>
        {props.deviseMappingConfirmable && props.userPendingReconfirmation &&
          <div className="row">
            <div className="form-group">
              Currently waiting confirmation for: {props.userUnconfirmedEmail}
            </div>
          </div>}
        <div className="row">
          <div className="form-group">
            <p>
              <label htmlFor="user_name">Name</label>
            </p>
            <input
              type="text"
              name="user[name]"
              id="user_name"
              value={name}
              autoComplete="off"
              data-lpignore="true"
              onChange={(e) => setName(e.target.value)}
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group">
            <p>
              <label htmlFor="user_email">Email</label>
            </p>
            <input
              type="email"
              name="user[email]"
              id="user_email"
              value={email}
              autoComplete="off"
              data-lpignore="true"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group">
            <p>
              <label htmlFor="user_company">Company</label>
            </p>
            <input
              type="text"
              name="user[company]"
              id="user_company"
              value={company}
              autoComplete="off"
              data-lpignore="true"
              onChange={(e) => setCompany(e.target.value)}
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group">
            <input name="user[share_notification_emails]" type="hidden" value="0"/>
            <input
              className='personal-information-form-checkbox'
              type="checkbox"
              name="user[share_notification_emails]"
              id="user_share_notification_emails"
              value="1"
              checked={shareNotificationEmails}
              onChange={(e) => setShareNotificationEmails(e.target.checked)}
            />
            <label htmlFor="user_share_notification_emails">
              Email me when a document is shared with me
            </label>
            <div className="tooltip">
              <span>?</span>
              <div className="tooltip-message">
                Some organizations may choose to send you email notifications when
                they share documents with you. To opt out of these email
                notifications, leave this unchecked.
              </div>
            </div>
          </div>
        </div>

        {props.userCanEditCorrespondencePrefs &&
        <div className="row">
          <div className="form-group">
            <div className="reduce-margin-bottom">
              <label htmlFor="user_correspondence_preferences">
                Correspondence Preferences
              </label>
              <div className="tooltip">
                <span>?</span>
                <div className="tooltip-message">
                  Select your preferred method to receive correspondences. Your
                  organizations will be notified of your preference, but may choose
                  to communicate in other forms.
                </div>
              </div>
            </div>
            <br />
            <select
              className="pref-select"
              name="user[correspondence_preferences]"
              id="user_correspondence_preferences"
              value={correspondencePreferences}
              onChange={(e) => setCorrespondencePreferences(e.target.value)}
            >
              <option value='["email", "sharesecure"]'>Email and ShareSecure</option>
              <option value='["sharesecure"]'>ShareSecure Only</option>
              <option value='["email"]'>Email Only</option>
            </select>
          </div>
        </div>}
        <div className="row">
          <div className="form-group">
            <p>
              <label htmlFor="user_current_password">Current Password *</label>{" "}
              <i>
                <label> (Please enter your password to save changes)</label>
              </i>
              <br />
            </p>
            <input
              className="password-input"
              type="password"
              name="user[current_password]"
              id="user_current_password"
              autoComplete="off"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
            />
          </div>
          <div className="clear" />
        </div>
        {submitButton()}
      </form>
    </div>
  );
}

export default PersonalInformationForm;