import React, { useState } from 'react';
import ToggleSwitch from '@components_new/shared/end_user/toggle_switch.es6';

const WatermarkToggle = ({ isOn, patchEndpoint, authToken }) => {
  const [toggled, setToggled] = useState(isOn);

  const handleToggle = async () => {
    // the update endpoint expects query param key in this format
    const key = encodeURIComponent('documents[][enable_watermark]');
    const url = `${patchEndpoint}?${key}=${!toggled}`;

    await fetch(url, {
      method: 'PATCH',
      headers: {
        'X-CSRF-Token': authToken,
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
      },
      redirect: 'manual' // because update endpoint sends 302 back to doc show page
    });
    setToggled(!toggled); // update toggle state immediately instead of waiting for page reload
    window.location.reload();
  };
  return (
    <ToggleSwitch
      name="watermark-toggle"
      isOn={toggled}
      handleToggle={handleToggle}
    ></ToggleSwitch>
  );
};

export default WatermarkToggle;
