import React, { useCallback } from 'react';
import { debounce } from 'debounce';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faXmark } from '@fortawesome/pro-solid-svg-icons';

const TableSearch = ({ searchText, setSearchText, handleChangeSearchText }) => {
  // memoizes debounce function
  const debouncedCallback = useCallback(
    debounce(handleChangeSearchText, 250),
    []
  );

  const handleChange = (text) => {
    setSearchText(text);
    debouncedCallback(text);
  };

  return (
    <div className="table__search">
      <FontAwesomeIcon
        icon={faMagnifyingGlass}
        className="table__search-icon"
      />
      {/* Input field for tablet/large screens */}
      <input
        type="text"
        value={searchText}
        onChange={(e) => handleChange(e.target.value)}
        tabIndex="0"
        className="table__search-text-input"
        data-testid="table__search-text-input"
        placeholder="Search Documents"
      />
      {/* Input field for mobile screens */}
      <input
        type="text"
        value={searchText}
        onChange={(e) => handleChange(e.target.value)}
        tabIndex="0"
        className="table__search-text-input-mobile"
        placeholder="Search..."
      />
      {searchText && (
        <span
          onClick={() => handleChange('')}
          className="table__search-clear-btn"
          data-testid="table__search-clear-btn"
        >
          <FontAwesomeIcon
            icon={faXmark}
            className="table__search-clear-icon"
          />
        </span>
      )}
    </div>
  );
};

export default TableSearch;
