import React, { useMemo, useState } from 'react';
import {
  faBellOn,
  faEye,
  faEyeSlash,
  faCloudArrowDown,
  faCloudArrowUp,
  faFileSignature
} from '@fortawesome/pro-light-svg-icons';

import { getStreamUrlForDoc } from '@components_new/shared/utils/api_service.es6.jsx';
import { readableDateTime } from '../../shared/utils/formatting_utils.es6.jsx';
import BulkActionsControl from './bulk_actions_control.es6.jsx';
import CategorySidebar from '@components_new/shared/end_user/category_sidebar.es6.jsx';
import DateRangeControl from './date_range_control.es6.jsx';
import DocIcon from '@components_new/shared/utils/doc_icon.es6';
import FeaturedStatistic from '@components_new/shared/end_user/featured_statistic.es6.jsx';
import FileTypeControl from '@components_new/shared/end_user/file_type_control.es6.jsx';
import Footer from '@components_new/shared/end_user/footer.es6.jsx';
import Logo from '@components_new/shared/end_user/logo.es6.jsx';
import NewMediaPlayer from '@components_new/shared/end_user/new_media_player.es6.jsx';
import Modal from '@components_new/shared/end_user/modal.es6.jsx';
import PaginationControl from '@components_new/shared/end_user/pagination_control.es6.jsx';
import SelectAllCheckbox from '@components_new/shared/end_user/select_all_checkbox.es6';
import SelectionSummaryBar from '@components_new/shared/end_user/selection_summary_bar.es6.jsx';
import Table from '@components_new/shared/end_user/table.es6.jsx';
import TableCheckbox from '@components_new/shared/end_user/table_checkbox.es6.jsx';
import TableRowActions from '@components_new/shared/end_user/table_row_actions.es6.jsx';
import TableSearch from '@components_new/shared/end_user/table_search.es6.jsx';
import UploadDocsModal from '@components_new/shared/end_user/upload_docs_modal.es6.jsx';
import ViewedIcon from '@components_new/shared/end_user/viewed_icon.es6.jsx';
import CategoryTag from '../../shared/end_user/category_tag.es6.jsx';

const EndUserDocumentsIndexPage = ({
  documentData, // JSON containing documents, filters, categories, and other stats.
  legalDisclaimer, // string, org-specific text to pass to footer
  fetchUrl, // string, url to use for document fetch
  preview, // boolean, whether page is being previewed by admin
  allSharedDocumentIds, // array of integer IDS for all shared documents with default filters applied
  authToken, // string, CSRF prevention token for validating POST requests to BE
  formUrl, //string used in upload documents modal
  authenticatedRootUrl, // part of redirect path for 2FA modal,
  twoFactorStatus, // string, 2FA status
  uploadedCount, // integer, total count of docs uploaded by user
  sharedDocsTotalCount, // integer, total count of docs shared with user. Used for 'All Documents' even though it excludes user uploaded docs.
  uncategorizedDocsCount, //integer, number of uncategorized docs
  shown2faModalPath, // string, url to use to update shown_2fa_modal on user table
  shown2faModal, // boolean, whether user has been shown the sms 2FA modal. We only want to show it to each user once
  hasAgreement, // boolean, whether user has pending agreements (they need to be rendered before any other modals)
  logoPath, // string, URI of logo to display
  orgName, //string, Organization name
  canUpload = false //boolean, whether user can upload docs or not
}) => {
  // State management. Only storing top-level data in component state, because it changes as a whole.
  const [data, setData] = useState(documentData);
  const [viewedMediaFile, setViewedMediaFile] = useState(null);
  const [hasSeen2faModal, setHasSeen2faModal] = useState(shown2faModal);

  // Destructure data for use by component. Destructure further as needed.
  const {
    documents,
    filters,
    categories,
    category_inv_name,
    content_types,
    _total_returned,
    total_count,
    not_viewed_docs_count,
    last_thirty_days_docs_count,
    need_signature,
    uncategorized,
    ..._stats
  } = data;

  const fetchData = async (updatedFilters, clearCheckboxes) => {
    setModalState({
      visible: true,
      heading: 'Filtering...',
      spinner: true,
      customAlign: { heading: 'center' }
    });

    // Destructure updated filters object.
    const {
      per,
      page,
      sort,
      direction,
      view_as,
      viewed,
      user_id,
      content_type,
      category_ids,
      category_sort_method,
      start_date,
      last_thirty_days,
      uploaded,
      uncategorized,
      q
    } = updatedFilters;

    // Select sub-set of filters to include by default in query params.
    const params = {
      per,
      page,
      sort,
      direction,
      view_as,
      viewed,
      user_id,
      content_type,
      category_ids,
      category_sort_method,
      start_date,
      last_thirty_days,
      uploaded,
      uncategorized,
      q
    };

    // Conditionally add params necessary for request to be processed as admin.
    if (preview) {
      params.preview = true;
      params.user_id = user_id;
    }

    // Dynamically add query params to the URL.
    let urlWithParams = `${fetchUrl}?`;
    const paramsToAdd = [];
    Object.entries(params).forEach(([key, value]) => {
      if (!value) return; // don't add params with null values

      const encodedKey = encodeURIComponent(key);
      const encodedValue = encodeURIComponent(value);
      paramsToAdd.push(`${encodedKey}=${encodedValue}`);
    });
    urlWithParams = urlWithParams + paramsToAdd.join('&');

    const response = await fetch(urlWithParams);
    // TODO: add error handling
    const newDocumentData = await response.json();

    // Keep the old Category state if no documents were returned. This prevents the sidebar
    // from going blank if a user selects filters that don't match any of their documents.
    newDocumentData.documents.length > 0
      ? setData(newDocumentData)
      : setData({
          ...newDocumentData,
          categories,
          category_inv_name
        });

    // clear checkboxes unless rows per page increases, in which case we want checkbox state to persist
    clearCheckboxes
      ? resetCheckboxes(newDocumentData.documents)
      : persistCheckboxes(newDocumentData.documents);

    closeModal();
  };

  const applyNewFilters = async (newFilters, clearCheckboxes = true) => {
    // Not updating filters in state, because it would cause circular re-render.
    // Instead, create updated filter object, query with it, then update state with response.
    // This keeps the BE as the source of truth for default & active filters.
    const updatedFilters = {
      ...filters,
      ...newFilters
    };
    await fetchData(updatedFilters, clearCheckboxes);
  };

  // Measure scrollbar width in current browser (varies) so it can be passed in
  // to CSS as a custom variable and space can be made for it in scrolling divs.
  // From: https://stackoverflow.com/q/39392423
  // TODO: move this into a utility file, add variable to body element on startup
  const getScrollbarPixelWidth = () => {
    var outer = document.createElement('div');
    outer.style.visibility = 'hidden';
    outer.style.width = '100px';
    outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps

    document.body.appendChild(outer);

    var widthNoScroll = outer.offsetWidth;
    // force scrollbars
    outer.style.overflow = 'scroll';

    // add innerdiv
    var inner = document.createElement('div');
    inner.style.width = '100%';
    outer.appendChild(inner);

    var widthWithScroll = inner.offsetWidth;

    // remove divs
    outer.parentNode.removeChild(outer);

    const pixels = widthNoScroll - widthWithScroll;
    return `${pixels}px`;
  };

  // memoize value; only needs to be calculated once per browser
  const scrollbarPixelWidth = useMemo(() => getScrollbarPixelWidth());

  //map documents to checkboxes
  const docCheckboxes = documents.map((doc) => ({
    id: doc.id,
    isAudio: doc.is_audio,
    isVideo: doc.is_video,
    isReadOnly: doc.read_only,
    isChecked: false
  }));

  const [checkboxes, setCheckboxes] = useState(docCheckboxes);
  const [selectAllCheckbox, setSelectAllCheckbox] = useState(false);

  const selectedDocuments = () => {
    const selected = [];
    checkboxes.forEach((checkbox) => {
      if (checkbox.isChecked) {
        selected.push({
          id: checkbox.id,
          isAudio: checkbox.isAudio,
          isVideo: checkbox.isVideo,
          isReadOnly: checkbox.isReadOnly
        });
      } else {
        return;
      }
    });
    return selected;
  };

  const findCheckboxState = (id) =>
    checkboxes.find((checkbox) => checkbox.id === id)?.isChecked;

  const persistCheckboxes = (docs) => {
    if (docs.length > 0) {
      const updatedCheckboxes = docs.map((doc) => ({
        id: doc.id,
        isAudio: doc.is_audio,
        isVideo: doc.is_video,
        isReadOnly: doc.read_only,
        isChecked: findCheckboxState(doc.id) || false
      }));

      const allSelected = updatedCheckboxes.every(
        (checkbox) => checkbox.isChecked === true
      );

      setCheckboxes(updatedCheckboxes);
      setSelectAllCheckbox(allSelected);
    }
  };

  // reset existing checkboxes "isChecked" to false
  const resetCheckboxes = (docs) => {
    if (docs.length > 0) {
      const updatedCheckboxes = docs.map((doc) => ({
        id: doc.id,
        isAudio: doc.is_audio,
        isVideo: doc.is_video,
        isReadOnly: doc.read_only,
        isChecked: false
      }));
      setCheckboxes(updatedCheckboxes);
    }
    setSelectAllCheckbox(false);
  };

  const handleSelectCheckbox = (id, event) => {
    const { checked } = event.target;

    const updatedCheckboxes = checkboxes.map((checkbox) => {
      if (checkbox.id === id) {
        return {
          ...checkbox,
          isChecked: checked
        };
      } else {
        return checkbox;
      }
    });

    setCheckboxes(updatedCheckboxes);
    // Pass on the updatedCheckboxes as parameter as the below function is not getting updated state
    updateSelectAllCheckbox(updatedCheckboxes);
  };

  const handleSelectAllCheckbox = (event) => {
    const { checked } = event.target;

    setSelectAllCheckbox(checked);
    const updateAllSelected = checkboxes.map((checkbox) => {
      checkbox.isChecked = checked;
      return checkbox;
    });
    setCheckboxes(updateAllSelected);
  };

  const updateSelectAllCheckbox = (updatedCheckboxes) => {
    const allSelected = updatedCheckboxes.every(
      (checkbox) => checkbox.isChecked === true
    );
    if (selectAllCheckbox === false && allSelected) {
      setSelectAllCheckbox(true);
    } else if (!allSelected) {
      setSelectAllCheckbox(false);
    }
  };

  // pass unique key to documents table
  const keyFn = (doc) => {
    return doc.id;
  };

  const noDocumentsMsg = 'No matching documents';

  // array of objects representing column configuration.
  // label: text to be displayed in header
  // name: snakecased string used for sorting and BE references to column (req'd if sortable, otherwise optional)
  // grow: class to be used for flexbox styling
  // alignItems: class to be used for internal cell alignment (optional)
  // render: text or JSX element to be inserted in table cells
  // sortable: boolean representing whether the table can be sorted by column (optional, defaults to false)
  const columns = [
    {
      label:
        documents.length == 0 ? null : (
          <SelectAllCheckbox
            value={selectAllCheckbox.toString()}
            checked={selectAllCheckbox}
            handleChange={handleSelectAllCheckbox}
          />
        ),
      grow: 'table__cell--fixed-10',
      render: (doc) => (
        <TableCheckbox
          id={doc.id}
          key={doc.id}
          data-testid={doc.id}
          value={findCheckboxState(doc.id)}
          handleChange={(e) => handleSelectCheckbox(doc.id, e)}
        />
      )
    },
    {
      label: 'Document',
      name: 'name',
      sortable: true,
      grow: 'table__cell--grow',
      wasViewed: (doc) => doc.viewed,
      render: (doc) => doc.name
    },
    {
      label: 'Type',
      name: 'file_type',
      sortable: true,
      grow: 'table__cell--fixed-60',
      alignItems: 'table__cell--center',
      render: (doc) => <DocIcon fileType={doc.file_type} />
    },
    {
      label: 'Categories',
      name: 'cat_names',
      grow: 'table__cell--grow',
      render: (doc) => doc.cat_names
    },
    {
      label: 'Document Date',
      name: 'date',
      sortable: true,
      grow: 'table__cell--fixed-date-wide',
      render: (doc) => readableDateTime(doc.date)
    },
    {
      label: 'Shared On',
      name: 'shared_on',
      sortable: !filters.uploaded,
      grow: 'table__cell--fixed-date-wide',
      render: (doc) =>
        doc.shared_date
          ? readableDateTime(doc.shared_date?.split('T')[0])
          : null
    },
    {
      label: 'Viewed',
      name: 'viewed',
      sortable: true,
      grow: 'table__cell--fixed-72',
      alignItems: 'table__cell--center',
      render: (doc) => <ViewedIcon viewed={doc.viewed} docId={doc.id} />
    },
    {
      label: 'Actions',
      grow: 'table__cell--fixed-60',
      alignItems: 'table__cell--right',
      render: (doc) => (
        <TableRowActions rowId={doc.id} actions={populateActions(doc)} />
      )
    }
  ];

  // columns for small screen table
  const partialTableColumns = [...columns];
  partialTableColumns.splice(2, 5);

  const downloadDoc = (doc) => {
    handleActionClick(doc, `/documents/${doc.id}/download`);
  };

  const populateActions = (doc) => {
    const view = {
      icon: faEye,
      text: 'View',
      callback: () => handleActionClick(doc, `/documents/${doc.id}/view`)
    };
    const download = {
      icon: faCloudArrowDown,
      text: doc.read_only ? 'Download (Read Only)' : 'Download',
      callback: doc.read_only ? null : () => downloadDoc(doc),
      isDisabled: doc.read_only
    };
    const sign = {
      icon: faFileSignature,
      text: 'Sign',
      callback: () =>
        handleActionClick(doc, `/document_signings/${doc.pending_sig_id}/sign`)
    };

    let docActions = [view];
    doc.is_audio || doc.is_video || docActions.push(download);
    doc.pending_sig_id && docActions.push(sign);

    return docActions;
  };

  const handleActionClick = async (doc, url) => {
    // If media, warn and return early if file hasn't been encoded.
    if ((doc.is_audio || doc.is_video) && !doc.playable) {
      setModalState({
        visible: true,
        closeable: true,
        heading: 'Media file not ready',
        children: (
          <span className="doc_index__modal-body">
            This file is still being encoded for playback. Please refresh and
            try again in a few minutes.
          </span>
        ),
        closeCallback: closeModal
      });

      return;
    }

    if (!preview && !doc.viewed) {
      const markAsViewedUrl = `/documents/${doc.id}/mark_as_viewed`;
      await fetch(markAsViewedUrl);

      applyNewFilters();
    }

    // View with media player if media, otherwise open url.
    // (This assumes the only action performed on media files is viewing.)
    if (doc.is_audio || doc.is_video) {
      const streamUrl = await getStreamUrlForDoc(doc.id);

      setViewedMediaFile({
        // All playable audio or video files are encoded as mp4 or mp3
        type: doc.is_video ? 'video/mp4' : 'audio/mp3',
        src: streamUrl
      });
    } else {
      window.open(url, '_blank');
    }
  };

  const [searchText, setSearchText] = useState('');

  const resetSearch = () => {
    setSearchText('');
  };

  const handleChangeSearchText = (text) => {
    applyNewFilters({ page: 1, q: text });
  };

  const handleCloseMediaPlayer = () => {
    setViewedMediaFile(null);
  };

  const [notViewedSelected, setNotViewedSelected] = useState(false);

  const handleNotViewed = () => {
    let prev = notViewedSelected;

    if (notViewedSelected == false) {
      applyNewFilters({ page: 1, viewed: 'false', uploaded: null });
      setNotViewedSelected(!prev);
    } else {
      applyNewFilters({ page: 1, viewed: null });
      setNotViewedSelected(!prev);
    }
  };

  const documentSigningUrl = '/document_signings/awaiting';

  const handleNeedsYourAttention = async () => {
    //open in the same window
    window.open(documentSigningUrl, '_self');
  };

  const handleSharedIn30Days = () => {
    let isSelected = filters.last_thirty_days;

    isSelected
      ? applyNewFilters({ page: 1, last_thirty_days: false })
      : applyNewFilters({
          page: 1,
          last_thirty_days: true,
          uploaded: null,
          sort: filters.sort
        });
  };

  const featuredStatistics = [
    {
      icon: faEyeSlash,
      count: not_viewed_docs_count,
      text: 'Not Viewed',
      linkText: 'View now',
      link: '#',
      previewByAdmin: preview == null ? false : preview,
      color: 'red',
      selected: notViewedSelected,
      notSelectableByAdmin: false,
      callback: handleNotViewed
    },
    {
      icon: faBellOn,
      count: need_signature,
      text: 'Needs Your Attention',
      linkText: 'View now',
      link: documentSigningUrl,
      previewByAdmin: preview == null ? false : preview,
      color: 'orange',
      selected: false,
      notSelectableByAdmin: true,
      callback: handleNeedsYourAttention
    },
    {
      icon: faCloudArrowUp,
      count: last_thirty_days_docs_count,
      text: 'Shared in the last 30 days',
      linkText: 'View now',
      link: '#',
      previewByAdmin: preview == null ? false : preview,
      color: 'blue',
      selected: filters.last_thirty_days,
      notSelectableByAdmin: false,
      callback: handleSharedIn30Days
    }
  ];

  const filteredSubsetOrSelectionPresent = () =>
    filters.q ||
    filters.content_type ||
    filters.start_date ||
    filters.last_thirty_days ||
    filters.viewed ||
    filters.category_ids?.length > 0 ||
    filters.uploaded ||
    filters.uncategorized ||
    selectedDocuments().length > 0;

  const handleClearFiltersAndSelection = () => {
    if (!filteredSubsetOrSelectionPresent()) return;

    resetSearch();
    resetCheckboxes(documents);
    setNotViewedSelected(false);
    applyNewFilters({
      page: 1,
      q: '',
      content_type: null,
      start_date: null,
      last_thirty_days: false,
      viewed: null,
      category_ids: [],
      uploaded: null,
      uncategorized: false
    });
  };

  const handleClearCategorySelections = () => {
    applyNewFilters({
      category_ids: []
    });
  };

  const initialModalState = {
    visible: false,
    spinner: false,
    heading: null,
    message: null,
    closeable: false
  };

  const [modalState, setModalState] = useState(initialModalState);

  const closeModal = () => {
    setModalState(initialModalState);
  };

  const twoFactorModalHeading = 'Help Protect Your SHARESECURE Account';

  const twoFactorModalBody = (
    <div className="two-factor-modal_body">
      <p>
        Please add another method of two-factor authentication. We&apos;ll ask
        for a verification code if we notice a browser we don&apos;t recognize
        as well as periodically for safety.
      </p>
      <p className="two-factor-modal_instructions">
        Click Add 2FA Device below to add a device to your account.
      </p>
    </div>
  );

  const update2faShown = async () => {
    await fetch(shown2faModalPath, {
      method: 'PUT',
      headers: { 'X-CSRF-Token': authToken }
    });
    setHasSeen2faModal(true);
  };

  const redirectTo2FaOnSubmit = () => {
    update2faShown();
    window.location.href = `${authenticatedRootUrl}two_factor/config`;
  };

  const sms2faModalState = {
    visible: true,
    heading: twoFactorModalHeading,
    children: twoFactorModalBody,
    footerButtons: [
      { text: 'Cancel', type: 'tertiary', callback: update2faShown },
      {
        text: 'Add 2FA Device',
        type: 'primary',
        callback: redirectTo2FaOnSubmit
      }
    ],
    customAlign: { footer: 'center' },
    closeable: true,
    closeCallback: update2faShown
  };

  const generateCategoryTags = (categories) => (
    <span className="doc_index__category-tags">
      {categories.map((cat) => (
        <CategoryTag
          key={cat.id}
          id={cat.id}
          name={cat.name}
          clickCb={() =>
            applyNewFilters({
              page: 1,
              category_ids: [cat.id]
            })
          }
        />
      ))}
    </span>
  );

  const handleRowClick = (rowData) => {
    const docData = [
      { name: 'Date', value: readableDateTime(rowData.date) },
      { name: 'File Name', value: rowData.file_name },
      { name: 'Size', value: rowData.file_size },
      { name: 'Type', value: rowData.file_type },
      { name: 'Description', value: rowData.description },
      { name: 'Categories', value: generateCategoryTags(rowData.categories) }
    ];

    const docModalBody = (
      <div className="doc-index__doc-modal--body">
        {docData.map((field, i) => (
          <div key={i} className="modal-body-field">
            <b className="modal-body-field--name">{field.name}</b>
            <span className="modal-body-field--value">{field.value}</span>
          </div>
        ))}
      </div>
    );

    const docModalButtons = populateActions(rowData).map((action) => ({
      ...action,
      type: 'primary'
    }));

    setModalState({
      visible: true,
      heading: rowData.name || rowData.file_name,
      children: docModalBody,
      footerButtons: docModalButtons,
      customAlign: { footer: 'center' },
      closeable: true,
      closeCallback: closeModal
    });
  };

  // returns count for uncategorized, all documents and My uploads
  const sidebarFilteredDocsCount = (name) => {
    const countByList = {
      uncategorized: uncategorizedDocsCount,
      uploads: uploadedCount,
      all: sharedDocsTotalCount
    };

    return countByList[name];
  };

  const handleUncategorizedFilter = () => {
    applyNewFilters({
      page: 1,
      uncategorized: !uncategorized,
      category_ids: []
    });
  };

  const handleMyUploadsFilter = () => {
    resetSearch();
    resetCheckboxes(documents);
    setNotViewedSelected(false);
    applyNewFilters({
      page: 1,
      q: '',
      content_type: null,
      start_date: null,
      viewed: null,
      category_ids: [],
      uploaded: 'end_user',
      uncategorized: false,
      sort: filters.sort == 'shared_on' ? 'date' : filters.sort
    });
  };

  const handleChangeCategorySortMethod = () => {
    // toggle from previous
    const new_method =
      filters.category_sort_method == 'name' ? 'recently_active' : 'name';

    applyNewFilters({ category_sort_method: new_method });
  };

  const handleCategorySelect = (categoryId) => {
    // remove category id from filters if present, add to filters if not
    const currentFilteredCategoryIds = filters.category_ids || [];
    const categoryFilterPresent =
      currentFilteredCategoryIds.includes(categoryId);
    const categoriesToFilterBy = categoryFilterPresent
      ? currentFilteredCategoryIds.filter((id) => id !== categoryId)
      : [...currentFilteredCategoryIds, categoryId];

    applyNewFilters({
      page: 1,
      category_ids: categoriesToFilterBy
    });
  };

  const handleCategoryPinned = async (categoryId, type) => {
    if (preview) return;

    const pinCategoryUrl = `/categories/${categoryId}/pin_category`;
    fetch(pinCategoryUrl); // allowed to happen asynchronously

    // change directly in local state to save data re-fetch
    updateCategoryState(categoryId, type, true);
  };

  const handleCategoryUnpinned = async (categoryId, type) => {
    if (preview) return;

    const unpinCategoryUrl = `/categories/${categoryId}/unpin_category`;
    fetch(unpinCategoryUrl); // allowed to happen asynchronously

    // change directly in local state to save data re-fetch
    updateCategoryState(categoryId, type, false);
  };

  const getTomorrowISO = () => {
    let tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    return tomorrow.toISOString();
  };

  const updateCategoryState = (categoryId, type, newPinnedStatus) => {
    const categoriesCopy =
      type == 'categories' ? [...categories] : [...category_inv_name];
    const pinnedIndex = categoriesCopy.findIndex((c) => c.id == categoryId);
    categoriesCopy[pinnedIndex].pinned = newPinnedStatus;
    categoriesCopy[pinnedIndex].pinned_at = newPinnedStatus
      ? getTomorrowISO() // in order to negate time zone difference from MST
      : null;
    if (type == 'categories') {
      setData({ ...data, categories: categoriesCopy });
    } else {
      setData({ ...data, category_inv_name: categoriesCopy });
    }
  };

  const featuredStatistic = featuredStatistics.map((feature) => {
    return feature.notSelectableByAdmin == true && feature.count == 0 ? null : (
      <FeaturedStatistic
        key={feature.color}
        icon={feature.icon}
        count={feature.count}
        text={feature.text}
        linkText={feature.linkText}
        link={feature.link}
        previewByAdmin={feature.previewByAdmin}
        color={feature.color}
        selected={feature.selected}
        notSelectableByAdmin={feature.notSelectableByAdmin}
        callback={feature.callback}
      />
    );
  });

  //pass dropup options to rows per page
  const dropupOptions = ['25', '50', '100'];

  const [showUploadDocModal, setShowUploadDocModal] = useState(false);

  const allDocsSelected = filters.uploaded !== 'end_user';

  // Note: styling assumes the contents will have a parent <main> element.
  // Because of the restrictions of using react-on-rails, we can't add it here,
  // but instead supply it when using react_component helper method.
  // See: https://www.shakacode.com/react-on-rails/docs/api/view-helpers-api/#react_component
  // The following is wrapped in JSX fragments (<>) so the <main> will be parent.
  // See: https://reactjs.org/blog/2017/11/28/react-v16.2.0-fragment-support.html
  return (
    <>
      {viewedMediaFile && (
        <NewMediaPlayer
          src={viewedMediaFile.src}
          type={viewedMediaFile.type}
          closeCallback={handleCloseMediaPlayer}
        />
      )}
      {!hasAgreement &&
        !preview &&
        twoFactorStatus === 'Not activated' &&
        !hasSeen2faModal && <Modal {...sms2faModalState} />}
      <Modal {...modalState} />
      <aside
        className={`sidebar  sidebar--${
          preview ? 'admin-preview' : 'end-user-view'
        }`}
        style={{ '--scrollbar-width': scrollbarPixelWidth }}
      >
        <Logo logoPath={logoPath}></Logo>
        <CategorySidebar
          preview={preview}
          categories={categories}
          categoryInvNames={category_inv_name}
          categorySortMethod={filters.category_sort_method}
          handleChangeCategorySortMethod={handleChangeCategorySortMethod}
          myUploadsSelected={!allDocsSelected}
          canUpload={canUpload}
          allDocsSelected={allDocsSelected}
          sidebarFilteredDocsCount={sidebarFilteredDocsCount}
          handleUncategorizedFilter={handleUncategorizedFilter}
          handleClearCategorySelections={handleClearCategorySelections}
          handleClearFiltersAndSelection={handleClearFiltersAndSelection}
          handleMyUploadsFilter={handleMyUploadsFilter}
          uncategorizedSelected={uncategorized}
          handleCategorySelect={handleCategorySelect}
          handleCategoryPinned={handleCategoryPinned}
          handleCategoryUnpinned={handleCategoryUnpinned}
        />
      </aside>

      <div
        className="content"
        style={{ '--scrollbar-width': scrollbarPixelWidth }}
      >
        <article className="view">
          <div className="doc-index__row doc-index__header-row">
            <h4
              data-testid="doc-index__orgName"
              className={
                orgName.length <= 20
                  ? `doc-index__header--regular`
                  : `doc-index__header--responsive`
              }
            >
              {orgName.length > 40 ? orgName.substr(0, 38) + '...' : orgName} -
              Document Manager
            </h4>
            {canUpload && (
              <button
                className="doc-index__upload-doc-button"
                data-testid="doc-index__upload-doc-button"
                onClick={() =>
                  preview
                    ? setShowUploadDocModal(false)
                    : setShowUploadDocModal(true)
                }
              >
                Upload Document
              </button>
            )}
            {showUploadDocModal && (
              <UploadDocsModal
                authToken={authToken}
                formUrl={formUrl}
                closeCallback={() => setShowUploadDocModal(false)}
              />
            )}
          </div>
          <div className="doc-index__row doc-index__featured-statistic-row">
            {featuredStatistic}
          </div>
          <div className="doc-index__row doc-index__table-row">
            <div className="doc-index__table-main">
              <div className="doc-index__table-controls-row">
                <div className="doc-index__table-control">
                  <BulkActionsControl
                    selectedDocs={selectedDocuments()}
                    allSharedIds={allSharedDocumentIds}
                    userId={filters.user_id}
                    preview={preview}
                    uploaded={filters.uploaded}
                    authToken={authToken}
                    setModalState={setModalState}
                    closeModal={closeModal}
                  />
                </div>
                <div className="doc-index__table-control doc-index__table-control-search">
                  <TableSearch
                    searchText={searchText}
                    setSearchText={setSearchText}
                    handleChangeSearchText={handleChangeSearchText}
                  />
                </div>
                <div className="doc-index__table-control doc-index__table-control-hide">
                  <FileTypeControl
                    fileTypes={content_types}
                    selectedType={filters.content_type}
                    applyNewFilters={applyNewFilters}
                  />
                </div>
                <div className="doc-index__table-control doc-index__table-control-hide">
                  <DateRangeControl
                    startDate={filters.start_date}
                    applyNewFilters={applyNewFilters}
                  />
                </div>
              </div>
              <div className="doc-index__row doc-index__table-row">
                <div className="doc-index__table-stats-row">
                  <div className="doc-index__table-stats">
                    <SelectionSummaryBar
                      selectedCount={selectedDocuments().length}
                      totalCount={total_count}
                      filteredSubsetOrSelectionPresent={filteredSubsetOrSelectionPresent()}
                      handleClearFiltersAndSelection={
                        handleClearFiltersAndSelection
                      }
                    />
                  </div>
                </div>
              </div>
              {/* large screen table */}
              <div
                className="doc-index__table-full"
                data-testid="doc-index__table-full"
              >
                <Table
                  data={documents}
                  columns={columns}
                  keyFn={keyFn}
                  rowClickCallback={handleRowClick}
                  sortColumn={filters.sort}
                  sortDirection={filters.direction}
                  sortCallback={applyNewFilters}
                  message={noDocumentsMsg}
                />
              </div>
              {/* small screen table */}
              <div
                className="doc-index__table-partial"
                data-testid="doc-index__table-partial"
              >
                <Table
                  data={documents}
                  columns={partialTableColumns}
                  keyFn={keyFn}
                  rowClickCallback={handleRowClick}
                  sortColumn={filters.sort}
                  sortDirection={filters.direction}
                  sortCallback={applyNewFilters}
                  message={noDocumentsMsg}
                />
              </div>
            </div>
            <div className="doc-index__pagination-controls">
              <div className="doc-index__pagination-title">Rows per page:</div>
              <PaginationControl
                itemsPerPage={filters.per}
                dropupOptions={dropupOptions}
                applyRecordsPerPage={applyNewFilters}
                page={filters.page}
                totalCount={total_count}
                applyPage={applyNewFilters}
              />
            </div>
          </div>
        </article>
        <Footer legalDisclaimer={legalDisclaimer} />
      </div>
    </>
  );
};

export default EndUserDocumentsIndexPage;
