import React, { useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAnglesLeft,
  faAngleLeft,
  faAnglesRight,
  faAngleRight,
  faEllipsis
} from '@fortawesome/pro-solid-svg-icons';
import RowsPerPageControl from './rows_per_page_control.es6';

//custom hook to calculate pagination range
const usePagination = ({ totalPageCount, siblingCount = 1, currentPage }) => {
  const range = (start, end) => {
    let length = end - start + 1;
    return Array.from({ length }, (_, idx) => idx + start);
  };

  const paginationRange = useMemo(() => {
    const totalPageNumbers = siblingCount + 5;

    if (totalPageNumbers >= totalPageCount) {
      return range(1, totalPageCount);
    }

    const leftSiblingIdx = Math.max(currentPage - siblingCount, 1);
    const rightSiblingIdx = Math.min(
      currentPage + siblingCount,
      totalPageCount
    );

    //do not show dots if there is one page
    const showLeftDots = leftSiblingIdx > 2;
    const showRightDots = rightSiblingIdx < totalPageCount - 2;

    const firstPageIdx = 1;
    const lastPageIdx = totalPageCount;
    const dots = 'dots';

    //no left dots
    if (!showLeftDots && showRightDots) {
      let leftPageCount = 3 + 2 * siblingCount;
      let leftRange = range(1, leftPageCount);

      return [...leftRange, dots, totalPageCount];
    }

    //no right dots
    if (showLeftDots && !showRightDots) {
      let rightPageCount = 3 + 2 * siblingCount;
      let rightRange = range(
        totalPageCount - rightPageCount + 1,
        totalPageCount
      );
      return [firstPageIdx, dots, ...rightRange];
    }

    //both left and right dots
    if (showLeftDots && showRightDots) {
      let middleRange = range(leftSiblingIdx, rightSiblingIdx);
      return [firstPageIdx, dots, ...middleRange, dots, lastPageIdx];
    }
  }, [totalPageCount, siblingCount, currentPage]);
  return paginationRange;
};

const PaginationPageNumber = ({ page, currentPage, callbackPageNoSelect }) => {
  const pageNo = Number(page);

  const handlePageNoClick = () => {
    callbackPageNoSelect(pageNo);
  };

  return (
    <div
      className={
        currentPage == page
          ? 'pagination-control__page-number-container pagination-control__page-number-container--selected'
          : 'pagination-control__page-number-container'
      }
      onClick={handlePageNoClick}
      data-testid={`pagination-control-page-no-${pageNo}`}
    >
      {pageNo}
    </div>
  );
};

const PaginationPageNumbers = ({
  page,
  pagesNo,
  siblingCount = 1,
  callbackPageNoSelect
}) => {
  const pageNumber = Number(page);
  const totalPageCount = Number(pagesNo);
  let currentPage = pageNumber;
  const paginationRange = usePagination({
    totalPageCount,
    currentPage,
    siblingCount,
    pageNumber
  });

  if (pageNumber == 0) {
    return null;
  }

  return paginationRange?.map((pageNum, idx) => {
    return pageNum.toString() == 'dots' ? (
      <div
        className="pagination-control__ellipsis-container"
        key={`pagination-control__ellipsis-cont-${idx}`}
      >
        <FontAwesomeIcon
          icon={faEllipsis}
          className="pagination-control__ellipsis"
          key={`pagination-control__ellipsis-${idx}`}
          data-testid={`pagination-control__ellipsis`}
        />
      </div>
    ) : (
      <PaginationPageNumber
        page={pageNum}
        currentPage={currentPage}
        key={`pagination-control-page-no-${idx}`}
        callbackPageNoSelect={callbackPageNoSelect}
      />
    );
  });
};

const PaginationPrevious = ({ page, callbackPageSelect }) => {
  const currentPage = Number(page);
  const noPreviousPage = () => {
    return currentPage == 1;
  };

  const previousArrows = [
    {
      iconAction: 'firstPage',
      icon: faAnglesLeft
    },
    {
      iconAction: 'previousPage',
      icon: faAngleLeft
    }
  ];

  const handlePreviousPageClick = () => {
    let newPage = currentPage - 1;
    callbackPageSelect(newPage);
  };

  const handleFirstPageClick = () => {
    const newPage = 1;
    callbackPageSelect(newPage);
  };
  return previousArrows.map((arrow) => {
    return (
      <div
        className={`${
          noPreviousPage()
            ? 'pagination-control__icon-container pagination-control__icon-container--disabled'
            : 'pagination-control__icon-container'
        }`}
        onClick={
          arrow.iconAction == 'firstPage'
            ? handleFirstPageClick
            : handlePreviousPageClick
        }
        key={arrow.iconAction}
        data-testid={`pagination-previous-${arrow.iconAction}`}
      >
        <FontAwesomeIcon
          icon={arrow.icon}
          className={`${
            noPreviousPage() ? 'pagination-control__icon--disabled' : null
          }`}
        />
      </div>
    );
  });
};

const PaginationNext = ({ page, pagesNo, callbackPageSelect }) => {
  const currentPage = Number(page);
  const pagesCount = Number(pagesNo);
  const isLastPage = () => {
    return currentPage == pagesCount;
  };
  const nextArrows = [
    {
      iconAction: 'nextPage',
      icon: faAngleRight
    },
    {
      iconAction: 'lastPage',
      icon: faAnglesRight
    }
  ];

  const handleNextPageClick = () => {
    const newPage = currentPage + 1;
    callbackPageSelect(newPage);
  };

  const handleLastPageClick = () => {
    const newPage = pagesCount;
    callbackPageSelect(newPage);
  };
  return nextArrows.map((arrow) => {
    return (
      <div
        className={`${
          isLastPage() || pagesNo == 0
            ? 'pagination-control__icon-container pagination-control__icon-container--disabled'
            : 'pagination-control__icon-container'
        }`}
        onClick={
          arrow.iconAction == 'lastPage'
            ? handleLastPageClick
            : handleNextPageClick
        }
        key={arrow.iconAction}
        data-testid={`pagination-next-${arrow.iconAction}`}
      >
        <FontAwesomeIcon
          icon={arrow.icon}
          className={`${
            isLastPage() || pagesNo == 0
              ? 'pagination-control__icon--disabled'
              : null
          }`}
        />
      </div>
    );
  });
};

const PaginationControl = ({
  itemsPerPage,
  dropupOptions,
  applyRecordsPerPage,
  page,
  totalCount,
  applyPage
}) => {
  const pagesNo = Math.ceil(totalCount / itemsPerPage);

  const startItemsNo = () => {
    let start = 0;
    if (page == 1) {
      start = 1;
    } else {
      start = page * itemsPerPage - itemsPerPage + 1;
    }
    return start;
  };

  const endItemsNo = () => {
    let end = 0;
    if (page == pagesNo) {
      end = totalCount;
    } else {
      end = page * itemsPerPage;
    }
    return end;
  };

  const handlePageSelect = (newPage) => {
    if (newPage != page) {
      applyPage({ page: newPage });
    }
  };

  const handleRowsPerPageSelect = (newItemsPerPage) => {
    applyRecordsPerPage({ per: newItemsPerPage, page: 1 }, false);
  };

  return (
    <>
      <RowsPerPageControl
        perPage={itemsPerPage}
        dropupOptions={dropupOptions}
        selectCallback={handleRowsPerPageSelect}
      />
      <div
        className="pagination-control__pagination-values"
        data-testid="pagination-values"
      >
        {startItemsNo()}-{endItemsNo()} of {totalCount}
      </div>
      <div className="pagination-control__navigation-container">
        <PaginationPrevious page={page} callbackPageSelect={handlePageSelect} />
        <PaginationPageNumbers
          page={page}
          pagesNo={pagesNo}
          itemsPerPage={itemsPerPage}
          callbackPageNoSelect={handlePageSelect}
        />
        <PaginationNext
          page={page}
          pagesNo={pagesNo}
          callbackPageSelect={handlePageSelect}
        />
      </div>
    </>
  );
};
export default PaginationControl;
