import React from 'react';
import ClassUtils from '@es6/class_utils.es6';

export default class ConfirmNavigateAwayModal extends React.Component {
  constructor(props) {
    super(props)
    ClassUtils.autobind(this);
    if (!window.sslyEvents) window.sslyEvents = new EventEmitter()
    this.sslyEvents = window.sslyEvents
    this.state = { show: false, targetUrl: null }
  }

  componentDidMount() {
    this.sslyEvents.on( 'show-confirm-nav-away', this.showModal )
  }

  componentWillUnmount() {
    this.sslyEvents.removeAllListeners('show-confirm-nav-away')
  }

  showModal(e, targetUrl) {
    e.preventDefault()
    e.stopPropagation()
    this.setState({
      show: true,
      targetUrl: targetUrl
    })
  }

  leave(e) {
    e.preventDefault() // if this is inside form, stop form from submitting
    if(this.state.targetUrl) {
      window.location = this.state.targetUrl
    }
  }

  stay() {
    this.setState({show: false, targetUrl: null})
  }

  render() {
    if(!this.state.show) {
      return null;
    }
    return (
        <div className="cmp-confirm-navigate-away-modal cmp-shared-component-modal-styles">
          <div className="backdrop">
            <div className="modal">
              <div className="close">
                <a onClick={this.stay}>
                  <img class="icon-circle-close" />
                </a>
              </div>
              <div className="header">
                Are you sure you want to leave this page?
              </div>
              <div className="message">
                <div className="textblock">
                  {this.props.message}
                </div>
              </div>
              <div className="actions">
                <button className="danger" onClick={this.leave}>
                  LEAVE
                </button>
                <button className="neutral" onClick={this.stay}>
                  Stay
                </button>
              </div>
            </div>
          </div>
        </div>
    );
  }
}
