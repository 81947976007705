import React from 'react';
import ClassUtils from '@es6/class_utils.es6';

/*
   Example Use: <%= react_component( 'Dropdown', title: "MY TITLE",
         items: [ { label: 'Do Something', link: '/if ya wanna link' }, { label: 'Or Something else', cbData: someData } ],
         itemClickCb // used if you don't specify a link and passed cbData
    ) %>
 */
export default class Dropdown extends React.Component {
  constructor(props) {
    super(props)
    ClassUtils.autobind(this)
    this.state = { expanded: false }
  }

  render() {
    return (
      <div className="cmp-dropdown" onClick={this.menuClick} onMouseLeave={this.hideMe}>
        <a href="#" className="export-menu onClick={this.exportCsvClick}">
          <i className="icon xx-small download"></i><span>{this.props.title}</span><i className="icon x-small dropdown gray"></i>
        </a>
        {
          this.state.expanded && this.props.items && 
            <ul>
            {
              this.props.items.map( (item, index) => {
                if (item.link) {
                  return ( <li key={index}><a href={item.link}>{item.label}</a></li> )
                } else {
                  return ( <li key={index} onClick={ () => this.itemClickCb( item.cbData ) }>{item.label}</li> )
                }
              })
            }
            </ul>
        }
      </div>
    )
  }

  itemClickCb( cbData ) {
    this.props.itemClickCb( cbData )
    this.hideMe()
  }

  hideMe() {
    this.setState( (state) => {
      return { expanded: false }
    } )
  }

  menuClick() {
    this.setState( (state) => {
      return { expanded: !state.expanded }
    } )
  }
}
