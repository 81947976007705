import React from 'react';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const RowsPerPageTable = ({ selectionText, dropup }) => {
  return (
    <>
      <div className="rows-per-page-table" data-testid="rows-per-page-table">
        <div className="rows-per-page-table__selection-row">
          <div
            className="rows-per-page-table__selection-text"
            data-testid={`rows-per-page-table__selection-text-${selectionText}`}
          >
            {selectionText}
          </div>
          <FontAwesomeIcon
            icon={faCaretDown}
            className="rows-per-page-table__icon"
          />
        </div>
        <div
          className="rows-per-page-table__dropup"
          data-testid="rows-per-page-table__dropup"
        >
          {dropup}
        </div>
      </div>
    </>
  );
};

const RowsPerPageItem = ({ perPage, optionText, selectCallback }) => {
  const handleClick = () => {
    selectCallback(perPage);
  };
  return (
    <div
      className="rows-per-page-table__dropup-list-item"
      onClick={handleClick}
      data-testid={`rows-per-page-table__dropup-list-item-${optionText}`}
    >
      {optionText}
    </div>
  );
};

const RowsPerPageControl = ({ perPage, dropupOptions, selectCallback }) => {
  const dropupItems = () => {
    return dropupOptions.map((option) => {
      return (
        <RowsPerPageItem
          optionText={option}
          perPage={option}
          selectCallback={selectCallback}
          key={option}
        />
      );
    });
  };

  const dropup = () => {
    return (
      <div className="rows-per-page-table__dropup-list">{dropupItems()}</div>
    );
  };

  return <RowsPerPageTable selectionText={perPage} dropup={dropup()} />;
};

export default RowsPerPageControl;
