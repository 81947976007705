import React from 'react';
import CategoryFilter from './category_filter.es6';
import ClassUtils from '@es6/class_utils.es6';
import ClearableField from '../clearable_field.es6';

export default class CategoryFilters extends React.Component {
  constructor(props) {
    super(props);
    ClassUtils.autobind(this);
    this.state = {
      show: { category: true, legal: true, batch: true },
      searchText: '',
      categoryPage: 1,
      legalPage: 1,
      batchPage: 1,
    };
  }

  /* This is the 'new' category_fiters component, it's currently only used for rendering the ADMIN category filters. The END USER category filters are rendered in the components/shared/documents dir.
  The plan is for both end user & admin to eventually share/reuse the same component with an if-else conditional after the end user css is refreshed.

  NOTE: The END USER code is commented out and not deleted so we keep the older components & components_new code the same as much as possible, if not identical so it'll be easier to 'merge' these files at some point. 
  */

  render() {
    const {
      categoryClick,
      endUser,
      legalNameClick,
      batchNameClick,
      matchedCategories,
      selectedCategories,
      uncategorized,
      handleFilterByNoCatClick
    } = this.props;

    let cats = this.getOptions(
      'category',
      'matchedCategories',
      'selectedCategories',
      'categories',
      categoryClick
    );
    let legalNamesDesc = endUser ? 'investor names' : 'investor legal names';
    let legalNames = this.getOptions(
      'legal',
      'matchedLegalNames',
      'selectedLegalNames',
      legalNamesDesc,
      legalNameClick
    );
    let batchNames = this.getOptions(
      'batch',
      'matchedBatchNames',
      'selectedBatchNames',
      'batch names',
      batchNameClick
    );
    const showBorder =
      (selectedCategories.length || matchedCategories.length) &&
      this.state.show.category;

    /* **** END USER category filters ****
    if (endUser) {
      return (
        <div className="cmp-category-filters filter-groups category-holder">
          <div className="filter-group">
            <h3>By Investor Name: {this.getActions('legal')}</h3>
            <div className="filter-list">
              {legalNames}
            </div>
          </div>
          <div className="filter-group category-holder">
            <h3>By Category: {this.getActions('category')}</h3>
            <div className="uncategorized-filter">
            <CategoryFilter 
              category={{ 
                id:'no category', 
                name: 'Documents Without Category'
              }} 
              selected={uncategorized} 
              categoryClick={handleFilterByNoCatClick} 
            />
            </div>
           <div className={`${showBorder ? 'border-top' : ''}`}>
          </div>
            <div className="filter-list">
              {cats}
            </div>
          </div>
        </div>
      )
    }
    else { */
    return (
      <div className="cmp-category-filters">
        <ClearableField
          label={'Search Categories'}
          value={this.state.searchText}
          className={'category-search'}
          iconClass={'search-icon'}
          onChange={(newText) => this.setState({ searchText: newText })}
        />
        <div className="filter-group category-holder">
          <h3>Category {this.getActions('category')}</h3>
          <div className="uncategorized-filter">
            <CategoryFilter
              category={{
                id: 'no category',
                name: 'Documents Without Category'
              }}
              selected={uncategorized}
              categoryClick={handleFilterByNoCatClick}
            />
          </div>
          <div className={`${showBorder ? 'border-top' : ''}`}></div>
          <div onScroll={ (e) => { this.handleScroll('categoryPage', e) }} className="filter-list">{cats}</div>
        </div>
        <div className="filter-group category-holder">
          <h3>Investor Legal Name {this.getActions('legal')}</h3>
          <div onScroll={ (e) => { this.handleScroll('legalPage', e) }} className="filter-list">{legalNames}</div>
        </div>
        <div className="filter-group category-holder">
          <h3>Batch Name {this.getActions('batch')}</h3>
          <div onScroll={ (e) => { this.handleScroll('batchPage', e) }} className="filter-list">{batchNames}</div>
        </div>
      </div>
    );
  }

  getActions(catType) {
    return (
      <span>
        &nbsp;
        <span className={this.state.show[catType] ? '' : 'hide'}>
          <a className="link" data-cat={catType} onClick={this.hideClick}>
            Hide
          </a>{' '}
          |{' '}
        </span>
        <span className={!this.state.show[catType] ? '' : 'hide'}>
          <a className="link" data-cat={catType} onClick={this.showClick}>
            Show
          </a>{' '}
          |{' '}
        </span>
        <span>
          <a className="link" data-cat={catType} onClick={this.clearClick}>
            Clear
          </a>
        </span>
      </span>
    );
  }

  // Show matching categories/et al, and indicate if they are being used as filters by showing them as 'selected'
  // If no categories match the current search, show the ones currently filtering with instead
  getOptions(catType, matchedKey, selectedKey, desc, clickCb) {
    if (!this.state.show[catType]) return [];

    const allMatchingCategories = this.props[matchedKey];
    let matched = this.filterBySearchText(allMatchingCategories);

    let allSelectedCategories = this.props[selectedKey];
    let selected = this.filterBySearchText(allSelectedCategories);

    let options = [];
    if (matched && matched.length > 0) {
      let selectedIds = selected.reduce((selectedIds, cat) => {
        selectedIds[cat.id] = true;
        return selectedIds;
      }, {});

      options = matched.slice(0, 30 * this.state[`${catType}Page`]).map(function (c) {
        let selected = selectedIds[c.id] != null;
        return (
          <CategoryFilter
            key={c.id}
            category={c}
            selected={selected}
            categoryClick={clickCb}
          />
        );
      }, this);
    } else if (selected && selected.length > 0) {
      options = selected.map(function (c) {
        return (
          <CategoryFilter
            key={c.id}
            category={c}
            selected={true}
            categoryClick={clickCb}
          />
        );
      }, this);
    } else {
      const adjective =
        allSelectedCategories.length == 0 && allMatchingCategories.length == 0
          ? 'available'
          : 'matching';
      options = (
        <label className="lighter-smaller">{`No ${adjective} ${desc}`}</label>
      );
    }

    return options;
  }

  showClick(event) {
    let catType = event.target.getAttribute('data-cat');
    this.setState((state) => {
      let showState = ClassUtils.clone(this.state.show);
      showState[catType] = true;
      return { show: showState };
    });
  }

  hideClick(event) {
    let catType = event.target.getAttribute('data-cat');
    this.setState((state) => {
      let showState = ClassUtils.clone(this.state.show);
      showState[catType] = false;
      return { show: showState };
    });
  }

  clearClick(event) {
    let catType = event.target.getAttribute('data-cat');

    let parentCb;
    if (catType == 'category') parentCb = this.props.categoriesClearClick;
    if (catType == 'legal') parentCb = this.props.legalNamesClearClick;
    else if (catType == 'batch') parentCb = this.props.batchNamesClearClick;

    if (parentCb) parentCb();
  }

  filterBySearchText(categories) {
    if (this.state.searchText == '') return categories;

    return categories
      .filter((category) =>
        category.name
          .toLowerCase()
          .includes(this.state.searchText.toLowerCase())
      )
      .sort((x, y) => x.name.length - y.name.length);
  }

  handleScroll(pageType, e) {
    if (e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 20) {
      var newState = {}
      newState[pageType] = this.state[pageType] + 1
      this.setState(newState)
    }
  }
}

