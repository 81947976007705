import React, { useState, useEffect } from 'react';
import EditTable from './edit_table.es6';

const EditGroupOrderTable = ({ groups, authToken }) => {
  const groupsOrder = groups.map((g) => ({
    id: g.id,
    group_order: g.group_order || 0
  }));

  // isOpen state is needed to open the whole component
  const [isOpen, _setIsOpen] = useState(true);
  // groups state to display in the edit table
  const [groupOrderList, setGroupOrderList] = useState(groupsOrder);
  // groups state that were updated and will be submitted to DB
  const [updatedGroups, setUpdatedGroups] = useState([]);
  const [hasDuplicates, setHasDuplicates] = useState(false);
  const [error, setError] = useState(false);

  // create unique key
  const keyFn = (group) => {
    return group.id;
  };

  let optionNumbers = [];
  if (groups.length > 0) {
    optionNumbers = [...Array.from({ length: groups.length + 1 }, (_, i) => i)];
  }

  // create dropdown options
  let options = optionNumbers.map((option, i) => {
    return (
      <option
        key={i}
        value={option}
        label={option != 0 ? option.toString() : ''}
      ></option>
    );
  });

  const errorText = (text) => {
    return <div className="edit-group-order-table__error">{text}</div>;
  };

  const checkDuplicates = () => {
    let groupsRecords = [];
    groupsRecords = groupOrderList;

    let duplicateOrderValues = groupsRecords
      .map((g) => g['group_order'])
      .map((g, i, final) => final.indexOf(g) !== i && i)
      .filter((group) => groupsRecords[group])
      .map((g) => groupsRecords[g]['group_order']);

    // only 0 can be used more than once as value for the group order
    // it is translated to nil in backend
    let filteredDuplicateOrderValues = duplicateOrderValues.filter(
      (val) => val !== 0
    );

    if (filteredDuplicateOrderValues.length > 0) {
      setHasDuplicates(true);
    } else {
      setHasDuplicates(false);
    }
  };

  useEffect(() => {
    checkDuplicates();
  }, [groupOrderList]);

  const handleGroupOrderChange = (id, value) => {
    let updated = updatedGroups;
    if (updated.length > 0) {
      // check if the group's order was already updated
      const alreadyUpdatedGroup = updated.find((gr) => gr.id === id);
      if (alreadyUpdatedGroup) {
        /**
         * if the group was already updated,
         * update it again with the new value
         */
        let updateGroups = updated.map((g) => {
          if (g.id === alreadyUpdatedGroup.id) {
            return { ...g, group_order: value };
          } else {
            return g;
          }
        });
        setUpdatedGroups(updateGroups);
      } else {
        updated.push({ id: id, group_order: value });
        setUpdatedGroups(updated);
      }
    } else {
      updated.push({ id: id, group_order: value });
      setUpdatedGroups(updated);
    }
  };

  const handleGroupOrderSelection = (id, event) => {
    const value = event.target.value === '' ? 0 : parseInt(event.target.value);

    handleGroupOrderChange(id, value);
    const updatedGroupOrder = groupOrderList.map((group) => {
      if (group.id === id) {
        return {
          ...group,
          group_order: value
        };
      } else {
        return group;
      }
    });
    setGroupOrderList(updatedGroupOrder);
  };

  const currentValue = (group) => {
    let value = null;
    groupOrderList.map((g) => {
      if (g.id === group.id) {
        value = g.group_order;
      } else {
        value;
      }
    });
    return value;
  };

  const columns = [
    {
      label: 'Name',
      name: 'name',
      grow: 'edit-table__cell--grow--wrap',
      render: (group) => group.name
    },
    {
      label: 'Answers Group',
      name: 'sisense_group',
      grow: 'edit-table__cell--grow--wrap',
      render: (group) => (group.sisense_group ? 'Yes' : 'No')
    },
    {
      label: 'Order',
      name: 'group_order',
      grow: 'edit-table__cell--fixed-60',
      render: (group) => {
        return (
          <select
            name="selectionOptions"
            id="selectionOptions"
            onChange={(e) => handleGroupOrderSelection(group.id, e)}
            value={currentValue(group)}
          >
            {options}
          </select>
        );
      }
    }
  ];

  const handleSave = async () => {
    setError(false);
    if (updatedGroups.length > 0) {
      const allUpdatedGroups = {
        groups: updatedGroups
      };
      const url = '/admin/groups/set_group_order';

      await fetch(url, {
        method: 'post',
        headers: {
          'X-CSRF-Token': authToken,
          credentials: 'same-origin',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(allUpdatedGroups)
      }).then((response) => {
        if (response.ok) {
          // if response is ok then refresh page that will close the edit table
          location.reload();
        } else {
          setError(true);
        }
      });
    }
  };

  const handleCancel = () => {
    const initailGroupsOrder = groupsOrder;
    setGroupOrderList(initailGroupsOrder);
    const cancel = document.getElementById('edit-group-order-holder');
    cancel.style.display = 'none';
    setError(false);
  };

  const noGroupsMsg = 'No answers groups';
  return (
    <>
      {isOpen && (
        <>
          <EditTable
            data={groups}
            columns={columns}
            keyFn={keyFn}
            message={noGroupsMsg}
          />
          {hasDuplicates && errorText('Group order number must be unique.')}
          {error && errorText('Error updating groups order.')}
          <div className="edit-group-order-table__btns-wrapper">
            <div
              className={`${
                hasDuplicates || groups.length === 0
                  ? 'edit-group-order-table__action-button disabled colored'
                  : 'edit-group-order-table__action-button colored'
              }`}
              onClick={handleSave}
            >
              Save
            </div>

            <div
              className="edit-group-order-table__action-button not-colored"
              onClick={handleCancel}
            >
              Cancel
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default EditGroupOrderTable;
