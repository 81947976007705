import React from 'react';
import DocumentFilters from './document_filters.es6';
import PagingListWithActions from '../paging_list_with_actions';
import ClassUtils from '@es6/class_utils.es6';
import DocumentFilterTranslator from '@es6/documents/document_filter_translator.es6';

export default class FilteredDocumentList extends React.Component {
  constructor(props) {
    super(props)
    ClassUtils.autobind(this)

    if (history.state && history.state.filteredDocumentList) {
      const listState = history.state.filteredDocumentList
      this.initialFilters = listState.filters
      this.initialPage = listState.page

      const orderIdx = (listState.order && listState.order.length > 0) ? listState.order[0][0] : null
      const orderData = orderIdx ? this.props.columns[orderIdx].data : null
      const oldOrderData = listState.orderData
      if(!orderData || !oldOrderData || orderData === oldOrderData) this.initialOrder = listState.order
    }

    this.state = this.getDefaultState()
  }

  // Parent callable: Refetch the data in the doclist (e.g. after docs are modified externally)
  refreshList() {
    this.docList.refreshList()
  }

  // Parent callable: Extract current filter state for action usage
  getFilterUrlParams() {
    return DocumentFilterTranslator.translateToUrlParamsString( this.state.filters )
  }

  // Parent callable: Extract current select state for action usage
  getSelectInfo() {
    return this.docList.getSelectInfo()
  }

  getTotalCount() {
    return this.docList.getTotalCount()
  }

  static readableAdminDocName(name, type, doc) {
    const safeName = $.fn.dataTable.render.text().display(name)
    if (type == 'display') {
      return `
        <a href="/admin/documents/${doc.id}">
          ${(doc.draft ? `${safeName}&nbsp; <span className="draft-mark">[DRAFT]</span>` : safeName)}
        </a>
        ${(doc.bypass_watermarking ? 
          `<span class='tooltip protected-icon'>
            <i class='icon small round lock'></i>
            <div class="tooltip-message">
              This document could not be watermarked due to encryption, password protection, or some other restriction.
            </div>
          </span>` : '')}
      `
    } else {
      return safeName
    }
  }

  static readableUploadedBy(name, type, doc) {
    const safeName = doc.uploaded_by ? $.fn.dataTable.render.text().display(doc.uploaded_by) : ''
    if (type == 'display' && doc.uploaded_by && doc.created_by_existing_user) {
      return `<a href="${doc.uploaded_by_url}">${safeName}</a>`
    } else if (doc.uploaded_by) {
      return safeName
    } else {
      return ''
    }
  }

  render() {
    const initialOrder = this.initialOrder ? this.initialOrder : this.props.order

    let itemDesc = 'documents'
    if (this.props.endUser && this.state.filters.endUserFileType == 'media') itemDesc = 'media'
    if (!this.props.endUser && this.state.filters.fileType == 'media') itemDesc = 'media'

    let filtersPossible = this.props.filtersToInclude && this.props.filtersToInclude.length > 0

    return (
      <div className="cmp-filtered-document-list">
        { filtersPossible && this.props.includeFilterControls &&
          <a href="#" className="hide-filters-control" onClick={this.showHideFiltersClick}>{ `${this.state.showFilters ? 'Hide' : 'Show' }` } Filters</a>
        }
        <div className="row">
          { filtersPossible && this.state.showFilters &&
            <div className="col-3">
              <div className="clump left-col">
                { this.props.includeFilterControls &&
                  <div>
                    <a href="/admin/documents/new" className="button">New Document</a>
                    <div className="filter-controls">
                      <span id="clear_filter">
                        <a href="#" onClick={this.clearFiltersClick}>Reset Filters</a>
                      </span>
                      <span> | </span>
                      <a href="/admin/trash">Deleted Documents</a>
                    </div>
                  </div>
                }

                <DocumentFilters
                  filtersToInclude={this.props.filtersToInclude} filters={this.state.filters}
                  matchedContentTypes={this.state.matchedContentTypes} matchedCategories={this.state.matchedCategories}
                  matchedLegalNames={this.state.matchedLegalNames} matchedBatchNames={this.state.matchedBatchNames}
                  endUser={this.props.endUser} draftsAllowed={this.props.draftsAllowed} filterChangeCb={this.filterChangeCb}
                />
              </div>
            </div>
          }

          <div className={ `${filtersPossible && this.state.showFilters ? 'col-9' : 'col-12'}` }>
            <div className='clump table-by-filters'>
              <div className="clump-header">
                <h2>{ `${this.props.title ? this.props.title : 'Documents' }` }</h2>
              </div>
      
               <PagingListWithActions
                 ref={ (docList) => { this.docList = docList } }
                 columns={this.props.columns} order={initialOrder} itemsPerPage={this.props.itemsPerPage}
                 showSelects={this.props.showSelects}
                 includeTextSearch={this.props.includeTextSearch}
                 filters={this.state.filters}
                 filterTranslator={DocumentFilterTranslator}
                 filteringUrl={this.props.filteringUrl}
                 page={this.initialPage}
                 itemDesc={itemDesc}
                 respContainer='documents'
                 actionBarClass={this.props.actionBarClass}
                 textSearchChangeCb={this.textSearchChange}
                 searchCb={this.searchCb}
                 itemActionCb={this.props.itemActionCb}
                 itemClickCb={this.props.itemClickCb}
                 anytimeActions={this.props.anytimeActions}
                 selectedActions={this.props.selectedActions}
                 multiSelectedActions={this.props.multiSelectedActions}
                 anytimeActionsAfter={this.props.anytimeActionsAfter}
                 selectOnlyThisPage={this.props.selectOnlyThisPage}
               />
            </div>
          </div>
        </div>
      </div>
    )
  }

  getDefaultState() {
    let filters = this.initialFilters
    if (!filters) {
      filters = DocumentFilters.getDefaultFilters(this.props.endUser)
    }

    if (this.props.sf_batch_id) {
      filters.sf_batch_id = this.props.sf_batch_id
    }

    return {
      showFilters: true,
      filters: filters,
      matchedContentTypes: [], matchedCategories: [], matchedLegalNames: [], matchedBatchNames: []
    }
  }

  searchCb( searchResult, sortAndPagingInfo ) {
    this.setState( (state) => {
      if (sortAndPagingInfo) {
        const sortCol = sortAndPagingInfo.sortColumn
        const sortData = this.props.columns[sortCol].data
        this.updateBrowserHistory( 'order', [[ sortCol, sortAndPagingInfo.sortDir ]] )
        this.updateBrowserHistory( 'orderData', sortData )
        this.updateBrowserHistory( 'page', sortAndPagingInfo.page )
      }

      return {
        matchedContentTypes: searchResult.content_types,
        matchedCategories: searchResult.categories,
        matchedLegalNames: searchResult.category_inv_name,
        matchedBatchNames: searchResult.category_batch_name
      }
    } )
  }

  filterChangeCb( newFilters ) {
    this.updateBrowserHistory( 'filters', newFilters )

    this.setState( (state) => {
      let filters = ClassUtils.clone( newFilters )
      filters.textSearch = state.filters.textSearch

      if (this.props.filterChangeCb) {
        this.props.filterChangeCb(newFilters) 
      }

      return { filters: filters }
    } )
  }

  clearFiltersClick(event) {
    this.clearBrowserHistory()
    location.reload() // brutish but easiest way to reset sorting/pagination
  }

  showHideFiltersClick(event) {
    event.preventDefault()
    this.setState( (state) => { return { showFilters: !state.showFilters } } )
  }

  textSearchChange(newText) {
    this.updateFilters( 'textSearch', newText )
  }

  updateFilters( filterKey, filterValue ) {
    this.setState( (oldState) => {
      let newState = { filters: {...oldState.filters, [filterKey]: filterValue} }
      this.updateBrowserHistory( 'filters', newState.filters )
      return newState
    } )
  }

  clearBrowserHistory() {
    if (history && history.replaceState) {
      let historyState = history.state || {}
      delete historyState.filteredDocumentList
      history.replaceState( historyState, 'filtered documents' )
    }
  }

  updateBrowserHistory( key, value ) {
    if (history && history.replaceState) {
      let historyState = history.state || {}
      historyState.filteredDocumentList = historyState.filteredDocumentList || {}
      historyState.filteredDocumentList[key] = value
      history.replaceState( historyState, 'filtered documents' )
    }
  }
}
