import React from 'react';
import ConfirmWithPassword from '@components_new/shared/documents/confirm_with_password.es6';
import Dropdown from '@components_new/shared/dropdown.es6';
import FilteredDocumentList from '@components_new/shared/documents/filtered_document_list.es6';
import PagingList from '@components_new/shared/paging_list.es6';
import PagingListWithActions from '@components_new/shared/paging_list_with_actions';
import Select2Input from '@components_new/shared/select2_input.es6';
import ClassUtils from '@es6/class_utils.es6';
import DocumentActions from '@es6/documents/document_actions.es6';
import DocumentFilterTranslator from '@es6/documents/document_filter_translator.es6';

export default class FilteredAdminDocumentList extends React.Component {
  constructor(props) {
    super(props);
    ClassUtils.autobind(this);
    if (!window.sslyEvents) window.sslyEvents = new EventEmitter();
    this.sslyEvents = window.sslyEvents;
    this.state = {
      showPublishSelected: false,
      showConfirm: false,
      deleteMessage:
        'You are about to delete multiple files from ShareSecure. Please verify that you want to delete all files by entering your password below. Please note that this action cannot be undone'
    };
  }

  render() {
    // Text columns must include a render function that escapes HTML to prevent XSS attack. See ALTVIA-2245
    let columns = [
      { data: 'id', width: '40px' },
      {
        title: 'Document',
        data: 'name',
        orderable: true,
        width: '30%',
        render: FilteredDocumentList.readableAdminDocName
      }
    ];
    if (this.props.watermarkCol) {
      columns.push({
        title: 'Watermarked',
        data: 'watermark',
        orderable: false,
        className: 'optional centered-col',
        render: PagingList.checkmarkIcon
      });
    }

    if (this.props.readOnlyCol) {
      columns.push({
        title: 'Read Only',
        data: 'read_only',
        orderable: true,
        className: 'optional centered-col width-95',
        render: PagingList.checkmarkIcon
      });
    }
    
    columns = columns.concat([
      {
        title: 'Categories',
        data: 'cat_names',
        orderable: false,
        className: 'optional',
        render: $.fn.dataTable.render.text()
      },
      {
        title: 'Last Modified',
        data: 'updated_at',
        orderable: true,
        className: 'optional width-115',
        render: PagingList.readableDateTime
      },
      {
        title: 'Date',
        data: 'date',
        orderable: true,
        className: 'optional width-115',
        render: PagingList.readableDateTime
      },
      {
        title: 'Uploaded By',
        data: 'created_by_name',
        orderable: true,
        className: 'optional width-110',
        render: FilteredDocumentList.readableUploadedBy
      },
      {
        title: 'Actions',
        orderable: false,
        render: this.renderActions,
        className: 'align-right doc-actions'
      }
    ]);
    const order = this.props.watermarkCol ? [[5, 'desc']] : [[4, 'desc']];

    return (
      <div className="cmp-filtered-admin-document-list">
        <ConfirmWithPassword
          showConfirm={this.state.showConfirm}
          successCallback={this.deleteCallback}
          hideDialogCallback={this.hideDialogCallback}
          header="Are you sure you want to delete these files?"
          message={this.state.deleteMessage}
          buttonAction="DELETE"
        />
        <FilteredDocumentList
          ref={(docList) => {
            this.docList = docList;
          }}
          columns={columns}
          order={order}
          itemsPerPage={25}
          includeFilterControls={true}
          filtersToInclude={['by', 'date', 'type', 'draft', 'cat']}
          sf_batch_id={this.props.sf_batch_id}
          includeTextSearch={true}
          draftsAllowed={this.props.draftsAllowed}
          endUser={false}
          filterTranslator={DocumentFilterTranslator}
          filteringUrl={this.props.filteringUrl}
          showSelects={true}
          itemDesc="documents"
          respContainer="documents"
          filterChangeCb={this.filterChangeCb}
          itemActionCb={this.actionClick}
          actionBarClass="action-bar-responsive"
          anytimeActions={
            <span>
              <Dropdown
                title="EXPORT"
                items={[
                  { label: 'CSV', cbData: { type: 'csv' } },
                  { label: 'XLSX', cbData: { type: 'xlsx' } }
                ]}
                itemClickCb={this.exportCb}
              />
            </span>
          }
          selectedActions={
            <span className="cmp-selected-actions">
              {this.state.showPublishSelected && (
                <div
                  className="action-button colored"
                  onClick={this.publishSelectedClick}
                >
                  Publish
                </div>
              )}
              <div
                className="action-button colored"
                onClick={this.downloadSelectedClick}
              >
                Download
              </div>
              <div
                className="action-button colored warning"
                onClick={this.deleteSelectedClick}
              >
                Delete
              </div>
            </span>
          }
          multiSelectedActions={
            <span className="cmp-select-categories">
              <Select2Input
                url="/admin/categories.json"
                params={{ select2: true, page_limit: 20 }}
                inputName="selected_category_ids"
                multiple={true}
                createNew={false}
                placeholder="Add Categories"
                updateCb={this.updateCategoryTokens}
                style={{ width: '16em' }}
              />
              <div className="cmp-add-categories-button">
                <div
                  className="action-button colored"
                  onClick={this.addCategoriesToDocumentsClick}
                >
                  Apply
                </div>
              </div>
            </span>
          }
        />
      </div>
    );
  }

  renderActions(docName, renderType, doc) {
    let actions = [];
    if (doc.can_publish) {
      actions.push('publish');
    }
    actions.push('view');
    actions.push('delete');

    return PagingListWithActions.renderActions(
      actions,
      doc,
      (actionType, doc) => {
        if (actionType == 'view' && doc.is_video) {
          return 'video';
        } else if (actionType == 'view' && doc.is_audio) {
          return 'audio';
        }
        return null;
      }
    );
  }

  updateCategoryTokens(newIds) {
    this.setState({ categoryTokens: newIds.join(',') });
  }

  exportCb(cbData) {
    let filterParams = this.docList.getFilterUrlParams();
    DocumentActions.preparingDownload(
      cbData.type,
      filterParams,
      null,
      this.docList.checkFilePrepProgress
    );
  }

  actionClick(docId, action, actionInfo) {
    if (action == 'publish') {
      DocumentActions.confirmAndPublish(docId, this.docList.refreshList);
    } else if (action == 'delete') {
      DocumentActions.confirmAndDelete(docId, this.docList.refreshList);
    } else if (action == 'view') {
      this.viewDoc(docId, actionInfo);
    }
  }

  viewDoc(id, mediaType) {
    let url = `/admin/documents/${id}/view`;
    if (mediaType == 'video' || mediaType == 'audio') {
      if (typeof sslyEvents !== 'undefined') {
        sslyEvents.trigger('init-video', [url, mediaType]);
      }
    } else {
      window.open(url, '_blank');
    }
  }

  filterChangeCb(newFilters) {
    let showPublishSelected = newFilters.draft == true && this.props.canPublish;

    this.setState((state) => {
      return { showPublishSelected: showPublishSelected };
    });
  }

  addCategoriesToDocumentsClick(event) {
    let selectInfo = this.docList.getSelectInfo();
    let selectedCategories = this.state.categoryTokens;
    let filterParams = this.docList.getFilterUrlParams();
    filterParams += '&category_tokens=' + selectedCategories;
    if (selectedCategories === undefined || selectedCategories === '') {
      alert('You must select at least one category');
    } else if (
      DocumentActions.confirmSelectedDocsAction(
        selectInfo,
        filterParams,
        'add categories to'
      )
    ) {
      DocumentActions.performSelectedDocsAction(
        '/admin/documents/add_categories_to_multiple',
        selectInfo,
        filterParams,
        'Adding categories to documents',
        this.docList.refreshList
      );
    }
  }

  async publishSelectedClick() {
    let ME = this;
    let filterParams = this.docList.getFilterUrlParams();
    let selectInfo = this.docList.getSelectInfo();

    if (selectInfo.all && selectInfo.selectCount > 1) {
      alert('You cannot publish all documents, deselect at least one.');
      return;
    }

    if (
      DocumentActions.confirmSelectedDocsAction(
        selectInfo,
        filterParams,
        'publish'
      )
    ) {
      try {
        await $.post(
          `/admin/documents/publish_multiple.json?publish_ids=${selectInfo.ids}`
        );
        ME.docList.refreshList();
      } catch (xhr) {
        const msg =
          (xhr.responseJSON && xhr.responseJSON.error) || 'An error occurred';
        alert(msg);
      }
    }
  }

  downloadSelectedClick(event) {
    let filterParams = this.docList.getFilterUrlParams();
    let selectInfo = this.docList.getSelectInfo();
    if (selectInfo.selectCount == 1) {
      // this is a single document download so just download this one document directly
      this.downloadDoc(selectInfo.ids);
    } else if (
      DocumentActions.confirmSelectedDocsAction(
        selectInfo,
        filterParams,
        'download'
      )
    ) {
      DocumentActions.performSelectedDocsAction(
        '/admin/documents/download_multiple',
        selectInfo,
        filterParams,
        'Preparing your download, please wait...'
      );
    }
  }

  downloadDoc(id) {
    window.open(`/admin/documents/${id}/download`, '_blank');
  }

  deleteCallback() {
    let filterParams = this.docList.getFilterUrlParams();
    let selectInfo = this.docList.getSelectInfo();
    this.setState({ showConfirm: false });
    DocumentActions.performSelectedDocsAction(
      '/admin/documents/delete_multiple',
      selectInfo,
      filterParams,
      'Deleting documents, please wait...',
      this.docList.refreshList
    );
  }

  deleteSelectedClick() {
    let filterParams = this.docList.getFilterUrlParams();
    let selectInfo = this.docList.getSelectInfo();
    let selectCount = selectInfo.selectCount;
    let maxFilesAllowedToDeleteBeforeWarning = 25;
    if (selectCount >= maxFilesAllowedToDeleteBeforeWarning) {
      this.setState({
        showConfirm: true,
        deleteMessage:
          'You are about to delete <b>' +
          selectCount +
          '</b> files from ShareSecure. Please verify that you want to delete all files by entering your password below. Please note that this action cannot be undone.'
      });
    } else {
      DocumentActions.performSelectedDocsAction(
        '/admin/documents/delete_multiple',
        selectInfo,
        filterParams,
        'Deleting documents, please wait...',
        this.docList.refreshList
      );
    }
  }

  hideDialogCallback() {
    this.setState({ showConfirm: false });
  }
}
