import React, { useState } from 'react';

// component used for org admins
const TwoFactorAuthModal = ({
  authenticatedRootUrl,
  authToken,
  shown2faModalPath
}) => {
  const [modalOpen, setModalOpen] = useState(true);

  const update2faShown = async () => {
    await fetch(shown2faModalPath, {
      method: 'PUT',
      headers: { 'X-CSRF-Token': authToken }
    });
    setModalOpen(false);
  };

  const redirectTo2FaOnSubmit = () => {
    update2faShown();
    window.location.href = `${authenticatedRootUrl}two_factor/config`;
  };

  return (
    <>
      {modalOpen && (
        <div className="two-factor-modal_bg">
          <section className="two-factor-modal">
            <div className="two-factor-modal_header">
              <h2 className="header-text">
                Help Protect Your SHARESECURE Account
              </h2>
              <button className="close-btn" onClick={() => update2faShown()}>
                X
              </button>
            </div>
            <main className="two-factor-modal_body">
              <p>
                Please add another method of two-factor authentication. We'll
                ask for a verification code if we notice a browser we don't
                recognize as well as periodically for safety.
              </p>
              <p>Click Add 2FA Device below to add a device to your account.</p>
            </main>

            <div className="two-factor-modal_controls">
              <button
                className="submit button"
                onClick={() => redirectTo2FaOnSubmit()}
              >
                Add 2FA Device
              </button>
              <a
                className="cancel"
                key="twoFaModalbackLink"
                onClick={() => update2faShown()}
              >
                Cancel
              </a>
            </div>
          </section>
        </div>
      )}
    </>
  );
};

export default TwoFactorAuthModal;
