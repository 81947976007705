import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLayerGroup } from '@fortawesome/pro-light-svg-icons';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';

const WorkspacesNavItem = ({
  tenantName,
  workspaceUrl,
  workspaceName,
  index,
  previewByAdmin,
  selectCallback
}) => {
  const handleClick = () => {
    selectCallback(tenantName, workspaceName);
  };

  return (
    <a
      className="workspaces-nav__item"
      data-testid={`selectable-workspaces-nav__item-${index}`}
      href={previewByAdmin ? '#' : workspaceUrl}
      onClick={handleClick}
    >
      <div
        className="workspaces-nav__tenant"
        data-testid={`workspaces-nav__tenant-${index}`}
      >
        {tenantName}
      </div>
      <div
        className="workspaces-nav__workspace"
        data-testid={`workspaces-nav__workspace-${index}`}
      >
        {workspaceName}
      </div>
    </a>
  );
};

const WorkspacesNavItems = ({
  previewByAdmin,
  title,
  selectedTenant,
  selectedWorkspace,
  userWorkspaces,
  callback,
  endUserNoSidebar
}) => {
  const handleSelect = (tenantName, workspaceName) => {
    if (workspaceName != selectedWorkspace && tenantName != selectedTenant) {
      callback(tenantName, workspaceName);
    }
  };

  const selectedText = () => {
    return (
      <>
        <div
          className="workspaces-nav__tenant"
          data-testid="workspaces-nav__selected-tenant"
        >
          {selectedTenant.length > 20
            ? selectedTenant.substr(0, 20) + '...'
            : selectedTenant}
          <FontAwesomeIcon
            icon={faCaretDown}
            className="workspaces-nav__menu-icon"
          />
        </div>
        <div
          className="workspaces-nav__workspace"
          data-testid="workspaces-nav__selected-workspace"
        >
          {selectedWorkspace.length > 20
            ? selectedWorkspace.substr(0, 20) + '...'
            : selectedWorkspace}
        </div>
        <div
          className={
            endUserNoSidebar
              ? 'workspaces-nav__menu--no-sidebar dropdown-scroll'
              : 'workspaces-nav__menu dropdown-scroll'
          }
          data-testid="workspaces-nav__menu"
        >
          {userWorkspaces &&
            userWorkspaces.map((workspace, i) => {
              return (
                <WorkspacesNavItem
                  tenantName={workspace.tenant_name}
                  workspaceUrl={workspace.workspace_url}
                  workspaceName={workspace.workspace_name}
                  key={`workspace-${workspace.workspace_name}-${i}`}
                  index={`${i}`}
                  previewByAdmin={previewByAdmin}
                  selectCallback={handleSelect}
                />
              );
            })}
        </div>
      </>
    );
  };

  return (
    <>
      <span className="workspaces-nav__selection-row">
        <div
          className="workspaces-nav__title"
          data-testid="workspaces-nav__title"
        >
          {title}
        </div>
        {selectedText()}
      </span>
    </>
  );
};

const WorkspacesNavControl = ({
  previewByAdmin,
  userWorkspaces,
  currentWorkspaceName,
  currentTenant,
  endUserNoSidebar
}) => {
  let selectedTenantName = currentTenant
    ? currentTenant.name
    : 'Select Workspace';

  let selectedWorkspaceName = currentWorkspaceName ? currentWorkspaceName : '';
  //state is managed here because this component is used in old UI as well
  const [selectedWorkspace, setSelectedWorkspace] = useState({
    tenantName: selectedTenantName,
    workspaceName: selectedWorkspaceName
  });

  const handleSelectWorkspace = (tenantName, workspaceName) => {
    if (
      selectedWorkspace.tenantName != tenantName ||
      selectedWorkspace.workspaceName != workspaceName
    ) {
      setSelectedWorkspace({
        tenantName: tenantName,
        workspaceName: workspaceName
      });
    }
  };

  const endUserNoSidebarStyle = endUserNoSidebar
    ? 'workspaces-nav__widget--no-sidebar workspaces-nav__icon'
    : 'workspaces-nav__widget workspaces-nav__icon';

  const dropdownStyle = previewByAdmin
    ? 'workspaces-nav__widget  workspaces-nav__widget--disabled workspaces-nav__icon--disabled'
    : endUserNoSidebarStyle;

  return (
    <div
      className={dropdownStyle}
      role="button"
      tabIndex="0"
      data-testid="workspaces-nav__dropdown-btn-primary"
    >
      <FontAwesomeIcon
        icon={faLayerGroup}
        className="workspaces-nav__icon"
        data-testid="workspaces-nav__icon"
      />
      <WorkspacesNavItems
        previewByAdmin={previewByAdmin}
        title="workspace"
        selectedTenant={selectedWorkspace.tenantName}
        selectedWorkspace={selectedWorkspace.workspaceName}
        userWorkspaces={userWorkspaces}
        callback={handleSelectWorkspace}
        endUserNoSidebar={endUserNoSidebar}
      />
    </div>
  );
};
export default WorkspacesNavControl;
