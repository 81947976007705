import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import Modal from '@components_new/shared/end_user/modal.es6.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import UploadDocsTable from './upload_docs_table.es6';

const UploadDocsModal = ({ authToken, formUrl, closeCallback }) => {
  //default upload files state
  const defaultUploadState = {
    initialized: false,
    documents: {},
    rowIds: [],
    uniqueIds: [],
    count: 0,
    canSave: false,
    allUploaded: false,
    totalSize: 0 // total size of all uploaded docs, in bytes
  };

  // Set variables to increment total docs count and total files size
  let totalDocsCount = 0;
  let totalSize = 0;
  const [filesUploadState, setFilesUploadState] = useState(defaultUploadState);
  const [dragActive, setDragActive] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('Saving Documents');
  const [uploadStatus, setUploadStatus] = useState('ready');
  const [finishedProcessingFiles, setFinishedProcessingFiles] = useState(false);
  const [uploads, setUploads] = useState([]);

  const filesUploadStateRef = useRef(filesUploadState);

  //handle edits made by user to file name or description
  const handleUpdateFile = (rowId, newDoc) => {
    let newState = filesUploadState;
    newState = {
      ...newState,
      documents: {
        ...newState.documents,
        [rowId]: { ...newState.documents[rowId], ...newDoc }
      }
    };
    setFilesUploadState(newState);
    //pass updated state to ref to be used in buildDocParams or validateData
    filesUploadStateRef.current = newState;
  };

  //close modal on cancel click
  const closeCallbackUploadDoc = () => {
    setUploadModalState({
      visible: false
    });
    setFilesUploadState(defaultUploadState);
    closeCallback();
  };

  //open window to choose file
  const handleChooseFileBtnClick = () => {
    document.getElementById('uploadInput').click();
  };

  // Upload Modal State
  const [uploadModalState, setUploadModalState] = useState({
    visible: true,
    spinner: false,
    heading: 'Upload Documents',
    message: null,
    closeable: false,
    customAlign: { footer: 'center' },
    footerButtons: [
      {
        text: 'Cancel',
        type: 'tertiary',
        callback: closeCallbackUploadDoc
      },
      {
        text: 'Choose Files Here',
        type: 'primary',
        callback: handleChooseFileBtnClick
      }
    ]
  });

  // Save Documents Modal State
  const [onSaveModalState, setOnSaveModalState] = useState({
    visible: false,
    spinner: false,
    message: null,
    closeable: false,
    size: 'small',
    customAlign: { footer: 'center' },
    footerButtons: [
      {
        text: 'Ok',
        type: 'primary',
        callback: handleChooseFileBtnClick
      }
    ]
  });

  //create a random string to assign it to each rowId
  const randomString = (len) => {
    let outStr = '';
    while (outStr.length < len) {
      let newStr = Math.random().toString(36).slice(2);
      outStr += newStr.slice(0, Math.min(newStr.length, len - outStr.length));
    }
    return filesUploadState.rowIds.includes(outStr)
      ? randomString(len)
      : outStr;
  };

  //filesUploadState.documents change + docs were processed in initializeUpload()
  //pass apploads to uploadFile function
  useEffect(() => {
    if (finishedProcessingFiles) {
      uploadFile(uploads);
    }
  }, [filesUploadState.documents]);

  //tracking canSave boolean value to disable/unable 'Save Documents' button
  useEffect(() => {
    if (filesUploadState.canSave == true) {
      const files = Object.values(filesUploadState.documents);
      let hasSuccess =
        files.filter((file) => file.status === 'success').length > 0;
      if (hasSuccess) {
        let modifiedBtn = uploadModalState.footerButtons[1];
        modifiedBtn = { ...modifiedBtn, isDisabled: false };

        setUploadModalState((prev) => {
          return {
            ...prev,
            footerButtons: [prev.footerButtons[0], { ...modifiedBtn }]
          };
        });
      }
    }
  }, [filesUploadState.canSave]);

  //create file object and append it to FormData
  const addFileToUploads = (data) => {
    const rowId = randomString(32);
    const docObj = buildDocObject(data, rowId);

    setFilesUploadState((prev) => {
      return {
        ...prev,
        documents: { ...prev.documents, [rowId]: docObj },
        rowIds: [...prev.rowIds, rowId],
        totalSize: docObj.combined_size
      };
    });
    const formData = new FormData();

    for (const [key, value] of Object.entries(docObj)) {
      formData.append(key, value);
    }
    formData.append('file', data);
    const uploadFileObject = {
      rowId: rowId,
      formFile: formData
    };
    return uploadFileObject;
  };

  let dirFiles = false;
  //initialize upload that is called after files are added/dropped
  const initializeUpload = (_e, data) => {
    // check file is from folder
    if (dirFiles) {
      const uploadFileObject = addFileToUploads(data);
      setUploads((prev) => {
        return [...prev, uploadFileObject];
      });
    } else {
      data.map((file) => {
        const uploadFileObject = addFileToUploads(file);
        setUploads((prev) => {
          return [...prev, uploadFileObject];
        });
      });
    }
    setFinishedProcessingFiles(true);
  };

  //completed all files upload
  let completedUpload = 0;
  const handleAllUploaded = (completed) => {
    const filesCount = filesUploadState.count;

    if (completed) {
      completedUpload = completedUpload + 1;
    }

    if (completedUpload == filesCount) {
      setFilesUploadState((prev) => {
        return {
          ...prev,
          allUploaded: true
        };
      });
    }
  };

  //upload progress of each file
  const handleProgress = (progressEvent, rowId) => {
    if (!progressEvent.lengthComputable) return;

    let uploadProgress = parseInt(
      (progressEvent.loaded / progressEvent.total) * 100,
      10
    );
    if (uploadProgress > 100) {
      uploadProgress = 100;
    }
    let doc = filesUploadState.documents[rowId];
    const previousProgress = doc.progress;

    if (uploadProgress != previousProgress) {
      doc.progress = uploadProgress;
      if (uploadProgress == 100) {
        doc.status = 'securing';
      }
      setFilesUploadState((prev) => {
        return {
          ...prev,
          documents: {
            ...prev.documents,
            [rowId]: { ...filesUploadState.documents[rowId], ...doc }
          }
        };
      });
    }
  };

  //call '/update' endpoint to insert documents
  const uploadFile = async (filesData) => {
    setFinishedProcessingFiles(false);
    const newTimer = setTimeout(24 * 60 * 1000);

    for (let i = 0; i < filesData.length; i++) {
      //create cancel token for each file
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      let rowId = filesData[i].rowId;
      let formData = filesData[i].formFile;
      const doc = filesUploadState.documents[rowId];
      doc.cancelSource = source;

      //add cancel token to each document state
      setFilesUploadState((prev) => {
        return {
          ...prev,
          documents: {
            ...prev.documents,
            [rowId]: { ...filesUploadState.documents[rowId], ...doc }
          }
        };
      });

      await axios
        .post(formUrl + '/upload', formData, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
            'X-CSRF-Token': authToken
          },
          newTimer,
          transformRequest: (formData) => formData,
          cancelToken: source.token,
          maxContentLength: 10485760,
          maxBodyLength: 10485760,
          onUploadProgress: (progressEvent) => {
            handleProgress(progressEvent.event, rowId);
          }
        })
        .then((response) => {
          if (response.status == 201) {
            uploadSuccess(response);
          }
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
            uploadFail(rowId, error.response);
          } else if (error.response) {
            uploadFail(rowId, error.response.data.error);
          } else {
            uploadFail(rowId, error);
          }
        });
    }
  };

  //delete failed document
  const deleteFailedDoc = async (rowId) => {
    await axios.delete(formUrl + '/multipart/' + rowId, {
      headers: {
        'X-CSRF-Token': authToken
      }
    });
  };

  const uploadSuccess = (data) => {
    const id = null;
    handleCompletedUpload(id, data, true);
  };

  const uploadFail = (id, data) => {
    handleCompletedUpload(id, data, false);
  };

  let fileCompleted = false;
  let uniqueDocIds = [];

  //called after initial upload
  const handleCompletedUpload = (id, respData, success) => {
    let rowId = null;
    let formData = null;

    if (success) {
      formData = respData.data;
      rowId = formData.files[0].upload_form_row_id;
    } else {
      rowId = id;
    }

    const docs = filesUploadState.documents;
    let doc = docs[rowId];

    if (doc) {
      doc.status = success ? 'success' : 'fail';
      doc.progress = 100;
      const status = respData == undefined ? -1 : respData.status;

      if (success) {
        const savedFile = formData.files[0];
        //check for duplicates
        if (savedFile.unique_id && uniqueDocIds.includes(savedFile.unique_id)) {
          if (typeof Rollbar !== 'undefined') {
            Rollbar.error('Document upload form contains duplicate unique id');
          }
          alert(
            'An error occurred processing the files. Please try uploading the files again.'
          );
          window.location.reload();
        }
        uniqueDocIds.push(savedFile.unique_id);
        doc.uploaded_document_id = savedFile.id;
        doc.unique_id = savedFile.unique_id;
        doc.content_type = savedFile.content_type;
      } else if (status === 415) {
        formData = respData.data;
        doc.description = formData.error;
      } else if (status === 403) {
        formData = respData.data;
        const msg = formData.error;
        doc.description = msg.includes('not authorized')
          ? 'User file upload disabled'
          : msg;
      } else if (status === -1) {
        // upload has been canceled
        doc.description = 'Upload canceled';
      } else {
        doc.description = `An error occurred processing the file. ${respData}`;
      }
      if (!success) {
        deleteFailedDoc(rowId);
      }
      fileCompleted = true;
      handleAllUploaded(fileCompleted);

      setFilesUploadState((prev) => {
        return {
          ...prev,
          documents: { ...prev.documents, [rowId]: doc },
          uniqueIds: [...prev.uniqueIds, doc.unique_id],
          canSave: prev.allUploaded
        };
      });
      filesUploadStateRef.current = filesUploadState;
    }
  };

  //selected files by clicking choose files here btn
  const handleChooseFile = (e) => {
    e.preventDefault();

    if (e.target.files && e.target.files[0]) {
      const countFiles = e.target.files.length;
      setFilesUploadState((prev) => {
        return {
          ...prev,
          count: countFiles,
          initialized: true
        };
      });

      setUploadModalState({
        visible: true,
        spinner: false,
        heading: 'Upload Documents',
        message: null,
        closeable: false,
        size: 'large',
        customAlign: { footer: 'center' },
        footerButtons: [
          {
            id: 'cancel-download-btn',
            text: 'Cancel',
            type: 'tertiary',
            callback: closeCallbackUploadDoc
          },
          {
            id: 'choose-download-btn',
            text: 'Save Documents',
            type: 'primary',
            callback: (e) => handleSaveDocumentsClick(e),
            isDisabled: true
          }
        ]
      });

      //pass selected files to initializer
      initializeUpload(e, Array.from(e.target.files));
    }
  };

  const handleDragFile = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleSetUploadModal = () => {
    setUploadModalState({
      visible: true,
      spinner: false,
      heading: 'Upload Documents',
      message: null,
      closeable: false,
      size: 'large',
      customAlign: { footer: 'center' },
      footerButtons: [
        {
          id: 'cancel-download-btn',
          text: 'Cancel',
          type: 'tertiary',
          callback: closeCallbackUploadDoc
        },
        {
          id: 'choose-download-btn',
          text: 'Save Documents',
          type: 'primary',
          callback: (e) => handleSaveDocumentsClick(e),
          isDisabled: true
        }
      ]
    });
  };

  //selected files by drag/drop action
  const getFilesFromDir = async (e, entry) => {
    dirFiles = true;
    let countEntries = 0;

    let directoryReader = entry.createReader();

    await directoryReader.readEntries((entries) => {
      let hasDsFile = entries.some(
        (fileEntry) => fileEntry.name === '.DS_Store'
      );
      hasDsFile
        ? (countEntries = entries.length - 1)
        : (countEntries = entries.length);
      if (countEntries > 0) {
        entries.map((dir, _key) => {
          dir.file((file) => {
            if (file.name != '.DS_Store') {
              initializeUpload(e, file);
            }
          });
        });
        handleSetUploadModal(e);
        setFilesUploadState((prev) => {
          return {
            ...prev,
            count: countEntries,
            initialized: true
          };
        });
      } else {
        handleDropFile();
      }
    });
  };

  //selected files by drag/drop action
  const handleDropFile = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    let entry = e.dataTransfer.items[0].webkitGetAsEntry();

    // check it is directory
    if (entry.isDirectory) {
      getFilesFromDir(e, entry);
    } else {
      if (e.dataTransfer.files && e.dataTransfer.files[0]) {
        const countFiles = e.dataTransfer.files.length;
        setFilesUploadState((prev) => {
          return {
            ...prev,
            count: countFiles,
            initialized: true
          };
        });
        handleSetUploadModal(e);
        //pass selected files to initializer
        initializeUpload(e, Array.from(e.dataTransfer.files));
      }
    }
  };

  //on Save click modal when upload is pending
  const OnSavePending = () => {
    setOnSaveModalState({
      visible: true,
      spinner: false,
      closeable: false,
      size: 'small'
    });
  };

  //On save click modal state success or error
  const OnSaveSuccessOrError = (statusOfUpload) => {
    setOnSaveModalState({
      visible: true,
      spinner: false,
      closeable: false,
      size: 'small',
      customAlign: { footer: 'center' },
      footerButtons: [
        {
          text: 'Ok',
          type: 'primary',
          callback: statusOfUpload == 'success' ? clearAlert : clearErrorAlert
        }
      ]
    });
  };

  //Display correct message after Save Documents button was clicked
  const onSaveMsg = () => {
    return uploadStatus === 'pending' ? (
      <div className="upload-docs-modal__alert-modal-body upload-docs-modal__alert-modal-body-column">
        <div className="upload-docs-modal__alert-modal-text">
          {alertMessage}
        </div>
        <FontAwesomeIcon
          icon={faSpinner}
          className="upload-docs-modal__modal-spinner fa-spin-pulse"
          data-testid="upload-docs-modal__modal-spinner"
        />
      </div>
    ) : (
      <div className="upload-docs-modal__alert-modal-body upload-docs-modal__alert-modal-body--shadow ">
        <div className="upload-docs-modal__alert-modal-text">
          {alertMessage}
        </div>
      </div>
    );
  };

  //used to get uploadStatus state and display onSaveModal with message
  useEffect(() => {
    if (uploadStatus === 'pending') {
      OnSavePending();
    } else {
      OnSaveSuccessOrError(uploadStatus);
    }
  }, [uploadStatus]);

  //handle Save Documents button click
  const handleSaveDocumentsClick = async (e) => {
    e.preventDefault();
    const params = buildParams();

    if (!validateData()) {
      return false;
    }

    setUploadStatus('pending');
    setAlertMessage('Saving Documents');
    setShowAlert(true);
    const resp = await axios.post('/documents', params, {
      headers: {
        Accept: 'application/json',
        'X-CSRF-Token': authToken
      }
    });

    if (resp.status == 200) {
      setUploadStatus('success');
      setAlertMessage(
        'File(s) successfully created. After passing virus scanning, they will be available for use.'
      );
      setShowAlert(true);
    }
    if (
      //not sure how to test that one
      resp.constructor === Array &&
      resp.length > 0 &&
      resp[0].constructor === String
    ) {
      let msgHTML =
        'There was an error with ' + resp.length + ' of your documents:<br/>';
      for (let i = resp.length - 1; i >= 0; i--) {
        msgHTML += resp[i] + '<br/>';
      }
      setUploadStatus('error');
      setAlertMessage(msgHTML);
      setShowAlert(true);
    }
    if (resp.status === 403) {
      setUploadStatus('failed') /
        setAlertMessage('You do not have permission to perform this action.');
      setShowAlert(true);
    }
  };

  //doc object that will be added to filesUploadState.documents
  const buildDocObject = (fileData, rowId) => {
    const file = fileData;
    // Increment docs count and total docs size
    totalDocsCount = totalDocsCount + 1;
    totalSize = totalSize + file.size;

    return {
      file_name: file.name,
      name: file.name,
      original_size: file.size,
      upload_form_row_id: rowId,
      uploaded_document_id: null,
      unique_id: null,
      content_type: file.type,
      description: '',
      progress: 0,
      status: 'uploading',
      cancelSource: undefined,
      combined_size: totalSize,
      docs_count: totalDocsCount
    };
  };

  const buildParams = () => {
    return {
      authenticity_token: authToken,
      documents: buildDocParams()
    };
  };

  const buildDocParams = () => {
    let docParams = [];
    const submitKeys = [
      'name',
      'unique_id',
      'uploaded_document_id',
      'description',
      'content_type'
    ];

    //needs ref here to get filesUploadState
    const data = filesUploadStateRef.current;

    for (let rowId in data.documents) {
      const doc = data.documents[rowId];

      if (doc.status === 'success') {
        let thisDocParams = {};
        for (let i = 0; i < submitKeys.length; i++) {
          const key = submitKeys[i];
          thisDocParams[key] = doc[key];
        }
        docParams = [...docParams, thisDocParams];
      }
    }
    return docParams;
  };

  const uploadModalBody = () => {
    return filesUploadState.initialized && filesUploadState.documents ? (
      <UploadDocsTable
        documents={filesUploadState.documents}
        abortUploadCb={cancelUpload}
        updateFileCb={handleUpdateFile}
      />
    ) : (
      <form
        className="upload-docs-modal__form"
        acceptCharset="UTF-8"
        onDragEnter={handleDragFile}
      >
        <input
          type="file"
          className="upload-docs-modal__form-input"
          name="file"
          id="uploadInput"
          multiple
          onChange={handleChooseFile}
        />
        <label
          className={
            dragActive
              ? 'upload-docs-modal__label'
              : 'upload-docs-modal__label upload-docs-modal__drag-file--active'
          }
          htmlFor="upload-docs-modal__input"
        >
          <p className="upload-docs-modal__text">
            Drag and Drop Files Here or Choose Below
          </p>
        </label>
        {dragActive && (
          <div
            className="upload-docs-modal__drag-file"
            onDragEnter={handleDragFile}
            onDragLeave={handleDragFile}
            onDragOver={handleDragFile}
            onDrop={handleDropFile}
          ></div>
        )}
      </form>
    );
  };

  const clearAlert = () => {
    setOnSaveModalState({
      visible: false
    });
    setUploadModalState({
      visible: false
    });

    setFilesUploadState(defaultUploadState);
    closeCallback();
  };

  //close error modal
  const clearErrorAlert = () => {
    setOnSaveModalState({
      visible: false
    });
  };

  //validate input fields before saveing documents
  const validateData = () => {
    let error = false;
    let message;

    //get recent documents state from ref
    let currentDocsState = filesUploadStateRef.current;

    for (let i = 0; i < currentDocsState.rowIds.length; i++) {
      const doc = currentDocsState.documents[currentDocsState.rowIds[i]];
      if (doc.name.length === 0) {
        error = true;
        message = 'Please choose a display name for each document.';
        break;
      } else if (doc.name.length > 255) {
        error = true;
        message =
          'Name cannot be more than 255 characters. Please shorten and resubmit.';
        break;
      }
    }

    if (error) {
      setAlertMessage(message);
      setUploadStatus('error');
      setShowAlert(true);
      return false;
    }
    return true;
  };

  //cancel upload of the file
  const cancelUpload = (cancelFile) => {
    if (cancelFile != undefined) {
      cancelFile.cancel('Upload canceled');
    }
  };

  return (
    <Modal {...uploadModalState}>
      {uploadModalBody()}
      {showAlert ? <Modal {...onSaveModalState}>{onSaveMsg()}</Modal> : null}
    </Modal>
  );
};

export default UploadDocsModal;
