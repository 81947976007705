import React, { useState, useEffect } from 'react';
import EditTable from './edit_table.es6';

const EditDashboardOrderTable = ({ dashboards, groupId, authToken }) => {
  const dashboardsOrder = dashboards.map((d) => ({
    id: d.id,
    dashboard_order: d.dashboard_order || 0
  }));

  // isOpen state is needed to open the whole component
  const [isOpen, _setIsOpen] = useState(true);
  // dashboards state to display in the edit table
  const [dashboardsOrderList, setDashboardsOrderList] =
    useState(dashboardsOrder);
  // dashboards state that were updated and will be submitted to DB
  const [updatedDashboards, setUpdatedDashboards] = useState([]);
  const [hasDuplicates, setHasDuplicates] = useState(false);
  const [error, setError] = useState(false);

  // create unique key
  const keyFn = (dashboard) => {
    return dashboard.id;
  };

  let optionNumbers = [];
  if (dashboards.length > 0) {
    optionNumbers = [
      ...Array.from({ length: dashboards.length + 1 }, (_, i) => i)
    ];
  }

  // create dropdown options
  let options = optionNumbers.map((option, i) => {
    return (
      <option
        id={`option-${i}]`}
        key={i}
        value={option}
        label={option != 0 ? option.toString() : ''}
      ></option>
    );
  });

  const errorText = (text) => {
    return <div className="edit-dashboard-order-table__error">{text}</div>;
  };

  const checkDuplicates = () => {
    let dashboardsRecords = [];
    dashboardsRecords = dashboardsOrderList;

    let duplicateOrderValues = dashboardsRecords
      .map((d) => d['dashboard_order'])
      .map((d, i, final) => final.indexOf(d) !== i && i)
      .filter((dashboard) => dashboardsRecords[dashboard])
      .map((d) => dashboardsRecords[d]['dashboard_order']);

    // only 0 can be used more than once as value for the group order
    // it is translated to nil in backend
    let filteredDuplicateOrderValues = duplicateOrderValues.filter(
      (val) => val !== 0
    );

    if (filteredDuplicateOrderValues.length > 0) {
      setHasDuplicates(true);
    } else {
      setHasDuplicates(false);
    }
  };

  useEffect(() => {
    checkDuplicates();
  }, [dashboardsOrderList]);

  const handleDashboardOrderChange = (id, value) => {
    let updated = updatedDashboards;
    // check if the dashboard's order was already updated
    if (updated.length > 0) {
      const alreadyUpdatedDashboard = updated.find((d) => d.id === id);
      if (alreadyUpdatedDashboard) {
        /**
         * if the dashboard was already updated,
         * update it again with the new value
         */
        let updateDashboards = updated.map((d) => {
          if (d.id === alreadyUpdatedDashboard.id) {
            return { ...d, dashboard_order: value };
          } else {
            return d;
          }
        });
        setUpdatedDashboards(updateDashboards);
      } else {
        updated.push({ id: id, dashboard_order: value });
        setUpdatedDashboards(updated);
      }
    } else {
      updated.push({ id: id, dashboard_order: value });
      setUpdatedDashboards(updated);
    }
  };

  const handleDashboardOrderSelection = (id, event) => {
    const value = event.target.value === '' ? 0 : parseInt(event.target.value);

    handleDashboardOrderChange(id, value);
    const updatedDashboardsOrder = dashboardsOrderList.map((dashboard) => {
      if (dashboard.id === id) {
        return {
          ...dashboard,
          dashboard_order: value
        };
      } else {
        return dashboard;
      }
    });
    setDashboardsOrderList(updatedDashboardsOrder);
  };

  const currentValue = (dashboard) => {
    let value = null;
    dashboardsOrderList.map((d) => {
      if (d.id === dashboard.id) {
        value = d.dashboard_order;
      } else {
        value;
      }
    });
    return value;
  };

  const columns = [
    {
      label: 'Name',
      name: 'name',
      grow: 'edit-table__cell--grow--wrap',
      render: (dashboard) => dashboard.sisense_title
    },
    {
      label: 'Order',
      name: 'dashboard_order',
      grow: 'edit-table__cell--fixed-60',
      render: (dashboard) => {
        return (
          <select
            name="selectionOptions"
            id={`selectionOptions-${dashboard.id}`}
            onChange={(e) => handleDashboardOrderSelection(dashboard.id, e)}
            value={currentValue(dashboard)}
          >
            {options}
          </select>
        );
      }
    }
  ];

  const handleSave = async () => {
    setError(false);
    if (updatedDashboards.length > 0) {
      const allUpdatedDashboards = {
        dashboards: updatedDashboards
      };
      const url = `/admin/groups/${groupId}/group_dashboards/set_dashboard_order`;

      await fetch(url, {
        method: 'post',
        headers: {
          'X-CSRF-Token': authToken,
          credentials: 'same-origin',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(allUpdatedDashboards)
      }).then((response) => {
        if (response.ok) {
          // if response is ok then refresh page that will close the edit table
          location.reload();
        } else {
          setError(true);
        }
      });
    }
  };

  const handleCancel = () => {
    const initailDashboardsOrder = dashboardsOrder;
    setDashboardsOrderList(initailDashboardsOrder);
    const cancel = document.getElementById('edit-dashboard-order-holder');
    cancel.style.display = 'none';
    setError(false);
  };

  const noDashboardsMsg = 'No dashboards';
  return (
    <>
      {isOpen && (
        <>
          <EditTable
            data={dashboards}
            columns={columns}
            keyFn={keyFn}
            message={noDashboardsMsg}
          />
          {hasDuplicates && errorText('Dashboard order number must be unique.')}
          {error && errorText('Error updating dashboards order.')}
          <div className="edit-dashboard-order-table__btns-wrapper">
            <div
              className={`${
                hasDuplicates || dashboards.length === 0
                  ? 'edit-dashboard-order-table__action-button disabled colored'
                  : 'edit-dashboard-order-table__action-button colored'
              }`}
              onClick={handleSave}
            >
              Save
            </div>

            <div
              className="edit-dashboard-order-table__action-button not-colored"
              onClick={handleCancel}
            >
              Cancel
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default EditDashboardOrderTable;
