import React from 'react';

const TableCheckbox = ({ value = false, id, handleChange }) => {
  return (
    <input
      type="checkbox"
      className="tableCheckbox"
      id={`${id}-checkbox`}
      data-testid={`checkboxId-${id}`}
      value={value}
      checked={value}
      onClick={(e) => e.stopPropagation()}
      onChange={handleChange}
    />
  );
};

export default TableCheckbox;
