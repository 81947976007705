import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleCheck,
  faCircleXmark
} from '@fortawesome/pro-regular-svg-icons';

const ViewedIcon = ({ viewed, docId }) => {
  const icon = viewed ? faCircleCheck : faCircleXmark;
  return (
    <span className="viewed-icon">
      <FontAwesomeIcon
        className={
          icon == faCircleCheck ? 'viewed-icon__true' : 'viewed-icon__false'
        }
        icon={icon}
        data-testid={`viewed-icon-item-id-${docId}-${viewed}`}
      />
    </span>
  );
};

export default ViewedIcon;
