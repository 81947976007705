import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbTack } from '@fortawesome/pro-light-svg-icons';
import { faThumbTack as faPinnedThumbTack } from '@fortawesome/pro-solid-svg-icons';

const CategoryPin = ({
  categoryId = null, // integer, id of the pinned category
  pinned = false, // boolean, determines initial active state
  type = '', // string, 'categories' or 'investor-names'
  handleCategoryPinned = null, // function, sends a POST request to /pin_category
  handleCategoryUnpinned = null // function, sends a DELETE request to /unpin_category
}) => {
  const [activePin, setActivePin] = useState(pinned);

  const handleClick = (e) => {
    e.stopPropagation();
    setActivePin(!activePin);
    pinned
      ? handleCategoryUnpinned(categoryId, type)
      : handleCategoryPinned(categoryId, type);
  };

  return (
    // Div wrapper around icon added for better identification by E2E tests.
    <div
      onMouseEnter={() => setActivePin(!activePin)}
      onMouseLeave={() => setActivePin(!activePin)}
      onClick={(e) => handleClick(e)}
      className={`category-sidebar__category-pin category-sidebar__category-pin${
        pinned ? '--show' : '--hide'
      }`}
    >
      <FontAwesomeIcon
        className="category-sidebar__category-pin-icon"
        icon={activePin ? faPinnedThumbTack : faThumbTack}
      />
    </div>
  );
};

export default CategoryPin;
