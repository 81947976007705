import React from 'react';
import ClassUtils from '@es6/class_utils.es6';

export default class PillSelect extends React.Component {
  constructor(props) {
    super(props)
    ClassUtils.autobind(this)
  }

  componentDidMount() {
    this.fireChange()
  }

  render() {
    const pillsHtml = this.props.options.map(option =>
      <div key={'option-' + option[0]} data-value={option[0]} onClick={this.updateInput}
           className={'better-button select-pill' + (this.props.selected === option[0] ? ' selected' : '')}>
        <span className="pill-text">{option[1]}</span>
      </div>
    )

    return (
      <div className="pill-select">
        <input type="hidden" ref={elt => this.input = elt} name={this.props.object + '[' + this.props.field + ']'}
               id={this.props.object + '_' + this.props.field} defaultValue={this.props.selected} />
        {pillsHtml}
      </div>
    )
  }

  updateInput(e) {
    const pills = document.getElementsByClassName('select-pill')
    for(let i = 0; i < pills.length; i++) {
      pills[i].classList.remove('selected')
    }
    const pillElt = e.target.classList.contains('pill-text') ? e.target.parentElement : e.target
    pillElt.classList.add('selected')
    this.input.value = pillElt.dataset.value
    this.fireChange()
  }

  fireChange() {
    let evt = null
    if(typeof(Event) === 'function') {
      // reasonable browsers
      evt = new Event('change', {bubbles: true})
    } else {
      // IE
      evt = document.createEvent('Event')
      evt.initEvent('change', true, true)
    }
    this.input.dispatchEvent(evt)
  }
}