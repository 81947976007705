import React, { useState, useEffect, useRef } from 'react';
import Modal from '@components_new/shared/end_user/modal.es6.jsx';

const SessionMonitorModal = ({
  authToken // string, csrf token to include on api calls
}) => {
  const [secondsLeft, setSecondsLeft] = useState(1800);
  const [sessionModalState, setSessionModalState] = useState({
    visible: false
  });
  let secondsLeftRef = useRef(secondsLeft);
  let nextTimeCheck;

  const checkTimeLeft = async () => {
    clearTimeout(nextTimeCheck);
    try {
      const response = await fetch('/api/session_time', {
        method: 'GET',
        headers: { 'X-CSRF-Token': authToken }
      });
      const data = await response.json();
      if (data == undefined) return;

      if (data.time_left == 0) {
        window.location.reload();
      } else if (response.status == 401) {
        window.location.reload();
      } else {
        let timeLeft = data.time_left;
        setSecondsLeft(timeLeft);
        if (timeLeft > 300) {
          const nextCheckIn = (timeLeft - 300) * 1000; // make sure session hasn't been extended
          nextTimeCheck = setTimeout(checkTimeLeft, nextCheckIn);
        } else {
          nextTimeCheck = setTimeout(checkTimeLeft, (timeLeft + 1) * 1000);
        }
      }
    } catch (error) {
      window.location.reload();
    }
  };

  const refreshSession = async () => {
    await fetch('/api/refresh_session', {
      method: 'GET',
      headers: { 'X-CSRF-Token': authToken }
    }).then(() => {
      checkTimeLeft();
    });
    setSessionModalState({ visible: false });
  };

  const showModal = () => {
    setSessionModalState({
      visible: true,
      heading: 'Session Timeout',
      children: (
        <span>
          Your session will expire in {secondsLeft} seconds due to inactivity.
          You can resume your session by clicking the button below. If you do
          nothing, you will be logged out automatically.
        </span>
      ),
      closeable: false,
      customAlign: { footer: 'center' },
      footerButtons: [
        {
          text: 'Stay logged in',
          type: 'primary',
          callback: refreshSession
        }
      ]
    });
  };

  const countDown = () => {
    const seconds = secondsLeftRef.current;
    if (seconds > 0) {
      setSecondsLeft((prev) => prev - 1);
    }
    setTimeout(countDown, 1000);
  };

  useEffect(() => {
    //get current secondsLeft from ref as state is not updating here
    secondsLeftRef.current = secondsLeft;
    if (secondsLeft < 300) {
      return showModal();
    }
  }, [secondsLeft]);

  useEffect(() => {
    checkTimeLeft();
    countDown();
    window.addEventListener('focus', (_event) => {
      checkTimeLeft();
    });
  }, []);

  return <Modal {...sessionModalState}></Modal>;
};
export default SessionMonitorModal;
