import React from 'react';
import PagingList from './paging_list.es6';
import PagingListWithActions from './paging_list_with_actions';
import AjaxUtils from '@es6/ajax_utils.es6';
import ClassUtils from '@es6/class_utils.es6';
import DocumentFilterTranslator from '@es6/documents/document_filter_translator.es6';

export default class GroupMemberTable extends React.Component {
  constructor(props) {
    super(props)
    ClassUtils.autobind(this)
    this.state = this.getDefaultState()
    if (!window.sslyEvents) window.sslyEvents = new EventEmitter()
    this.sslyEvents = window.sslyEvents
  }
  componentDidMount() {
    if(this.sslyEvents) {
      this.sslyEvents.on('new-group-member', this.refreshList)
    }
  }

  render () {
    // Text columns must include a render function that escapes HTML to prevent XSS attack. See ALTVIA-2245
    const columns = [
      { data: 'id', width: '40px' },
      { title: 'User', data: "name", orderable: true, width: '30%', render: this.readableUserName },
      { title: 'Company', data: "company", orderable: true, render: $.fn.dataTable.render.text() },
      { title: 'Joined Group', data: "created_at", orderable: true, render: PagingList.readableDateTime },
      { title: 'Actions', orderable: false, render: this.renderActions, className: 'align-right' }
    ]
    const order = [[1, 'asc']];

    return (
      <div className="user-table">
        <div className="header"></div>
        <PagingListWithActions
          ref={ (dataList) => { this.dataList = dataList } }
          columns={columns} order={order}
          showSelects={true}
          includeTextSearch={this.props.includeTextSearch}
          filterTranslator={DocumentFilterTranslator}
          filteringUrl={this.props.filteringUrl}
          filters={this.state.filters}
          textSearchChangeCb={this.textSearchChange}
          page={this.initialPage}
          itemDesc='users'
          respContainer='users'
          actionBarClass={'user-table-search'}
          textSearchChangeCb={this.textSearchChange}
          itemActionCb={this.actionClick}
          includeTextSearch={true}
          selectedActions={
            <span className='cmp-selected-actions'>
              {(!this.props.everyoneGroup) && <div className="action-button colored warning" onClick={this.removeSelected}>Remove Selected Users</div>}
            </span>
          }
          multiSelectedActions={this.props.multiSelectedActions}
        />
      </div>
    )
  }

  refreshList(){
    this.dataList.refreshList()
  }

  getDefaultState() {
    let filters = { textSeach : null }
    return { filters }
  }

  updateFilters( filterKey, filterValue ) {
    this.setState( (oldState) => {
      let newState = { filters: {...oldState.filters, [filterKey]: filterValue} }
      return newState
    } )
  }

  textSearchChange(newText) {
    this.updateFilters( 'textSearch', newText )
  }

  readableUserName(name, type, user){
    const safeName = $.fn.dataTable.render.text().display(name)
    if(type == 'display') {
      return `<a href="/admin/organization_users/${user.user_id}">${safeName}</a>`
    } else {
      return safeName
    }
  }

  renderActions(userName, renderType, user) {
    if(!this.props.everyoneGroup){
      return PagingListWithActions.renderActions( [ 'remove' ], user)
    } else{
      return PagingListWithActions.renderActions([''], user)
    }
  }

  actionClick(userId, action){
    if(action == 'remove'){
      this.removeUserFromGroup(userId, this.dataList.refreshList)
    }
  }

  removeSelected(){
    let selectInfo = this.dataList.getSelectInfo();
    if (confirm( 'Are you sure you want to remove the selected users from this group?') ) {
      this.removeSelectedUsers('/admin/group_members/delete_multiple' , selectInfo, 'Removing users from group', this.dataList.refreshList)
    }
  }

  removeSelectedUsers(actionUrl, selectInfo, progressMsg, refreshCb){
    const queryOp = actionUrl.includes('?') ? '&' : '?'
    if(selectInfo.all){
      ssly.BackgroundRequest.send({ path: `${actionUrl}${queryOp}group_id=${this.props.groupId}&all=true`, message: progressMsg, doneCb: refreshCb, method: 'DELETE'  });
    } else {
      ssly.BackgroundRequest.send({ path: `${actionUrl}${queryOp}id=${selectInfo.ids}&group_id=${this.props.groupId}`, message: progressMsg, doneCb: refreshCb, method: 'DELETE'  });
    }
  }

  removeUserFromGroup(userId, refreshCb){
    if ( confirm( 'Are you sure you want to remove this user from this group?' ) ) {
      let ME = this
      ssly.Modal.show({message: 'Removing...', spinner: true})
      $.ajax({
        url: '/admin/group_members/' + userId,
        method: 'DELETE',
        dataType: 'script',
        error: function(xhr, txtStatus, error) {
          if(!AjaxUtils.handleUnauthorized(xhr.status)) {
            const responseText = xhr && xhr.responseText;
            let msg;
            try {
              msg = JSON.parse(responseText).error;
            } catch(e) {
              msg = error;
            }
            alert('An error occurred while group member: ' + msg)
          }
          ssly.Modal.hide()
          if(refreshCb) refreshCb()
        },
        success: function(resp) {
          ssly.Modal.hide()
          if(refreshCb) refreshCb()
        },
        timeout: 10000
      });
    }
  }
}