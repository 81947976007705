import React from 'react';
import ToggleSwitch from '@components_new/shared/end_user/toggle_switch.es6';

const V2ToggleSwitch = ({ v2Url }) => {
  const handleToggleSwitchEnabled = () => {
    window.location.href = v2Url;
  };

  return (
    <ToggleSwitch
      name="V2 Toggle"
      text="New Experience"
      isOn={false}
      handleToggle={handleToggleSwitchEnabled}
      previewByAdmin={false}
    />
  );
};

export default V2ToggleSwitch;
