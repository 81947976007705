import React from 'react';

const EditTable = ({ data, columns, keyFn, message }) => {
  const renderedHeaders = columns.map((column, index) => {
    let classes = [];

    if (column.grow) {
      classes.push(column.grow);
    }
    if (column.alignItems) {
      classes.push(column.alignItems);
    }

    const combinedClasses = classes.join(' ');

    return (
      <div
        className={combinedClasses}
        key={column.label}
        data-testid={`edit-table__header-row-${index}`}
      >
        {column.label}
      </div>
    );
  });
  const renderedRows = data.map((rowData, idx) => {
    const renderedCells = columns.map((column) => {
      let classes = [];
      if (column.alignItems) {
        classes.push(column.alignItems);
      }
      if (column.grow) {
        classes.push(column.grow);
      }

      const combinedClasses = classes.join(' ');

      return (
        <div
          className={combinedClasses}
          key={column.label}
          data-testid={`column-row-${idx}`}
        >
          {column.render(rowData)}
        </div>
      );
    });

    return (
      <div
        className="edit-table__row hoverstate"
        key={keyFn(rowData)}
        data-testid={`edit-table__row-${idx}`}
      >
        {renderedCells}
      </div>
    );
  });

  const noData = () => {
    return (
      <div className="edit-table__row" data-testid="no-data-msg">
        {message}
      </div>
    );
  };

  return (
    <div className="edit-table" role="table">
      <div className="edit-table__row edit-table__row-header">
        {renderedHeaders}
      </div>
      <div className="edit-table__rows">
        {data.length == 0 ? noData() : renderedRows}
      </div>
    </div>
  );
};
export default EditTable;
