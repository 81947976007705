import React from 'react';
import FilteredDocumentList from '@components_new/shared/documents/filtered_document_list.es6';
import PagingListWithActions from '@components_new/shared/paging_list_with_actions';
import ClassUtils from '@es6/class_utils.es6';
import DocumentActions from '@es6/documents/document_actions.es6';
import DocumentFilterTranslator from '@es6/documents/document_filter_translator.es6';

export default class CategoryDocumentsList extends React.Component {
  constructor(props) {
    super(props)
    ClassUtils.autobind(this)
    this.itemsPerPage = 20
  }

  render() {
    // Text columns must include a render function that escapes HTML to prevent XSS attack. See ALTVIA-2245
    const columns = [
      { data: 'id', width: '40px' },
      { title: 'Document', data: "name", orderable: true, width: '40%', render: FilteredDocumentList.readableAdminDocName },
      { title: 'Categories', data: 'cat_names', orderable: false, className: 'optional', render: $.fn.dataTable.render.text() },
      { title: 'Actions', orderable: false, render: this.renderActions, className: 'align-right' }
    ]
    const order = [[ 1, 'asc' ]]

    return (
      <div className="cmp-category-documents-list">
        <FilteredDocumentList
          ref={ (docList) => { this.docList = docList } }
          columns={columns} order={order} itemsPerPage={this.itemsPerPage}
          includeTextSearch={true}
          title='Documents in this Category'
          showSelects={true}
          endUser={false}
          filterTranslator={DocumentFilterTranslator}
          filteringUrl={this.props.filteringUrl}
          itemDesc='documents'
          respContainer='documents'
          itemActionCb={this.actionClick}
          actionBarClass='action-bar-responsive'
          selectedActions={
              [
                <div className="action-button colored warning" onClick={this.removeSelectedClick}>Remove Selected Documents</div>,
              ]
          }
          selectOnlyThisPage={true}
        />
      </div>
    )
  }

  renderActions(docName, renderType, doc) {
    return PagingListWithActions.renderActions( [ 'edit', 'remove' ], doc)
  }

  actionClick(docId, action) {
    if (action == 'edit') {
      DocumentActions.edit( docId )
    } else if (action == 'remove') {
      DocumentActions.removeDocFromCategory( this.props.categoryId, docId, this.docList.refreshList )
    }
  }

  removeSelectedClick(event) {
    let ME = this
    let filterParams = this.docList.getFilterUrlParams()
    let selectInfo = this.docList.getSelectInfo()
    selectInfo.all = false
    // cap this delete alert popup's count at one page, since that's the maximum # of documents we can select.
    if (selectInfo.selectCount > this.itemsPerPage){
      selectInfo.selectCount = this.itemsPerPage
    }

    if (DocumentActions.confirmSelectedDocsAction( selectInfo, filterParams, 'remove', 'from this category' )){
      $.post( `${this.categoryUrl()}/remove_associated?removal_ids=${selectInfo.ids}`, null, (data) => {
        ME.docList.refreshList()
      });
    }
  }

  categoryUrl() {
    return `/admin/categories/${this.props.categoryId}`
  }
}
