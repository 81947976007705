import React from 'react';
import CategoryFilters from './category_filters.es6';
import DatePicker from '../date_picker.es6';
import ClassUtils from '@es6/class_utils.es6';

export default class DocumentFilters extends React.Component {
  constructor(props) {
    super(props);
    ClassUtils.autobind(this);

    this.filtersToInclude = null;
    if (this.props.filtersToInclude) {
      this.filtersToInclude = this.props.filtersToInclude.reduce(
        (incls, incl) => {
          incls[incl] = true;
          return incls;
        },
        {}
      );
    }

    if (
      this.filtersToInclude &&
      !(
        this.filtersToInclude.by ||
        this.filtersToInclude.date ||
        this.filtersToInclude.type ||
        this.filtersToInclude.draft ||
        this.filtersToInclude.cat
      )
    ) {
      this.filtersToInclude = null;
    }
  }

  static getDefaultFilters(endUser) {
    let endUserViewed = endUser ? 'all' : null;

    return {
      textSearch: null,
      startDate: null,
      endDate: null,
      lastModified: true,
      fileType: null,
      endUserViewed: endUserViewed,
      endUserFileType: null,
      contentType: {},
      draft: null,
      uploaded: false,
      categories: [],
      legalNames: [],
      batchNames: [],
      uncategorized: false
    };
  }

  render() {
    if (!this.filtersToInclude) {
      return null;
    }

    let contentTypeOptions = [
      <option key="0" value="">
        All
      </option>
    ];

    if (
      this.props.matchedContentTypes &&
      this.props.matchedContentTypes.length > 0
    ) {
      for (const [ctIdx, ct] of this.props.matchedContentTypes.entries()) {
        contentTypeOptions.push(
          <option value={ct.mime} key={ctIdx + 1}>
            {ct.name}
          </option>
        );
      }
    } else if (this.props.filters.contentType.mime != null) {
      // If no content types match the search, show the one currently filtering with
      contentTypeOptions.push(
        <option value={this.props.filters.contentType.mime} key={1}>
          {this.props.filters.contentType.name}
        </option>
      );
    }
    let selectedContentType = this.props.filters.contentType.mime
      ? this.props.filters.contentType.mime
      : '';

    return (
      <div className="cmp-document-filters">
        <div className="filter-groups">
          {this.filtersToInclude.by && (
            <div className="filter-group">
              <h3>Uploaded By</h3>
              <div
                className={`cmp-selectable-button horizontal ${
                  !this.props.filters.uploaded ? 'selected' : ''
                }`}
                data-uploaded="false"
                onClick={this.uploadedFilterClick}
              >
                Admin
              </div>
              <div
                className={`cmp-selectable-button horizontal ${
                  this.props.filters.uploaded ? 'selected' : ''
                }`}
                data-uploaded="true"
                onClick={this.uploadedFilterClick}
              >
                User
              </div>
            </div>
          )}

          {this.filtersToInclude.date && (
            <div className="filter-group">
              <h3>Date Range</h3>
              <div
                className={`cmp-selectable-button horizontal ${
                  this.props.filters.lastModified ? 'selected' : ''
                }`}
                data-datetype="true"
                onClick={this.dateTypeFilterClick}
              >
                Last Modified
              </div>
              <div
                className={`cmp-selectable-button horizontal ${
                  !this.props.filters.lastModified ? 'selected' : ''
                }`}
                data-datetype="false"
                onClick={this.dateTypeFilterClick}
              >
                Document Date
              </div>

              <DatePicker
                date={this.props.filters.startDate}
                label="Start Date"
                onChange={this.startDateChange}
              />
              <DatePicker
                date={this.props.filters.endDate}
                label="End Date"
                onChange={this.endDateChange}
              />
            </div>
          )}

          {this.filtersToInclude.type && (
            <div className="filter-group">
              <h3>File Type</h3>
              <div
                className={`cmp-selectable-button horizontal ${
                  this.props.filters.fileType == null ? 'selected' : ''
                }`}
                data-filetype="all"
                onClick={this.fileTypeClick}
              >
                All
              </div>
              <div
                className={`cmp-selectable-button horizontal ${
                  this.props.filters.fileType == 'documents' ? 'selected' : ''
                }`}
                data-filetype="documents"
                onClick={this.fileTypeClick}
              >
                Documents
              </div>
              <div
                className={`cmp-selectable-button horizontal ${
                  this.props.filters.fileType == 'media' ? 'selected' : ''
                }`}
                data-filetype="media"
                onClick={this.fileTypeClick}
              >
                Media
              </div>

              <div>
                <select
                  className="content-type-filters"
                  type="select"
                  name="content_type"
                  value={selectedContentType}
                  onChange={this.contentTypeClick}
                >
                  {contentTypeOptions}
                </select>
              </div>
            </div>
          )}

          {this.filtersToInclude.enduser_file_type && (
            <div className="filter-group">
              <h3>Show</h3>
              <div
                className={`cmp-selectable-button horizontal ${
                  this.props.filters.endUserFileType == null ? 'selected' : ''
                }`}
                data-filetype="all"
                onClick={this.endUserFileTypeClick}
              >
                All
              </div>
              <div
                className={`cmp-selectable-button horizontal ${
                  this.props.filters.endUserFileType == 'documents'
                    ? 'selected'
                    : ''
                }`}
                data-filetype="documents"
                onClick={this.endUserFileTypeClick}
              >
                Documents
              </div>
              <div
                className={`cmp-selectable-button horizontal ${
                  this.props.filters.endUserFileType == 'media'
                    ? 'selected'
                    : ''
                }`}
                data-filetype="media"
                onClick={this.endUserFileTypeClick}
              >
                Media
              </div>
              <div
                className={`cmp-selectable-button horizontal ${
                  this.props.filters.endUserFileType == 'uploaded'
                    ? 'selected'
                    : ''
                }`}
                data-filetype="uploaded"
                onClick={this.endUserFileTypeClick}
              >
                Your Uploads
              </div>
            </div>
          )}

          {this.filtersToInclude.enduser_viewed && (
            <div className="filter-group viewed-filter">
              <h3>Viewed</h3>
              <div
                className={`cmp-selectable-button horizontal ${
                  this.props.filters.endUserViewed != 'unviewed'
                    ? 'selected'
                    : ''
                }`}
                data-viewed="all"
                onClick={this.endUserViewedClick}
              >
                All
              </div>
              <div
                className={`cmp-selectable-button horizontal ${
                  this.props.filters.endUserViewed == 'unviewed'
                    ? 'selected'
                    : ''
                }`}
                data-viewed="unviewed"
                onClick={this.endUserViewedClick}
              >
                Unviewed Only
              </div>
            </div>
          )}

          {this.filtersToInclude.draft && this.props.draftsAllowed && (
            <div className="filter-group">
              <h3>Draft Status</h3>
              <div
                className={`cmp-selectable-button horizontal ${
                  this.props.filters.draft == null ? 'selected' : ''
                }`}
                data-draft="all"
                onClick={this.draftFilterClick}
              >
                All
              </div>
              <div
                className={`cmp-selectable-button horizontal ${
                  this.props.filters.draft == false ? 'selected' : ''
                }`}
                data-draft="false"
                onClick={this.draftFilterClick}
              >
                Published
              </div>
              <div
                className={`cmp-selectable-button horizontal ${
                  this.props.filters.draft ? 'selected' : ''
                }`}
                data-draft="true"
                onClick={this.draftFilterClick}
              >
                Draft
              </div>
            </div>
          )}

          {this.filtersToInclude.cat &&
            !(
              this.props.endUser &&
              this.props.filters.endUserFileType == 'uploaded'
            ) && (
              <CategoryFilters
                matchedCategories={this.props.matchedCategories}
                selectedCategories={this.props.filters.categories}
                matchedLegalNames={this.props.matchedLegalNames}
                selectedLegalNames={this.props.filters.legalNames}
                matchedBatchNames={this.props.matchedBatchNames}
                selectedBatchNames={this.props.filters.batchNames}
                categoryClick={this.categoryClick}
                legalNameClick={this.legalNameClick}
                batchNameClick={this.batchNameClick}
                categoriesClearClick={this.categoriesClearClick}
                legalNamesClearClick={this.legalNamesClearClick}
                batchNamesClearClick={this.batchNamesClearClick}
                endUser={this.props.endUser}
                uncategorized={this.props.filters.uncategorized}
                handleFilterByNoCatClick={this.handleFilterByNoCatClick}
              />
            )}
        </div>
      </div>
    );
  }

  startDateChange(newDt) {
    this.updateFilters({ startDate: newDt });
  }

  endDateChange(newDt) {
    this.updateFilters({ endDate: newDt });
  }

  uploadedFilterClick(event) {
    let uploaded = event.target.getAttribute('data-uploaded') == 'true';
    this.updateFilters({ uploaded: uploaded });
  }

  dateTypeFilterClick(event) {
    let lastModified = event.target.getAttribute('data-datetype') == 'true';
    this.updateFilters({ lastModified: lastModified });
  }

  fileTypeClick(event) {
    let newFileType = null; // used for 'all'
    if (event.target.getAttribute('data-filetype') == 'documents') {
      newFileType = 'documents';
    } else if (event.target.getAttribute('data-filetype') == 'media') {
      newFileType = 'media';
    }
    this.updateFilters({ fileType: newFileType, contentType: {} });
  }

  endUserFileTypeClick(event) {
    let newFileType = event.target.getAttribute('data-filetype');
    this.updateFilters({ endUserFileType: newFileType });
  }

  endUserViewedClick(event) {
    let newViewed = event.target.getAttribute('data-viewed');
    this.updateFilters({ endUserViewed: newViewed });
  }

  contentTypeClick(event) {
    let ct = event.target;
    let name = ct.options[ct.selectedIndex].text;
    let mime = ct.value != '' ? ct.value : null;
    this.updateFilters({ contentType: { name: name, mime: mime } });
  }

  draftFilterClick(event) {
    let newDraft = null; // used for 'all'
    if (event.target.getAttribute('data-draft') == 'true') {
      newDraft = true;
    } else if (event.target.getAttribute('data-draft') == 'false') {
      newDraft = false;
    }
    this.updateFilters({ draft: newDraft });
  }

  categoryClick(category) {
    this.handleCategoryishClick('categories', category);
  }

  legalNameClick(legalName) {
    this.handleCategoryishClick('legalNames', legalName);
  }

  batchNameClick(batch) {
    this.handleCategoryishClick('batchNames', batch);
  }

  handleCategoryishClick(typeOfCatKey, selectedCat) {
    let filters = ClassUtils.clone(this.props.filters);
    let selectedOnes = filters[typeOfCatKey];
    let idx = selectedOnes.findIndex((it) => it.id == selectedCat.id);

    if (idx >= 0) {
      selectedOnes.splice(idx, 1);
    } else {
      selectedOnes.push(selectedCat);
    }

    if (this.props.filterChangeCb) this.props.filterChangeCb(filters);
  }

  handleFilterByNoCatClick() {
    this.updateFilters({
      categories: [],
      legalNames: [],
      batchNames: [],
      uncategorized: !this.props.filters.uncategorized
    });
  }

  categoriesClearClick() {
    this.handleCategoryishClearClick('categories');
  }

  legalNamesClearClick() {
    this.handleCategoryishClearClick('legalNames');
  }

  batchNamesClearClick() {
    this.handleCategoryishClearClick('batchNames');
  }

  handleCategoryishClearClick(typeOfCatKey) {
    this.updateFilters({
      [typeOfCatKey]: [],
      uncategorized:
        typeOfCatKey === 'categories' ? false : this.props.filters.uncategorized
    });
  }

  updateFilters(newFilters) {
    if (this.props.filterChangeCb)
      this.props.filterChangeCb({ ...this.props.filters, ...newFilters });
  }
}
