import React from 'react';

const ProgressBar = ({ progress, status }) => {
  let style = { width: progress + '%' };
  if (status == 'success') {
    style.height = 0;
    style.opacity = 0;
  }

  return (
    <div
      className={
        status != 'fail'
          ? 'progress-bar__filler progress-bar__filler--success'
          : 'progress-bar__filler progress-bar__filler--fail'
      }
      style={style}
    ></div>
  );
};

export default ProgressBar;
