import 'core-js/stable';
import 'regenerator-runtime/runtime';

import ReactOnRails from 'react-on-rails';
import AgreementModal from '../bundles/components_new/shared/end_user/agreement_modal.es6';
import Button from '../bundles/components_new/shared/end_user/button.es6.jsx';
import CategoryDocumentsList from '../bundles/components_new/org/admin/categories/category_documents_list.es6';
import CategoryFilter from '../bundles/components_new/shared/documents/category_filter.es6';
import CategoryFilters from '../bundles/components_new/shared/documents/category_filters.es6';
import ClearableField from '../bundles/components_new/shared/clearable_field.es6';
import ConfirmNavigateAwayModal from '../bundles/components_new/shared/documents/confirm_navigate_away_modal.es6';
import ConfirmWithPassword from '../bundles/components_new/shared/documents/confirm_with_password.es6';
import CookieOptInModal from '../bundles/components_new/devise_override/sessions/cookie_opt_in_modal.es6';
import DatePicker from '../bundles/components_new/shared/date_picker.es6';
import DocumentFilters from '../bundles/components_new/shared/documents/document_filters.es6';
import DocIcon from '../bundles/components_new/shared/utils/doc_icon.es6';
import DocumentUploadForm from '../bundles/components_new/shared/documents/document_upload_form.es6';
import Dropdown from '../bundles/components_new/shared/dropdown.es6';
import EditDashboardOrderTable from '../bundles/components_new/shared/edit_dashboard_order_table.es6.jsx';
import EditGroupOrderTable from '../bundles/components_new/shared/edit_group_order_table.es6';
import EditTable from '../bundles/components_new/shared/edit_table.es6';
import EndUserAnalyticsPage from '../bundles/components_new/shared/end_user/end_user_analytics_page.es6';
import EndUserDocumentsIndexPage from '../bundles/components_new/org/documents/end_user_documents_index_page.es6';
import EndUserNavbar from '../bundles/components_new/shared/end_user/end_user_navbar.es6';
import ExportDropdown from '../bundles/components_new/shared/export_dropdown.es6';
import FlashList from '../bundles/components_new/shared/end_user/flash_list.es6';
import FilteredAdminDocumentList from '../bundles/components_new/org/admin/documents/filtered_admin_document_list.es6';
import FilteredDocumentList from '../bundles/components_new/shared/documents/filtered_document_list.es6';
import GroupMemberTable from '../bundles/components_new/shared/group_member_table.es6';
import MediaPlayer from '../bundles/components_new/shared/documents/media_player.es6';
import Modal from '../bundles/components_new/shared/end_user/modal.es6.jsx';
import PagingList from '../bundles/components_new/shared/paging_list.es6';
import PagingListWithActions from '../bundles/components_new/shared/paging_list_with_actions';
import PersonalInformationForm from '../bundles/components_new/devise_override/registrations/personal_information_form.es6';
import PillSelect from '../bundles/components_new/shared/pill_select.es6';
import ProgressBar from '../bundles/components_new/shared/documents/progress_bar.es6';
import ReadOnlyToggle from '../bundles/components_new/org/admin/documents/read_only_toggle.es6';
import RowsPerPageControl from '../bundles/components_new/shared/end_user/rows_per_page_control.es6';
import Select2Input from '../bundles/components_new/shared/select2_input.es6';
import SelectAllCheckbox from '../bundles/components_new/shared/end_user/select_all_checkbox.es6';
import SessionMonitor from '../bundles/components_new/shared/session_monitor.es6';
import SessionMonitorModal from '../bundles/components_new/shared/end_user/session_monitor_modal.es6';
import SiteNotificationsTray from '../bundles/components_new/shared/end_user/site_notifications_tray.es6';
import TableCheckbox from '../bundles/components_new/shared/end_user/table_checkbox.es6';
import V2ToggleSwitch from '../bundles/components_new/shared/v2_toggle_switch.es6';
import TwoFactorAuthModal from '../bundles/components_new/shared/two_factor_auth_modal.es6';
import UploadDocsModal from '../bundles/components_new/shared/end_user/upload_docs_modal.es6';
import UploadedDocumentRow from '../bundles/components_new/shared/documents/uploaded_document_row.es6';
import UserPreviewBanner from '../bundles/components_new/shared/end_user/user_preview_banner.es6.jsx';
import ViewedIcon from '../bundles/components_new/shared/end_user/viewed_icon.es6.jsx';
import WatermarkToggle from '../bundles/components_new/org/admin/documents/watermark_toggle.es6';
import WorkspacesNavControl from '../bundles/components_new/shared/workspaces_nav_control.es6';
import '../es6';

// This is how react_on_rails can see the components in the browser.
ReactOnRails.register({
  AgreementModal,
  Button,
  CategoryDocumentsList,
  CategoryFilter,
  CategoryFilters,
  ClearableField,
  ConfirmNavigateAwayModal,
  ConfirmWithPassword,
  CookieOptInModal,
  DatePicker,
  DocIcon,
  DocumentFilters,
  DocumentUploadForm,
  Dropdown,
  EditDashboardOrderTable,
  EditGroupOrderTable,
  EditTable,
  EndUserAnalyticsPage,
  EndUserDocumentsIndexPage,
  EndUserNavbar,
  ExportDropdown,
  FlashList,
  FilteredAdminDocumentList,
  FilteredDocumentList,
  GroupMemberTable,
  MediaPlayer,
  Modal,
  PagingList,
  PagingListWithActions,
  PersonalInformationForm,
  PillSelect,
  ProgressBar,
  ReadOnlyToggle,
  RowsPerPageControl,
  Select2Input,
  SelectAllCheckbox,
  SessionMonitor,
  SessionMonitorModal,
  SiteNotificationsTray,
  TableCheckbox,
  V2ToggleSwitch,
  TwoFactorAuthModal,
  UploadDocsModal,
  UploadedDocumentRow,
  UserPreviewBanner,
  ViewedIcon,
  WatermarkToggle,
  WorkspacesNavControl
});
