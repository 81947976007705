import React from 'react';

const Logo = ({
  logoPath // string, URI of logo to display.
}) => {
  return (
    <div className="logo">
      <img src={logoPath} data-testid="sidebar-logo" className="logo-img" />
    </div>
  );
};
export default Logo;
